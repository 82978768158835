export const createModelAvailability = `mutation($input: CreateModelAvailabilityRequest!) {
    createModelAvailability(input: $input) {
      message
    }
  }`;



    export const GetProductLine = `query($filter: GetProductLineRequest!) {
        ocplcProductLine(filter: $filter) {
            productLine {
                id
                name
            }
        }
    }`;