// import { useSelector } from 'react-redux';
import React from 'react';
import TextField from '@mui/material/TextField';
import { updateChoiceItemFields } from 'actions/modelActions';
import useGlobalStore from 'hooks/useGlobalStore';


export const Comments = ({ itemID, choiceID, tabName, data }) => {
  // const choiceData = useSelector(state => state.models)[getTab(tabName)][choiceID];
  // const choiceDataString = JSON.stringify(choiceData);
  // const getCommentsSource = useMemo(() => {
  //   const choiceDataObject = JSON.parse(choiceDataString);
  //   const fromStore = itemID ? choiceDataObject.items[itemID] : choiceDataObject;
  //   return fromStore?.comments?.length ? fromStore : ''
  // }, [choiceDataString, itemID]);
  // const [itemData, setItemData] = useGlobalStore(getCommentsSource, updateChoiceItemFields, { choiceID, itemID, tabCategory: tabName });
  const [itemData, setItemData] = useGlobalStore(data, updateChoiceItemFields, { choiceID, itemID, tabCategory: tabName });
  
  const handleOnChange = (event, field) => {
    let newVal = event.target.value;

    setItemData((prevData) => ({
      ...prevData,
      [field]: newVal,
      edited: true
    }));
  };

  return (
    <>
      <TextField
        variant="filled"
        multiline
        fullWidth
        minRows={25}
        margin="dense"
        slotProps={{ input : { disableUnderline: true }}}
        id="comments"
        onChange={(e) => handleOnChange(e, 'comments')}
        value={itemData?.comments || ""}
      />
    </>
  )
}

export default Comments;