import React, { useRef, useState, useLayoutEffect, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { CVG_REGIONAL_TABS, CVG_TABS, PM_TAB, WWNA_TAB } from "constants/modelConstants";
import { useStyles, CountBadge, CustomSearch,useCommonStyles } from "../../pages/EditModel/IndexHelpers";
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { cvgTabSelection } from "actions/modelActions";
import { useActiveDataSet } from "pages/Utils/TabDataUtil";
import CVGEdit from "pages/EditModel/CVGEdit/CVGEdit";
import useKeypress from "hooks/useKeypress";
import Label from "pages/EditModel/label";

const CvgTree = (props) => {
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const dispatch = useDispatch();
    const searchRef = useRef();
    const isRegional = props?.dataType === "regional";

    const { defaultCvgTab, tabs } = useMemo(() => {
        return { defaultCvgTab: isRegional ? WWNA_TAB : PM_TAB, tabs: isRegional ? CVG_REGIONAL_TABS : CVG_TABS };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.dataType]);

    const [selectedCvgTab, setSelectedCvgTab] = useState(defaultCvgTab);
    const { datasetValues } = useActiveDataSet(selectedCvgTab);
    const [selectedChoiceData, setSelectedChoiceData] = useState(null);
    const [cvgsFilter, setCvgsFilter] = useState("");

    useKeypress({ f: () => searchRef.current.focus(), }, true);

    useLayoutEffect(() => {
        if (!datasetValues.length) return;
        if (!selectedChoiceData) {
            setSelectedChoiceData(datasetValues[0]);
        } else {
            setSelectedChoiceData(datasetValues.find((choice) => choice.choiceID === selectedChoiceData.choiceID));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datasetValues]);

    const handleCvgIdChange = (e) => {
        setCvgsFilter('');
        setSelectedChoiceData(null);
        setSelectedCvgTab(e.target.value);
        dispatch(cvgTabSelection(e.target.value));
    };

    const handleClearSearch = () => {
        setCvgsFilter('');
        setSelectedChoiceData(datasetValues[0]);
    };

    const handleChangeSearch = (newCvgsFilter) => {
        if (newCvgsFilter === "") return handleClearSearch();

        const nodes = datasetValues.filter((dataset) => dataset?.allowedCVGs.toLowerCase().includes(newCvgsFilter.toLowerCase()));
        setCvgsFilter(newCvgsFilter);
        setSelectedChoiceData(nodes[0]);
    }

    const handleSelectItem = useCallback((choice) => {
        setSelectedChoiceData(choice);
    }, []);

    return (
        <div style={{ height: '100vh' }}>
            <Grid container style={{ height: '100%' }} spacing={1} className={classes.root}>
                <Grid id="tree-container" size={{ xs: 12, md: 4 }} >
                    <Paper className={classes.cvgPaper}>
                        <div className={commonClasses.popperContainer}>
                            <div className={commonClasses.popperHeader}>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        select
                                        value={selectedCvgTab}
                                        variant="filled"
                                        margin="dense"
                                        label="Category"
                                        sx={{ '& .MuiFilledInput-root': { height: "45px" }, '& .MuiSelect-select': { paddingBottom: 0 ,backgroundColor:"transparent !important" } }}
                                        slotProps={{input: {disableUnderline: true} }}
                                        onChange={(e) => handleCvgIdChange(e)}
                                    >
                                        {tabs.map((tab) => (
                                            <MenuItem value={tab.id} id={tab.id} key={tab.id}>
                                                {tab.title}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                                <CustomSearch
                                    placeholder="Search CVGs.."
                                    value={cvgsFilter}
                                    inputRef={searchRef}
                                    handleClearSearch={handleClearSearch}
                                    onChange={(e) => handleChangeSearch(e.target.value)}
                                />
                            </div>
                            <div id="split-button-menu" className={commonClasses.popperList}>
                                {datasetValues?.length !== 0 && (
                                    (cvgsFilter ? datasetValues.filter((dataset) => dataset?.allowedCVGs.toLowerCase().includes(cvgsFilter.toLowerCase())) : datasetValues).map((choice) => (
                                        <div
                                            key={choice.choiceID}
                                            className={clsx(commonClasses.cvg, { selected: choice.choiceID === selectedChoiceData?.choiceID })}
                                            onClick={() => handleSelectItem(choice)}
                                        >
                                            <Typography className={classes.choiceText}>{choice.name}</Typography>
                                            <CountBadge badgeContent={Object.values(choice.items).length} />
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                    </Paper>
                </Grid>
                <Grid size={{ xs: 12, md: 8 }}>
                    <Paper className={classes.cvgPaper}>
                        {datasetValues?.length !== 0 && <Label editTitle={"CVG Editor"} updatedSelectedData={""} selectedChoiceData={selectedChoiceData} />}
                        <div>
                            <CVGEdit
                                key={`displayEditCVG-${selectedChoiceData?.choiceID || ''}`}
                                choiceID={selectedChoiceData?.choiceID}
                                selectedCvgTab={selectedCvgTab}
                                cvgsFilter={cvgsFilter}
                                selectedChoiceData={selectedChoiceData}
                                currentVersion={props?.currentVersion}
                                kmatId={props?.kmatId}
                                dataType={props?.dataType}
                            />
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

export default CvgTree;
