/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, memo } from "react";
import { AppBar, ListItemText, Toolbar, Typography } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CheckIcon from '@mui/icons-material/Check';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from "prop-types";
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import { useRerouteRole } from "pages/Utils";
import { useStyles } from "./topBarHelpers";
import LocationEffect from "./locationEffect";
import { isRoleSelected } from "pages/Utils/RerouteUtil";

const TopBar = ({ logout, userDetails }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const reroute = useRerouteRole();

  if (!userDetails) return null;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.setItem("loggingOut", "1");
    logout();
  };

  return (
    <AppBar className={classes.appbar}>
      <Toolbar className={classes.topbarFlex}>
        <LocationEffect />

        <Avatar className={classes.menuAvathar} onClick={handleMenuOpen}>
          {userDetails.firstName.charAt(0)}
          {userDetails.lastName.charAt(0)}
        </Avatar>

        {open &&
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            className={classes.accountMenu}
            open={open}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <span>
              <h5 style={{ marginLeft: "35%", display: "inline-block" }} > Account </h5>
              <MoreHorizIcon className={classes.menuHeaderRight} />
            </span>
            <MenuItem className={classes.menuUserSettings} >  User Settings </MenuItem>
            <Typography item className={classes.profileName} >
              <Avatar className={classes.menuProfileName}>
                {userDetails.firstName.charAt(0)}
                {userDetails.lastName.charAt(0)}
              </Avatar>
              <ListItemText
                primary={<Typography className={classes.profile} >{userDetails?.firstName + " " + userDetails?.lastName}</Typography>}
              />
            </Typography>
            <MenuItem onClick={handleLogout} >
              <ListItemIcon> <Logout fontSize="small" /> </ListItemIcon>
              Logout
            </MenuItem>
            <MenuItem className={classes.menuUserSettings}> User Roles </MenuItem>
            {userDetails?.roles.map(role => {
              return (
                <MenuItem
                  key={role.id}
                  onClick={() => reroute(role.id)}
                  style={{ borderBottom: "1px solid lightgrey", display: "flex", justifyContent: "space-between" }}
                  selected={isRoleSelected(role.id, userDetails)}>
                  {role.name}
                  {isRoleSelected(role.id, userDetails) ? <span style={{ color: "green" }}><CheckIcon /></span> : null}
                </MenuItem>
              )
            })}
          </Menu>}
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  logout: PropTypes.func.isRequired,
  userDetails: PropTypes.object
};

export default memo(TopBar);
