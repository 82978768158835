import { SELECTED_OCPLC_KMAT, MODEL_AVAILABILITY, REGION_DROPDOWN, BUSINESS_MODEL, COUNTRY_LIST, FILTER_OCPLC_EDIT_DASHBOARD, LOCK_STATUS_DASHBOARD, SET_DASHBOARD_ROW_DETAILS, FILTER_BY_STATUS, TOF_AUDIT_TRAIL, GET_PRODUCT_LINE, CREATE_MODEL_AVAILABILITY, UPDATE_MODEL_AVAILABILITY, CREATE_EXISTING_MODEL_AVAILABILITY, MODUL_DATA_TRANSPORT, MODULE_WISE_DATA_TRANSPORT, COMPLETE_MODEL_AVAILABILITY, DELETE_MODEL_AVAILABILITY, DASHBOARD_DATA_TRANSPORT } from 'constants/ocplcConstants';
import { applyFilters } from 'pages/Utils/FilterUtil';

const initOcplcDashboard = {
    filters: {},
    filtersEdit : {},
    filteredOcplcData: [],
    filteredModelData: [],
    isLoading: true,
    ocplcSelectedKmats: [],
    selectedRows: {},
    tableData: [],
    modelAvailabilityData: [],
    countryListData: [],
    businessModels: [],
    regionDropdown: [],
    isLockStatusError: false,
    filterKmats: [],
    lockStatus: {},
    modelAuditTOFTrial: [],
    productLine: [],
    moduleWiseDataTransport: [],
    completeModelAvailability: [],
    dataTransport: []
};

export const ocplcReducer = (state = initOcplcDashboard, { type, payload }) => {
    switch (type) {
        case "REFRESH_OCPLC": {
            const newData = payload || initOcplcDashboard;
            const newState = {
                ...newData,
                isLoading: false,
                filters: state.filters,
                filtersEdit: state.filtersEdit,
                tableData: state.tableData // Preserve existing tableData
            };

            const itemsToFilter = newState.ocplcProductModels;
            const filteredOcplcData = applyFilters(Object.values(state.filters), itemsToFilter);
            const filteredModelData = applyFilters(Object.values(state.filtersEdit), newData.modelAvailabilityData);
            return {
                ...newState,
                filteredOcplcData,
                filteredModelData,
            };
        }
        case SELECTED_OCPLC_KMAT: {
            const updatedKmats = payload || [];
            const updatedSelectedRows = updatedKmats.reduce((acc, kmat) => {
                acc[kmat] = true;
                return acc;
            }, {});

            return {
                ...state,
                ocplcSelectedKmats: updatedKmats,
                selectedRows: updatedSelectedRows,
            };
        }
        
        case "CLEAR_OCPLC_FILTERS": {
            return {
                ...state,
                filters: {},
                filtersEdit: {},
                selectedRows: {},
                ocplcSelectedKmats: [],
                filteredOcplcData: state.ocplcProductModels,
                filteredModelData: state.modelAvailabilityData
            };
        }
        case "FILTER_OCPLC": {
            const { name, searchText, matchFields, type: filterType } = payload;

            const filters = {
                ...state.filters,
                [name]: {
                    value: searchText,
                    type: filterType,
                    matchFields
                }
            };
            const filteredOcplcData = applyFilters(Object.values(filters), state.ocplcProductModels);
            return {
                ...state,
                filters,
                filteredOcplcData
            };
        }
        case "OCPLC_TABLE_DATA": {
            return {
                ...state,
                tableData: payload,
                isLoading: false
            };
        }
        case FILTER_OCPLC_EDIT_DASHBOARD: {
            const { name, searchText, matchFields, type } = payload;

            const updatedFilters = {
                ...state.filtersEdit,
                [name]: {
                    value: searchText,
                    type,
                    matchFields
                }
            };
            const filteredModelData = applyFilters(Object.values(updatedFilters), state.modelAvailabilityData);
            return {
                ...state,
                filtersEdit: updatedFilters,
                filteredModelData
            };
        }

        case FILTER_BY_STATUS: {
            const filteredModelData = state.modelAvailabilityData.filter(item => payload.includes(item.status));
            
            return {
              ...state,
              filteredModelData,
            };
          }

        case MODEL_AVAILABILITY: {
            const updatedData = payload || [];
            const filteredModelData = applyFilters(Object.values(state.filtersEdit), updatedData);

            return {
                ...state,
                modelAvailabilityData: updatedData,
                filteredModelData,
                isLoading: false
            };
        }
        case UPDATE_MODEL_AVAILABILITY:{
            const updatedData = payload;
            const filteredModelData = state.filteredModelData.map(item => {
                if (item.pkId === updatedData.pkId) {
                    return payload ;
                }
                return item;
            });
            return {
                ...state,
                filteredModelData         
           }

        };
        case CREATE_EXISTING_MODEL_AVAILABILITY: {
            const updatedData = payload;
            const updateData = (data) => 
                data.some(item => item.pkId === updatedData.pkId)
                    ? data.map(item => item.pkId === updatedData.pkId ? updatedData : item)
                    : [...data, updatedData];

            const updatedModelAvailabilityData = updateData(state.modelAvailabilityData);
            const updatedFilteredModelData = updateData(state.filteredModelData);
        
            return {
                ...state,
                modelAvailabilityData: updatedModelAvailabilityData,
                filteredModelData: updatedFilteredModelData,
            };
        };
     
        case COUNTRY_LIST: {
            return {
                ...state,
                countryListData: payload || [],
                isLoading: false
            };
        }
        case BUSINESS_MODEL: {
            return {
                ...state,
                businessModels: payload || [],
                isLoading: false
            };
        }
        case "FILTER_KMAT": {
            return {
                ...state,
                filterKmats: payload
            };
        }
        case REGION_DROPDOWN: {
            return {
                ...state,
                regionDropdown: payload || [],
                isLoading: false
            };
        }
        case LOCK_STATUS_DASHBOARD: {
            const { isError, response } = payload;
            return {
                ...state,
                isLockStatusError: isError,
                lockStatus: response
            };
        }
        case DELETE_MODEL_AVAILABILITY: {
            const { pkId } = payload;
            const updatedModelAvailabilityData = state.modelAvailabilityData.filter(item => item.pkId !== pkId);
            const updatedFilteredModelData = state.filteredModelData.filter(item => item.pkId !== pkId);

            return {
                ...state,
                modelAvailabilityData: updatedModelAvailabilityData,
                filteredModelData: updatedFilteredModelData
            };
        }
        case TOF_AUDIT_TRAIL: {
            return {
                ...state,
                modelAuditTOFTrial: payload
            };
        }
        case GET_PRODUCT_LINE: {
            return {
                ...state,
                productLine: payload
            };
        }
        case CREATE_MODEL_AVAILABILITY: {
            const updatedModelAvailabilityData = [payload, ...state.modelAvailabilityData];

            return {
                ...state,
                modelAvailabilityData: updatedModelAvailabilityData,
            };
        }
        case MODULE_WISE_DATA_TRANSPORT: {
            return {
                ...state,
                moduleWiseDataTransport: payload
            };
        }
        case COMPLETE_MODEL_AVAILABILITY: {
            return {
                ...state,
                completeModelAvailability: payload

            };
        }
        case DASHBOARD_DATA_TRANSPORT: {
            return {
                ...state,
                dataTransport: payload
            };
        }
       
        default: return state;
    }
};