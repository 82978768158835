import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Typography } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import MultiValueInput from './MultiValueInput';
import Box from '@mui/material/Box';

const UploadDialog = ({ open, handleClose, ocplcSelectedKmats }) => {
    const [files, setFiles] = useState([]);

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    };

    const handleDeleteFile = (file) => {
        setFiles(files.filter((item) => item.name !== file.name));
    };

    const handleUpload = () => {
        setFiles([]);
        handleClose();
    };

    return (open &&
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Upload File</DialogTitle>
            <Typography sx={{ paddingLeft: "20px" }}>Selected KMAT</Typography>
            <Box sx={{ marginLeft: "20px" }}>
                <MultiValueInput ocplcSelectedKmats={ocplcSelectedKmats} />
            </Box>
            <Typography sx={{ padding: "20px 0px 0px 20px" }}>Upload file</Typography>
            <DialogContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <TextField
                    type="text"
                    slotProps={{
                        input: {
                            readOnly: true,
                            endAdornment: (
                                <IconButton component="label">
                                    <FileUploadOutlinedIcon />
                                    <input
                                        type="file"
                                        accept='.csv'
                                        hidden
                                        multiple
                                        onChange={handleFileChange}
                                    />
                                </IconButton>
                            ),
                        }
                    }}
                    fullWidth
                    value={files.length > 0 ? `${files.length} file(s) selected` : ''}
                />
                {files.length > 0 && (
                    <List>
                        {files.map((file) => (
                            <ListItem
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => handleDeleteFile(file)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemText primary={file.name} />
                            </ListItem>
                        ))}
                    </List>
                )}
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "flex-start", marginLeft: "15px" }} >
                <Button variant="outlined" className='cancel-button' onClick={handleClose} >
                    Close
                </Button>
                <Button
                    variant="contained"
                    className='add-Button'
                    disabled={files?.length === 0 || ocplcSelectedKmats?.length === 0}
                    onClick={handleUpload}
                >
                    Upload
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UploadDialog;
