import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import SyncIcon from '@mui/icons-material/Sync';
import { iconButtonStyle } from 'pages/DashboardHelpers';
import { clearOcplcFilters, deleteModelAvailabilityAction, modelAvailability } from 'actions/ocplcAction';
import { snackBar } from 'actions/modelActions';
import { actionButtonsStyle } from 'pages/ocplcDashboard/ocplcHelpers';
import { isAddDisabled, isCopyDeleteDisabled as checkCopyDeleteDisabled } from 'pages/Utils/OcplcUtil';
import AddCopyDeleteConfirmationDialog from 'pages/ocplcDashboard/AddCopyDeleteConfirmationDialog';
import AddDialog from 'pages/ocplcDashboard/AddDialog';
import EditOcplc from 'pages/ocplcDashboard/EditOcplc';

const ActionableButtons = (props) => {
    const { version, region, kmatId, productLineID, productGroup, checkedRow, userEmail, businessModels, assignedRole, countryList, disabledAction, setCheckedRow } = props;
    const dispatch = useDispatch();
    const [dialogState, setDialogState] = useState({
        isDeleteDialogOpen: false,
        isAddDialogOpen: false,
        isCopyDialogOpen: false
    });

    const refreshAction = () => {
      setCheckedRow([]);
      dispatch(modelAvailability(version, region, kmatId, productGroup, productLineID));
      dispatch(snackBar(true, "Refresh successfully", "success"));
    };
  
    const clearFilterAction = () => {
      setCheckedRow([]);
      dispatch(clearOcplcFilters());
    };

    const handleDialogOpen = (dialog) => {
        setDialogState(prev => ({ ...prev, [dialog]: true }));
    };

    const handleDialogClose = (dialog) => {
        setDialogState(prev => ({ ...prev, [dialog]: false }));
        setCheckedRow([]);
    };

    const handleDialogDelete = (comments) => {
        const { pkId, whoChanged, productFamily } = checkedRow[0];
        const input = { pkId, comments, whoChanged, productFamily };

        dispatch(deleteModelAvailabilityAction(input));
        handleDialogClose('isDeleteDialogOpen');
    };
    const isCopyDeleteDisabled = checkCopyDeleteDisabled(version, assignedRole, region, checkedRow);
    const isAddButtonDisabled = isAddDisabled(version, assignedRole, region, kmatId, checkedRow.length);

    const actionsLeft = [
      { label: 'Add', icon: <AddIcon />, onClick: () => handleDialogOpen('isAddDialogOpen'), disabled: isAddButtonDisabled },
      { label: 'Copy', icon: <ContentCopyIcon />, onClick: () => handleDialogOpen('isCopyDialogOpen'), disabled: isCopyDeleteDisabled },
      { label: 'Delete', icon: <DeleteForeverIcon />, onClick: () => handleDialogOpen('isDeleteDialogOpen'), disabled: isCopyDeleteDisabled }
  ];

    const actionsRight = [
        { label: 'Clear Filters', icon: <FilterAltOffIcon />, onClick: clearFilterAction },
        { label: 'Refresh', icon: <SyncIcon />, onClick: refreshAction }
    ];

    return (
      <Box sx={actionButtonsStyle}>
        <Box sx={{ display: "inline-flex", gap: "10px" }}>
          {actionsLeft.map(({ label, icon, onClick, disabled }) => (
            <Button
              key={label}
              color="primary"
              disableRipple
              sx={iconButtonStyle}
              disabled={disabled}
              onClick={onClick}
              endIcon={icon}
            >
              {label}
            </Button>
          ))}
        </Box>
        <Box sx={{ display: "inline-flex", gap: "10px" }}>
          {actionsRight.map(({ label, icon, onClick }) => (
            <Button
              key={label}
              color="primary"
              disableRipple
              sx={iconButtonStyle}
              onClick={onClick}
              endIcon={icon}
            >
              {label}
            </Button>
          ))}
        </Box>
        
        {dialogState.isDeleteDialogOpen && (
          <AddCopyDeleteConfirmationDialog
            isOpen={dialogState.isDeleteDialogOpen}
            onClose={() => handleDialogClose('isDeleteDialogOpen')}
            onDelete={handleDialogDelete}
          />
        )}
       
        {dialogState.isAddDialogOpen && (
          <AddDialog
            open={dialogState.isAddDialogOpen}
            onClose={() => handleDialogClose('isAddDialogOpen')}
            version={version}
            region={region}
            productGroup={productGroup}
            userEmail={userEmail}
            kmatId={kmatId}
            businessModels={businessModels}
            productLineID={productLineID}
          />
        )}

        {dialogState.isCopyDialogOpen && (
          <EditOcplc
            open={dialogState.isCopyDialogOpen}
            handleClose={() => handleDialogClose('isCopyDialogOpen')}
            mode="copy"
            plcDetails={checkedRow[0]}
            countryList={countryList}
            businessModels={businessModels}
            selectedTableRegion={region}
            userEmail={userEmail}
            disabledAction={disabledAction}
            assignedRole={assignedRole}
            kmatId={kmatId}
            productLineID={productLineID}
            copy={true}
          />
      )}
      </Box>
    );
};

export default ActionableButtons;