import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { renderOcplcSelectHeader } from 'pages/ocplcDashboard/ocplcHelpers';
import RenderTextfieldHeader from '../renderTextFieldHeader';
import FeedIcon from '@mui/icons-material/Feed';
import EditIcon from '@mui/icons-material/Edit';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { colorTypes, columnTypes, dateConfig, regionalFields } from './columnTypes';
import dayjs from 'dayjs';

export const getColumns = (filters, modelAvailabilityData = [], handleChange, countryList, businessModels, regionDropdown, handleViewModel, region, disableAction, handleFeedDialog) => {
    const isFieldNotEmpty = (field) => modelAvailabilityData.length > 0 && modelAvailabilityData.every(row => row[field]);
    const { startDateLabel, endDateLabel, startDateValue, endDateValue } = dateConfig[region] || dateConfig.default;
    let regionCheckBoxColumns = []

    // Common columns for all regions
    const baseColumns = [
        {
            headerName: 'Product Family', field: 'productFamily', width: 155, sortable: false, resizable: true, headerClassName: 'dashboard-data-grid-headers',
            renderCell: (params) => <Typography variant='subtitle2' sx={{ color: colorTypes[params.row.status] || 'grey' }}>{params.row.productFamily}</Typography>,
            renderHeader: () => <RenderTextfieldHeader colData={columnTypes.productFamily} label="Product Family" filters={filters} handleChange={handleChange} />
        },
        {
            headerName: 'Product Description', field: 'productDesc', width: 155, sortable: false, headerClassName: 'dashboard-data-grid-headers',
            renderCell: (params) => <Typography variant='subtitle2'>{params.row.productDesc}</Typography>,
            renderHeader: () => <RenderTextfieldHeader colData={columnTypes.productDesc} label="Product Description" filters={filters} handleChange={handleChange} />
        },
        {
            headerName: 'Region', field: 'region', width: 155, sortable: false, headerClassName: 'dashboard-data-grid-headers',
            renderCell: (params) => <Typography variant='subtitle2'>{params.row.region}</Typography>,
            renderHeader: () => renderOcplcSelectHeader(columnTypes.region, filters, 'Region', handleChange, regionDropdown)
        },
        {
            headerName: 'Country ID', field: 'country', width: 155, sortable: false, headerClassName: 'dashboard-data-grid-headers',
            renderCell: (params) => <Typography variant='subtitle2'>{params.row.country}</Typography>,
            renderHeader: () => renderOcplcSelectHeader(columnTypes.country, filters, 'Country ID', handleChange, countryList)
        },
        {
            headerName: 'Country Description', field: 'countryDesc', width: 155, sortable: false, headerClassName: 'dashboard-data-grid-headers',
            renderCell: (params) => <Typography variant='subtitle2'>{params.row.countryDesc}</Typography>,
            renderHeader: () => <RenderTextfieldHeader colData={columnTypes.countryDesc} label="Country Description" filters={filters} handleChange={handleChange} />
        },
        {
            headerName: 'Business Model', field: 'businessModel', width: 155, sortable: false, headerClassName: 'dashboard-data-grid-headers',
            renderCell: (params) => <Typography variant='subtitle2'>{params.row.businessModel}</Typography>,
            renderHeader: () => renderOcplcSelectHeader(columnTypes.businessModel, filters, 'Business Model', handleChange, businessModels)
        },
        {
            headerName: 'Status', field: 'status', width: 155, sortable: false, headerClassName: 'dashboard-data-grid-headers',
            renderCell: (params) => <Typography variant='subtitle2'>{params.row.status}</Typography>,
            renderHeader: () => <RenderTextfieldHeader colData={columnTypes.status} label="Status" filters={filters} handleChange={handleChange} />
        },
        {
            headerName: 'Start Date', field: 'startDate', width: 155, sortable: false, headerClassName: 'dashboard-data-grid-headers',
            renderCell: (params) => <Typography variant='subtitle2'>{params.row[startDateValue] ? dayjs(params.row[startDateValue]).format('DD/MM/YYYY') : ''}</Typography>,
            renderHeader: () => <RenderTextfieldHeader colData={columnTypes.startDate} label={startDateLabel} filters={filters} handleChange={handleChange} />
        },
        region !== "WW" && {
            headerName: 'Regional Start Date', field: 'regStartDate', width: 155, sortable: false, headerClassName: 'dashboard-data-grid-headers',
            renderCell: (params) => <Typography variant='subtitle2'>{params.row.startDate ? dayjs(params.row.startDate).format('DD/MM/YYYY') : ''}</Typography>,
            renderHeader: () => <RenderTextfieldHeader colData={columnTypes.regStartDate} label="Start Date" filters={filters} handleChange={handleChange} />
        },
        region !== "WW" && {
            headerName: 'Regional End Date', field: 'regEndDate', width: 155, sortable: false, headerClassName: 'dashboard-data-grid-headers',
            renderCell: (params) => <Typography variant='subtitle2'>{params.row.endDate ? dayjs(params.row.endDate).format('DD/MM/YYYY') : ''}</Typography>,
            renderHeader: () => <RenderTextfieldHeader colData={columnTypes.regEndDate} label="End Date" filters={filters} handleChange={handleChange} />
        },
        {
            headerName: 'End Date', field: 'endDate', width: 155, sortable: false, headerClassName: 'dashboard-data-grid-headers',
            renderCell: (params) => <Typography variant='subtitle2'>{params.row[endDateValue] ? dayjs(params.row[endDateValue]).format('DD/MM/YYYY') : ''}</Typography>,
            renderHeader: () => <RenderTextfieldHeader colData={columnTypes.endDate} label={endDateLabel} filters={filters} handleChange={handleChange} />
        },
    ].filter(Boolean);

    // Regional Dates Columns
    const regionalDateColumns = regionalFields.flatMap(({ startField, startName, endField, endName }) => [
        isFieldNotEmpty(startName) && {
            headerName: startName, field: startName, width: 155, sortable: false, headerClassName: 'dashboard-data-grid-headers',
            renderCell: (params) => <Typography variant='subtitle2'>{params.row?.[startField] ? dayjs(params.row[startField]).format('DD/MM/YYYY') : ''}</Typography>,
            renderHeader: () => <RenderTextfieldHeader colData={columnTypes[startField]} label={modelAvailabilityData?.[0]?.[startName]} filters={filters} handleChange={handleChange} />
        },
        isFieldNotEmpty(endName) && {
            headerName: endName, field: endName, width: 155, sortable: false, headerClassName: 'dashboard-data-grid-headers',
            renderCell: (params) => <Typography variant='subtitle2'>{params.row?.[endField] ? dayjs(params.row[endField]).format('DD/MM/YYYY') : ''}</Typography>,
            renderHeader: (params) => <RenderTextfieldHeader colData={columnTypes[endField]} label={modelAvailabilityData?.[0]?.[endName]} filters={filters} handleChange={handleChange} />
        },
    ]).filter(Boolean);

    // WW Region Columns
    if (region === 'WW')
        regionCheckBoxColumns = ['apRegion', 'naRegion', 'laRegion', 'euRegion'].map(regionField => ({
            headerName: regionField.slice(0, 2).toUpperCase(),
            headerClassName: regionField === 'apRegion' ? 'dashboard-data-grid-checkbox-headers' : 'dashboard-data-grid-headers',
            field: regionField, width: 55, sortable: false,
            renderCell: (params) => <Checkbox disabled checked={params.row[regionField] === true} />,
        }))

    const actionColumn = {
        headerName: '', field: 'actions', width: 180, sortable: false,
        renderCell: (params) => (
            <Box onClick={(e) => e.stopPropagation()}>
                {region !== "WW" && <Tooltip title="Marketing Program Audit Trial">
                    <Button onClick={() => { handleFeedDialog(params.row, 'targetOfferAudit') }} startIcon={<TrackChangesIcon />} size="medium" />
                </Tooltip>
                }
                <Tooltip title="Audit Trail - Model">
                    <Button onClick={() => { handleFeedDialog(params.row, 'modelOfferAudit') }} startIcon={<FeedIcon />} size="medium" />
                </Tooltip>
                <Tooltip title={disableAction ? "View" : "Edit"}>
                    <Button onClick={() => { handleViewModel(params.row)}} startIcon={disableAction ? <VisibilityIcon /> : <EditIcon />} size="medium" />
                </Tooltip>
            </Box>
        ),
    };

    return [...baseColumns, ...regionalDateColumns, ...regionCheckBoxColumns, actionColumn];
};
