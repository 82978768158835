import { useMemo } from "react";
import { getTab } from "pages/Utils";
import { useSelector } from "react-redux";

export const useActiveDataSet = (selectedTab) => {

  const activeDataSet = useSelector(state => state.models[getTab(selectedTab)]);
  const datasetValues = useMemo(() => (activeDataSet ? Object.values(activeDataSet) : []), [activeDataSet]);
  
  return  {datasetValues};

};

export const getChoiceAndItemData = (state, selectedTab, choiceID, parentId, itemID) => {
  const tabData = state.models[getTab(selectedTab)] ?? {};
  if (choiceID && parentId === "0") {
    return tabData?.[choiceID] ?? null;
  } else if (parentId && tabData?.[parentId]?.items) {
    return tabData[parentId]?.items[itemID] ?? null;
  }
  return null;
};
