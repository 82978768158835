import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Typography, Box } from '@mui/material';
import { dataGridStyles } from 'pages/DashboardHelpers';

export const FilesDataGrid = ({ ...rest }) => {
    return (
        <DataGrid
            checkboxSelection
            rowHeight={49}
            columnHeaderHeight={90}
            className='dashboard-data-grid'
            disableSelectionOnClick
            getRowClassName={(params) => `${params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'} clickable`}
            headerClassName='dashboard-grid-headers'
            sx={dataGridStyles}
            components={{
                NoRowsOverlay: () => (
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "40px",
                        marginTop: "40px"
                    }}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography>No data found!</Typography>
                    </Box>
                )
            }}
            disableColumnFilter
            disableColumnMenu
            hideFooter
            {...rest}
        />
    )
}