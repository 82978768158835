import React, { useState } from "react";
import Messages from "../Tabs/Messages"
import PreSelectedItems from "../Tabs/PreSelectedItems"
import ItemsTabPanel from "components/ItemsTabPanel";
import ItemSettings from "../Tabs/ItemSettings";
import { Comments } from '../Tabs/Comments';
import { getTab } from "pages/Utils";
import { useSelector } from "react-redux";

const ItemEdit = (props) => {
  const dataTabName = getTab(props?.tabName);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const syntaxCheck = useSelector(state => state.models.syntaxCheck[dataTabName][props?.parentId]?.items[props?.itemID]);

  const tabs = [
    <ItemSettings
      key={`itemsettings-${props?.itemID}-${props?.parentId}-${props?.tabName}`}
      itemID={props?.itemID}
      choiceID={props?.parentId}
      tabName={props?.tabName}
      data={props?.itemData}
      syntaxCheck={syntaxCheck}
    />,
    <PreSelectedItems
      key={`preselected-${props?.itemID}-${props?.parentId}-${props?.tabName}-${JSON.stringify(props?.data?.defaultComponents)}`}
      choiceID={props?.parentId}
      isSystemChoice={props?.isSystemChoice}
      itemID={props?.itemID}
      tabName={props?.tabName}
      data = {props?.itemData}
      syntaxCheck={syntaxCheck}
    />,
    <Messages
      key={`messages-${props?.itemID}-${props?.parentId}-${props?.tabName}-${JSON.stringify(props?.data?.messages)}`}
      itemID={props?.itemID}
      choiceID={props?.parentId}
      tabName={props?.tabName}
      data={props?.itemData}
      syntaxCheck={syntaxCheck}
    />,
    <Comments
      key={`comments-${props?.itemID}-${props?.parentId}-${props?.tabName}-${JSON.stringify(props?.data?.comments)}`}
      itemID={props?.itemID}
      choiceID={props?.parentId}
      tabName={props?.tabName}
      data={props?.itemData}
    />
  ]

  return (
    <>
        <ItemsTabPanel
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
          hasComments={Boolean(props?.itemData?.comments?.length)}
          syntaxCheck={syntaxCheck}
        />
      {tabs[activeTabIndex]}
    </>
  );
};

export default ItemEdit;
