import { combineReducers } from "redux";
import userReducer from "./userReducer";
import modelReducer from "./modelReducer";
import dashboardReducer from './dashboardReducer';
import filesReducer from "./filesReducer";
import regionalsReducer from "./regionalsReducer";
import templatesReducer from "./templatesReducer";
import { undoReducer } from "./undoReducer";
import btpReducer from "./btpReducer";
import { ocplcReducer } from "./ocplcReducer";

const allReducers = combineReducers({
  user: userReducer,
  models: undoReducer(modelReducer),
  dashboard: dashboardReducer,
  files: filesReducer,
  regionals: regionalsReducer,
  templates: templatesReducer,
  btps:  btpReducer,
  ocplc: ocplcReducer
});

export default allReducers;
