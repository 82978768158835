import React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid2";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import DeleteIcon from "@mui/icons-material/Delete";
import { ITEMEDIT_LABELS } from "constants/modelConstants";
import { useStyles } from "./styles";
import ErrorToolTip from "pages/Common/ErrorToolTip";

const selectionTypes = {
    "T": "True",
    "F": "False",
    "TF": "Both",
    "R": "Required",
};

export const PreSelectedItem = ({ condition, errorData, type, index, handleEditComponent, handleDelete, tabName, isSystemChoice }) => {
    const classes = useStyles();

    return (
        <div key={`item-${index}`}>
            <Grid container style={{ alignItems: 'center' }}>
                <Grid size={6}>
                    <TextField
                        value={condition}
                        onChange={(e) => handleEditComponent(e.target.value, 'condition', index)}
                        multiline
                        id="DefaultComponentCondition"
                        variant="filled"
                        margin="dense"
                        label={ITEMEDIT_LABELS[tabName]["DefaultComponentCondition"]}
                        // placeholder="DefaultComponentCondition"
                        slotProps={{
                            input: {
                            classes: { input: classes.textarea },
                            disableUnderline: true
                            }
                        }}
                        disabled={isSystemChoice}
                    />
                </Grid>
                <Grid size={5}>
                    <FormControl>

                        <TextField
                            value={type}
                            onChange={e => handleEditComponent(e.target.value, 'type', index)}
                            id="DefaultComponentType"
                            select
                            variant="filled"
                            margin="dense"
                            label={ITEMEDIT_LABELS[tabName]["DefaultComponentType"]}
                            slotProps={{ input : { disableUnderline: true }}}
                            disabled={isSystemChoice}

                        >
                            {Object.keys(selectionTypes).map(key => (
                                <MenuItem key={key} value={key}>
                                    {selectionTypes[key]}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </Grid>
                {(condition && type) && <DeleteIcon className={classes.deleteButton} style={{ cursor: 'pointer' }} onClick={() => handleDelete(index)} />}
            </Grid>
            
            {errorData && 
                <ErrorToolTip data={errorData} />
            }
        </div>
    );
};
