import React from "react";
import { useState, useCallback, useMemo } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useGlobalStore } from 'hooks/useGlobalStore';
import { updateChoiceItemFields } from 'actions/modelActions';
import ErrorToolTip from "../../Common/ErrorToolTip";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useStyles } from "./styles";

const defaultMessages = { condition: "", description: "" };

export default function Message(props) {
  const classes = useStyles();

  const getMessagesSource = useMemo(() => {
    return props?.data?.messages?.length ? props?.data : { messages: [defaultMessages] };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data, props?.choiceID, props?.itemID]);

  const [itemWithMessages, setMessages] = useGlobalStore(getMessagesSource, updateChoiceItemFields, { choiceID: props?.choiceID, itemID: props?.itemID, tabCategory: props?.tabName });

  const invalidConditions = props?.syntaxCheck?.MessageCondition

  const [deleteIndex, setDeleteIndex] = useState(null);

  const handleModalClose = () => setDeleteIndex(null);

  const handleClickDelete = () => {
    setMessages((prevState) => ({ ...prevState, messages: prevState.messages.filter((_, i) => i !== deleteIndex), edited: true }));
    handleModalClose();
  };

  const handleUpdateMsg = useCallback((e, idx, type) => {
    setMessages((prevState) => ({ ...prevState, messages: prevState.messages.map((message, index) => index === idx ? { ...message, [type]: e.target.value } : message), edited: true }));
  }, [setMessages])

  const messagesLength = itemWithMessages?.messages?.length > 1;
  const msgToDelete = itemWithMessages?.messages[deleteIndex]?.condition;

  return (
    <>
        <div className={classes.root}>

          {itemWithMessages?.messages?.map((msg, index) => {
            const messageCondition = getMessagesSource.messages[index]?.condition || msg.condition;

            return (
              <div key={index}>
                <TextField
                  className={classes.messageWidth}
                  variant="filled"
                  margin="dense"
                  multiline
                  required
                  label="Message Condition"
                  // placeholder="Message Condition"
                  value={msg.condition}
                  onChange={(e) => handleUpdateMsg(e, index, 'condition')}
                  slotProps={{
                    input : {
                    classes: { input: classes.textarea },
                    disableUnderline: true
                  }
                  }}
                />
                {(msg.condition && msg.description) && messagesLength && <DeleteIcon className={classes.deleteIconButton} style={{ cursor: 'pointer' }} onClick={() => { setDeleteIndex(index) }} />}
                {invalidConditions?.[messageCondition] && (<ErrorToolTip data={invalidConditions[messageCondition]} />)}
                <TextField
                  className={classes.messageWidth}
                  variant="filled"
                  margin="dense"
                  required
                  multiline
                  label="Message Description"
                  // placeholder="Message Description"
                  value={msg.description}
                  onChange={(e) => handleUpdateMsg(e, index, 'description')}
                  slotProps={{
                    input : {
                    classes: { input: classes.textarea },
                    disableUnderline: true
                    }
                  }}
                />
              </div>
            )
          })}
          <Button variant="text" className={classes.addMsgButton} onClick={() => setMessages((prevState) => ({ ...prevState, messages: [...prevState.messages, defaultMessages], edited: true }))}><AddIcon /> Add New Message</Button>
        </div>

        {deleteIndex !== null &&
          <ConfirmationDialog
            open={deleteIndex !== null}
            title="Delete Message"
            content={<>You are about to delete "<b>{msgToDelete}</b>" message condition.<br /> Are you sure you want to delete it?</>}
            onClose={handleModalClose}
            onConfirm={handleClickDelete}
            caption="Delete"
            bgColor="#BE1313"
          />
        }
    </>
  );
}
