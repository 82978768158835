export const HPUID_LOGIN_URL = process.env.REACT_APP_HPUID_LOGIN_URL;
export const GRAPHQL_BASE_URL = process.env.REACT_APP_GRAPHQL_BASE_URL;
export const GRAPHQL_SECONDARY_URL = process.env.REACT_APP_GRAPHQL_SECONDARY_URL;
export const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const REACT_APP_SECONDARY_ENVIRONMENT = process.env.REACT_APP_SECONDARY_ENVIRONMENT;

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const KB_USER_DATA = "KB_USER_DATA";

export const TOKEN_RENEW_REQUEST = "TOKEN_RENEW_REQUEST";
export const TOKEN_RENEW_SUCCESS = "TOKEN_RENEW_SUCCESS";
export const TOKEN_RENEW_FAIL = "TOKEN_RENEW_FAIL";

export const RECEIVE_ACCESS_TOKEN = "RECEIVE_ACCESS_TOKEN";
export const CLEAR_USER_INFO = "CLEAR_USER_INFO";

export const SET_ALL_USERS = "SET_ALL_USERS";
export const SET_ROLE_LIST = "SET_ROLE_LIST";

export const USERS = [
    {
        userName: 'John Doe',
        password: '1',
        userEmail: 'test.hp@hp.com',
        roles: ['KB Engineer'],
    },
    {
        userName: 'John Doe',
        password: '1',
        userEmail: 'test.hp@hp.com',
        roles: ['KB Admin'],
    }
];
