import React, {  useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { DataGrid } from '@mui/x-data-grid';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { refreshTemplates, filterTemplates } from 'actions/templatesActions';
import { clearModelState } from 'actions/undoActions';
import ActionButtons from '../ActionButtons';
import RoundedPagination from 'pages/Pagination';
import { container, dataGridStyles } from 'pages/DashboardHelpers';
import RenderTextfieldHeader from 'pages/renderTextFieldHeader';

function TemplatesDashboard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { filters, filteredTemplates, isLoading } = useSelector(state => state.templates.filteredTemplates && state.templates)

    useEffect(() => {
        dispatch(refreshTemplates());
    }, [dispatch]);

    const handleChange = useCallback((col, value) => {
        dispatch(filterTemplates({ ...col, searchText: value }))
    }, [dispatch]);

    const editTemplate = (rowDetails) => {
        navigate({ pathname: `/admin/templates/${rowDetails.kmatID}` });
        dispatch(clearModelState());
    }

    const columnTypes = {
        kmatID: {
            type: 'text',
            name: 'kmatID',
            matchFields: ['kmatID']
        },
        name: {
            type: 'text',
            name: 'name',
            matchFields: ['name']
        },
    };


    const columns = [
        {
            headerName: 'KMAT ID',
            field: 'kmatID',
            headerClassName: 'dashboard-data-grid-headers',
            flex: 0.4,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div>
                        <Typography variant='subtitle2'>{params.row.kmatID}</Typography>
                    </div>
                );
            },
            renderHeader: () => <RenderTextfieldHeader colData={columnTypes?.kmatID} label={"KMAT ID"} filters={filters} handleChange={handleChange}/>,
        },
        {
            headerName: 'Manufacturing Name',
            field: 'name',
            headerClassName: 'dashboard-data-grid-headers',
            headerAlign: 'left',
            flex: 0.4,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div>
                        <Typography variant='subtitle2'>{params.row.name}</Typography>
                    </div>
                );
            },
            renderHeader: () => <RenderTextfieldHeader colData={columnTypes?.name} label={"Manufacturing Name"} filters={filters} handleChange={handleChange}/>,
        },
        {
            headerName: '',
            field: 'actions',
            headerClassName: 'dashboard-data-grid-headers',
            headerAlign: 'left',
            flex: 0.2,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className='header-actions'>
                        <IconButton className='edit-template-button' disableRipple onClick={() => editTemplate(params.row)} >
                            <DriveFileRenameOutlineIcon />
                        </IconButton>
                    </div>
                );
            }
        },
    ]

    return (
        <Box sx={container}>
            <ActionButtons title="Templates" />
            <DataGrid
                columns={columns}
                onRowClick={(row) => editTemplate(row.row)}
                rows={filteredTemplates}
                getRowId={(row) => row.kmatID}
                loading={isLoading}
                rowHeight={48}
                columnHeaderHeight={90}
                className='dashboard-data-grid'
                disableSelectionOnClick
                getRowClassName={(params) => `${params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'} clickable`}
                headerClassName='dashboard-grid-headers'
                initialState={{ pagination: { paginationModel: { page: 0, pageSize: 10} } }}
                slots={{ pagination: RoundedPagination }}
                sx={dataGridStyles}
                disableColumnFilter
                disableColumnMenu
            />

        </Box>
    )
}

export default TemplatesDashboard;
