import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getColumns } from './columnsConfig';
import { DataGrid } from '@mui/x-data-grid';
import { DashboardPopup } from './DashboardPopup';
import { filterDashboard, refreshDashboard } from 'actions/dashboardActions';
import { clearModelState } from 'actions/undoActions';
import ActionButtons from '../ActionButtons';
import { container, dataGridStyles } from '../DashboardHelpers';
import "./Dashboard.scss";
import RoundedPagination from 'pages/Pagination';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from 'pages/EditModel/IndexHelpers';

function Dashboard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [isBtnMenuOpen, setIsBtnMenuOpen] = useState({ state: false, el: null });
    const [buttonArgs, setButtonArgs] = useState({});

    const publishLoading = useSelector(state => state.models.publishLoading);
    const userEmail = useSelector(state => state.user.kbUser !== null && state.user.kbUser?.email);
    const { productLines, statuses, role, filters, filteredProducts, isLoading } = useSelector(state => state.dashboard.role !== null && state.dashboard); 

    const handleNavRefresh = () => {
        if (role !== "admin" && !isLoading) {
            dispatch(filterDashboard({ type: 'text', name: 'productLineID', matchFields: ['assignedToEmail', 'assignedToName'], searchText: userEmail }));
        }
    }
 
    useEffect(() => {
        dispatch(refreshDashboard());
        dispatch(clearModelState());
    }, [dispatch]);
   
    useEffect(() => {
        handleNavRefresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userEmail, role, isLoading, dispatch]);

    const handlePopup = useCallback((e, btnArgs) => {
        e.stopPropagation();
        setIsBtnMenuOpen({ state: true, el: e.currentTarget });
        setButtonArgs(btnArgs);
    }, []);

    const handleViewModel = useCallback((rowDetails) => {
        const { kmatID, actions: { lastDraft } = {} } = rowDetails;
        if (kmatID && lastDraft) {
            navigate(`/model/${kmatID}/${lastDraft.version}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, navigate]);

    const handleFilterChange = useCallback((col, value) => {
        dispatch(filterDashboard({ ...col, searchText: value }))
    }, [dispatch]);

    const columns = getColumns(filters, handleFilterChange, statuses, productLines, handlePopup, userEmail, role);

    return (
        <Box sx={{ ...container, minWidth: "100%" }}>
            {publishLoading && (<Box className={classes.publishLoading}><CircularProgress color="primary" /></Box>)}
            {userEmail && <ActionButtons title="New Models and Models with Updates" handleNavRefresh={handleNavRefresh} />}

            {buttonArgs.id && isBtnMenuOpen?.state &&
                <DashboardPopup
                    isBtnMenuOpen={isBtnMenuOpen}
                    setIsBtnMenuOpen={setIsBtnMenuOpen}
                    row={buttonArgs.row}
                    role={role}
                    id={buttonArgs.id}
                />}

            {userEmail && (
                <DataGrid
                    columns={columns}
                    rows={filteredProducts}
                    getRowId={(row) => row.kmatID}
                    loading={isLoading}
                    rowHeight={48}
                    columnHeaderHeight={90}
                    className='dashboard-data-grid'
                    onRowClick={(params) => params.row.status !== 'New' && handleViewModel(params.row)}
                    slots={{ pagination: RoundedPagination }}
                    disableSelectionOnClick
                    getRowClassName={(params) => `${params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'} ${params.row.status !== 'New' ? 'clickable' : ''}`}
                    headerClassName='dashboard-grid-headers'
                    initialState={{ pagination: { paginationModel: { page: 0, pageSize: 10 } } }}
                    sx={dataGridStyles}
                    disableColumnFilter
                    disableColumnMenu
                    hideFooterSelectedRowCount={true}
                />)
            }
        </Box>
    )
}

export default Dashboard;
