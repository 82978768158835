import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    tabBorder: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        marginBottom: "13px",
    },
    activeDot: {
        height: "20px",
        width: "20px",
        marginLeft: "5px",
    },
    dot: {
        height: "16px",
        width: "16px",
        marginLeft: "5px",
    },
}));
