import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { updateCancelInit, updateSaveInit } from "actions/modelActions";
import { CVGMGMT_TAB } from "constants/modelConstants";
import "./EditModel/EditModel.scss";
import { useStyles,useCommonStyles } from "./EditModel/IndexHelpers";
import RegionalItemEdit from "pages/RegionalModel/RegionalItem";
import RegionalChoice from "pages/RegionalModel/RegionalChoice";
import { undoProperty } from "actions/undoActions";
import Label from "./EditModel/label";
import ActionableButtons from "./EditModel/ActionableButtons";

const EditRegional = (props) => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();
	const dispatch = useDispatch();
	const choiceID = props?.currentActiveNode?.choiceID;
	const parentId = props?.currentActiveNode?.parentId;
	const itemID = props?.currentActiveNode?.itemID;
	const sysChoiceID = props?.currentActiveNode?.dataType;
	const [isSaving, setIsSaving] = useState(false);
	const publishLoading = useSelector((state) => state.models.publishLoading);


	const editTitle = useMemo(() => {
		if (!props?.currentActiveNode) return null;
		if (itemID) return 'Item Details';
		if (sysChoiceID === "SYS") return 'System Choice';

		return 'Choice Details';
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props?.currentActiveNode]);

	const handleSaveClick = async () => {
		setIsSaving(true);
		dispatch(updateSaveInit(true));
		setIsSaving(false);
	};

	const handleCancelClick = () => {
		const cancelChoiceID = itemID ? parentId : choiceID;
		// executed when items are bulk edited
		if (!itemID && !cancelChoiceID && props?.selectedTab !== CVGMGMT_TAB) {
			dispatch(updateCancelInit(true));
			return
		}
		dispatch(undoProperty({ itemID, choiceID: cancelChoiceID }));
	};

	const areActionableButtonsVisible = (sysChoiceID !== "SYS" && Object.entries(parentId ?? {}).length > 0);

	const renderEditableContent = () => {
		if (props?.datasetValues && props?.datasetValues.length === 0) {
			return <div className={commonClasses.noItem}>No data found!</div>;
		}
		if (itemID) {
			return (
				<RegionalItemEdit
					key={`displayEdit-${props?.selectedTab}-${props?.currentActiveNode.id}`}
					tabName={props?.selectedTab}
					data={props?.currentActiveNode}
					itemID={itemID}
					choiceID={parentId}
				/>
			);
		}
		return (
			<RegionalChoice
				key={`displayChoiceEdit-${props?.selectedTab}-${choiceID}`}
				data={props?.currentActiveNode}
			/>
		);
	};

	return (
		<>
			<div className={classes.root}>
				{publishLoading && (<Box className={classes.publishLoading}><CircularProgress color="primary" /></Box>)}
				<>
					<Label editTitle={editTitle} />
					{renderEditableContent()}
					<div className={commonClasses.actionableButtons}>
						{(areActionableButtonsVisible) && (
							<ActionableButtons
								isSaving={isSaving}
								handleSaveClick={handleSaveClick}
								handleCancelClick={handleCancelClick}
								dataType={props?.dataType}
								kmatId={props?.kmatId}
								currentVersion={props?.currentVersion}

							/>
						)}
					</div>
				</>
			</div>
		</>
	)
};

export default EditRegional;