import { React, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import { retrieveBackgroundColor } from "pages/Dashboard/columnsConfig";
import { fetchData } from "actions/modelActions";
import { useStyles } from "./ModelHeaderHelpers";
import WorkSpaceButton from "components/WorkSpaceButtons/WorkSpaceButton";
import WorkSpaceButtonRegional from "components/WorkSpaceButtons/WorkSpaceButtonRegional";
import StatusInfo from "components/StatusInfo";
import KmatHeaderCard from "./KmatHeaderCard";
import { getCurrentVersion } from "reducers/modelUtils";

const KmatDetails = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { kmatId, version } = useParams();
  const currentVersion = getCurrentVersion(props?.dataType, version);
  const kmat = useSelector((state) => state.models.kmat);
  const error = useSelector((state) => state.models.error);

  const kmatStatus = kmat?.status;
  const publishedVersion = currentVersion?.split(".")[1] === "1";
  
  useLayoutEffect(() => {
    dispatch(fetchData(kmatId, currentVersion, props?.dataType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, kmatId]);
  
  if (!kmat) return <StatusInfo loading={!kmat} loadingMessage={`Fetching the ${props?.dataType} details.... Please wait`} error={error} />


  return (
    <>
      <div className={classes.root}>

        <div className={classes.gridContainer}>
          <div className={classes.gridItem}>
            <Typography variant="h5" className={classes.title}>{props?.dataType === 'template' ? "Template" : "KB Model"} {kmat?.kmatID}</Typography>
            {kmatStatus &&
              <Chip
                size="small"
                style={{
                  background: retrieveBackgroundColor(kmatStatus),
                  color: ['Updates available', 'In progress', 'Published'].includes(kmatStatus) ? '#ffffff' : 'inherit',
                  width: 'fit-content'
                }}
                label={kmatStatus}
              />
            }
            {publishedVersion ? (
              <Chip label="Production Draft" color="success" size="small" />
            ) : (
              <Chip label="Draft Version" className={classes.chipColor} size="small" />
            )}
          </div>
          {props?.dataType === "regional" ? <WorkSpaceButtonRegional kmatId={kmat?.kmatID} /> :
            <WorkSpaceButton kmatId={kmat?.kmatID} kmat={kmat} dataType={props?.dataType} currentVersion={currentVersion} />}
        </div>
      </div>
      <KmatHeaderCard kmat={kmat} dataType={props?.dataType} currentVersion={currentVersion} kmatID = {kmatId} />
    </>
  );
};

export default KmatDetails;
