import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import ActionButtons from '../ActionButtons';
import DefineColumns from './DefineColumns';
import { filterOcplc, ocplcTableData, refreshOcplc } from 'actions/ocplcAction';
import { dataGridStyles, container } from "../DashboardHelpers";
import CustomPagination from './CustomPagination';
import { getRowClassName, pageSizeOptions } from './ocplcHelpers';
import { snackBar } from 'actions/modelActions';
import { useNavigate } from 'react-router';

const OcplcDashboard = ({ Api, auth }) => { 
    const userRole = auth?.ocplcRole;
    const userEmail = auth?.email;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { filteredOcplcData, filters, loading, ocplcProductLines, statuses, tableData, ocplcSelectedKmats } = useSelector(state => state.ocplc);

    const [checkedRows, setCheckedRows] = useState([]);

    useEffect(() => {
        dispatch(refreshOcplc());
        dispatch(ocplcTableData());
        dispatch(snackBar(false));
    }, []);

    const handleChange = useCallback((col, value) => {
        dispatch(filterOcplc({ ...col, searchText: value }))
    }, []);

    const handleRowClick = (params, event) => {
        const { kmatId, productLineID, productGroup } = params.row;
        if (event.target.type !== "checkbox") {
            event.defaultMuiPrevented = true;
        }
        if (event.target.type !== 'checkbox' && !event.target.closest('.action-button-class')) {
            if (!kmatId || !productLineID || !productGroup) {
                dispatch(snackBar(true, `Product Line is empty for KMAT : ${kmatId}`, 'error'));
            } else {
                navigate(`/ocplc/viewRecord/${kmatId}/${productGroup}/${productLineID}`);
            }
        }
    };

    const columns = DefineColumns({
        filters: filters || [],
        handleChange,
        ocplcProductLines: ocplcProductLines || [],
        statuses: statuses || [],
        userEmail,
        userRole
    });

    const handleRowSelection = useCallback((newSelection) => {
        const selectedItems = filteredOcplcData.filter(row => newSelection.includes(row.kmatId));
        setCheckedRows(selectedItems);
    }, [filteredOcplcData]);

    return (
        <Box sx={{ ...container, minWidth: "60%" }}>
            <ActionButtons 
                title={"OCPLC Dashboard"} 
                Api={Api} 
                tableData={tableData} 
                ocplcSelectedKmats={ocplcSelectedKmats} 
                selectedRows={checkedRows} 
                userRole={userRole}
                setCheckedRows={setCheckedRows}
                userEmail={userEmail}
            />
                <DataGrid
                    columns={columns}
                    rows={filteredOcplcData}
                    getRowId={(row) => row.kmatId}
                    loading={loading}
                    rowHeight={48}
                    columnHeaderHeight={90}
                    className='dashboard-data-grid'
                    slots={{ pagination: (props) => <CustomPagination {...props} pageSizeOptions={pageSizeOptions} /> }}
                    disableSelectionOnClick
                    getRowClassName={getRowClassName}
                    headerClassName='dashboard-grid-headers'
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    sx={dataGridStyles}
                    disableColumnFilter
                    disableColumnMenu
                    hideFooterSelectedRowCount={true}
                    checkboxSelection
                    onRowSelectionModelChange={handleRowSelection}
                    onRowClick={handleRowClick}
                />
        </Box>
    );
};

export default OcplcDashboard;
