import { GraphqlApi } from 'services/api';
import { jwtDecode } from 'jwt-decode';
import { clearAllCookies, setCookie, clearCookie } from "utils/function-utils";
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    TOKEN_RENEW_FAIL,
    TOKEN_RENEW_REQUEST,
    TOKEN_RENEW_SUCCESS,
    RECEIVE_ACCESS_TOKEN,
    CLEAR_USER_INFO,
    USERS,
    SET_ALL_USERS,
    SET_ROLE_LIST,
} from "constants/userConstants";
import { snackBar } from './modelActions';


export const login = (email, password) => async (dispatch) => {
    dispatch({ type: USER_LOGIN_REQUEST, payload: { email, password } });
    try {
        // TODO: Create fixed data, instead real from axios
        const data = USERS[password === "admin" ? 1 : 0];
        const token = "123"; // void token
        data['userEmail'] = email;
        dispatch({ type: USER_LOGIN_SUCCESS, payload: { data, token } });
        setCookie("userInfo", JSON.stringify(data));
        setCookie("access_token", "123");
    } catch (err) {
        dispatch({ type: USER_LOGIN_FAIL, payload: err.message });
        dispatch(snackBar(true, err.message, 'error'));
    }
};

export const receiveAccessToken = (access_token, expires_at) => async (dispatch) => {
    try {
        let user = jwtDecode(access_token);
        if (access_token && expires_at && expires_at > new Date().getTime() / 1000) {
            setCookie("userInfo", user);
            setCookie("access_token", access_token);
            setCookie("expiresAt", expires_at);
            dispatch({
                type: RECEIVE_ACCESS_TOKEN,
                payload: { user, token: access_token, expires_at }
            });
        } else {
            var expires_time = new Date(expires_at * 1000);
            throw new Error("Token has expired at " + expires_time.toLocaleString());
        }
    } catch (error) {
        dispatch({ type: USER_LOGIN_FAIL, payload: error.message });
        dispatch(snackBar(true, error.message, 'error'));
    }
}

export const resetAccessCookies = () => {
    clearCookie("access_token");
    clearCookie("expiresAt");
    clearCookie("access_token", { path: "/", domain: ".hpicloud.net" });
    clearCookie("expires", { path: "/", domain: ".hpicloud.net" });
    clearCookie("access_token", { path: "/", domain: ".hp.com" });
    clearCookie("expires", { path: "/", domain: ".hp.com" });
};

export const renewToken = () => (dispatch) => {
    dispatch({ type: TOKEN_RENEW_REQUEST });
    GraphqlApi.query('renewToken').then((tokenResponse) => {
        const tokenDetails = tokenResponse.data.renewToken;
        resetAccessCookies();
        setCookie("access_token", tokenDetails.token, {
            path: "/",
            domain: window.location.hostname
        });
        setCookie("expires", tokenDetails.expires_at, {
            path: "/",
            domain: window.location.hostname
        });
        dispatch({ type: TOKEN_RENEW_SUCCESS, payload: tokenDetails.token });
    }).catch(error => {
        dispatch(snackBar(true, error.message));
        dispatch({ type: TOKEN_RENEW_FAIL, payload: error.message });
    })
};


export const logout = () => async (dispatch) => {
    clearAllCookies();
    dispatch({ type: CLEAR_USER_INFO });
};

export const getUsers = () => {
    return async (dispatch) => {
        try {
            const res = await GraphqlApi.query('getUsers');
            const users = res.data.kbUsersWithRoles.users;
            const roles = res.data.kbUsersWithRoles.roles;
            dispatch(setAllUsers(users));
            dispatch(setRoleList(roles));
        } catch (error) {
            dispatch(snackBar(true, error.message));
        }
    };
};

export const setAllUsers = (users) => ({ type: SET_ALL_USERS, payload: users });

export const setRoleList = (roles) => ({ type: SET_ROLE_LIST, payload: roles });

export const updateUser = (firstNameUpdation, lastNameUpdation, chosenUser, selectedValue) => {
    return async (dispatch) => {
        try {
            await GraphqlApi.query("updateKbUser", {
                model: {
                    firstName: firstNameUpdation,
                    lastName: lastNameUpdation,
                    email: chosenUser.email,
                    role: chosenUser?.role || '',
                    ocplcRole: chosenUser?.ocplcRole || '',
                    roles: selectedValue
                }
            });
            dispatch(snackBar(true, "User updated successfully", "success"))
            dispatch(getUsers());
        } catch (error) {
            dispatch(snackBar(true, error.message, "error"));
        }
    };
};

export const deleteUser = (chosenUser) => {
    return async (dispatch) => {
        try {
            await GraphqlApi.query("deleteKbUser", { email: chosenUser.email });
            dispatch(snackBar(true, "User deleted successfully", "success"))
            dispatch(getUsers());
        } catch (error) {
            dispatch(snackBar(true, error.message, "error"));
        }
    };
};

export const createUser = (firstName, lastName, email, selectedValue) => {
    return async (dispatch) => {
        try {
            await GraphqlApi.query("createKbUser", {
                model: {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    role: '',
                    ocplcRole: '',
                    roles: selectedValue
                }
            });
            dispatch(snackBar(true, "User created successfully", "success"))
            dispatch(getUsers());
        } catch (error) {
            dispatch(snackBar(true, error.message, "error"));
        }
    };
};
