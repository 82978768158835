export const ocplcDashboard = `query {
    ocplcDashboard {
       ocplcProductModels {
      kmatId
      productLineID
      productLineName
      manufacturingName
      productGroup
      status
    }
    ocplcProductLines {
      id
      name
      count
    }
    statuses
    users {
      firstName
      lastName
      email
      role
    }
    }
}`