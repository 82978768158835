import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import ConfirmationDialog from "components/ConfirmationDialog";
import useKeypress from "hooks/useKeypress";
import { SYNTAX_CHECK_DONE, SYNTAX_CHECK_REQUIRED } from "constants/modelConstants";
import { syntaxCheck } from "actions/modelActions";

const ActionableButtons = ({ isSaving, handleSaveClick, handleCancelClick, dataType, kmatId, currentVersion, isTabClicked, setTabClicked }) => {
  const dispatch = useDispatch();
  const [isCancelDialogOpen, setCancelDialogOpen] = useState(false);
  const syntaxCheckRequired = useSelector((state) => state.models.syntaxCheckRequired);

  const handleOpenCancelDialog = () => { setCancelDialogOpen(true) };

  const handleCloseCancelDialog = () => {
    setCancelDialogOpen(false)
    setTabClicked(false)
  };

  const handleConfirmCancel = () => {
    setCancelDialogOpen(false);
    handleCancelClick();
  };

  useEffect(() => {
    if (syntaxCheckRequired) {
        dispatch({ type: SYNTAX_CHECK_DONE, payload: true });
        dispatch({ type: SYNTAX_CHECK_REQUIRED, payload: false });
        if (dataType !== "template")
          dispatch(syntaxCheck(kmatId, currentVersion));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataType, dispatch, syntaxCheckRequired]);


  useKeypress({ s: handleSaveClick, Escape: handleOpenCancelDialog }, true);

  return (
    <div style={{ display: "inline-flex", gap: "10px" }}>

      {(isCancelDialogOpen || isTabClicked) &&
        <ConfirmationDialog
          open={isCancelDialogOpen || isTabClicked}
          title="Discard changes"
          content={<>Are you sure you want to discard the changes you've made?</>}
          onClose={handleCloseCancelDialog}
          onConfirm={handleConfirmCancel}
          caption="Discard"
          bgColor="#BE1313"
        />}

      <Button variant="outlined" className='cancel-button' onClick={handleOpenCancelDialog} >
        Cancel
      </Button>
      <Button variant="outlined" className='add-Button' onClick={handleSaveClick}>
        {isSaving ? "Saving..." : "Save"}
      </Button>
    </div>
  );
};

export default ActionableButtons;
