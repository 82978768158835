import { HIDE_LABELS, OCPLC_READ_ONLY, OCPLC_REGIONAL, PLC, PLC_ADMIN, PLC_AP, PLC_EU, PLC_LA, PLC_NA, PLC_REGIONAL, PLC_WW, REGION_AP, REGION_EU, REGION_LA, REGION_NA, REGION_WW, REGIONS, ROLE_FROM_DROPDOWN, ROLE_NAME_FOR_ADMIN, ROLE_NAME_FOR_KE, ROLE_NAME_FOR_KE_AP, ROLE_NAME_FOR_KE_EU, ROLE_NAME_FOR_KE_LA, ROLE_NAME_FOR_KE_NA, VERSION_0002, VERSION_0003 } from "constants/ocplcConstants";

export const currentDate = () => {
  const date = new Date()
  return date.toISOString()
};

export const findSelectedRole = (roles, roleId) => {
    return roles.find((role) => role.id === roleId) || null;
  };

export function capitalizeFirstLetter(val) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

export const getPlcRoles = (roles) => {
  return roles?.filter(role => role?.id.startsWith('plc')) || [];
};

export const isPLCAdmin = (userRole) => userRole === PLC_ADMIN;

export const isPLCWW = (userRole) => userRole === PLC_WW;


export const isUserDisabled = (userRole, region) => {
  return userRole === PLC_WW || 
         userRole === PLC_REGIONAL || 
         (userRole === PLC && REGIONS.includes(region)) || 
         (userRole === PLC_AP && region !== REGION_AP) || 
         (userRole === PLC_LA && region !== REGION_LA) || 
         (userRole === PLC_NA && region !== REGION_NA) || 
         (userRole === PLC_EU && region !== REGION_EU);
};

export const getVersion = (type) => {
  if (type === 'edit') return VERSION_0002;
  else if (type === 'viewRecord') return VERSION_0003;
  else return null;
};

export const filterDetails = (details, selectKmat, version) => {
  return details.filter((detail) => 
      !((HIDE_LABELS.includes(detail.label) && selectKmat === '') || 
        (detail.label === "Status" && version === VERSION_0003))
  );
};

export const updateCountryDescriptionById = (updatedData, field, value, countryList) => {
  if (field === 'countryID') {
      const selectedCountry = countryList.find(country => country.countryCode === value);
      if (selectedCountry) {
          updatedData['countryDesc'] = selectedCountry.countryName;
      }
  }
  return updatedData;
};

export const isDTButtonDisabled = (userRole, region, assignedRole, checkedRows) => {
  const isRegionWW = region === REGION_WW;
  const isAdminRole = assignedRole === ROLE_NAME_FOR_ADMIN;
  const isKERole = assignedRole === ROLE_NAME_FOR_KE;
  const isRegionalRole = assignedRole === OCPLC_REGIONAL;
  const isReadOnlyRole = assignedRole === OCPLC_READ_ONLY;
  const hasSelectedRows = checkedRows.length === 0;

  if (isKERole) {
    return !isRegionWW || hasSelectedRows;
  }

  return !isAdminRole && (isPLCWW(userRole) || isRegionWW || isRegionalRole || isReadOnlyRole) || hasSelectedRows;
};

export const isCopyDeleteDisabled = (version, assignedRole, region, checkedRow) => {
  return version === VERSION_0003 ||
      (assignedRole === ROLE_NAME_FOR_KE_AP && region === REGION_WW) ||
      (assignedRole === ROLE_NAME_FOR_KE_NA && region === REGION_WW) ||
      (assignedRole === ROLE_NAME_FOR_KE_EU && region === REGION_WW) ||
      (assignedRole === ROLE_NAME_FOR_KE_LA && region === REGION_WW) ||
      (assignedRole === ROLE_NAME_FOR_KE && region !== REGION_WW) ||
      (assignedRole !== ROLE_NAME_FOR_KE && assignedRole !== ROLE_NAME_FOR_ADMIN &&
      (assignedRole === OCPLC_READ_ONLY || assignedRole === OCPLC_REGIONAL)) ||
      checkedRow.length !== 1 
};

export const isAddDisabled = (version, assignedRole, region, kmatId) => {
  if (version !== VERSION_0002 || kmatId !== "") return true;
  return !(
    (assignedRole === ROLE_NAME_FOR_ADMIN ||
      assignedRole === ROLE_NAME_FOR_KE) &&
    region === REGION_WW
  );
};