export const ocplcModelTOFAuditTrial=`query GetModelTOFAuditTrial($filter: ModelTOFAuditTrialRequest!) {
    ocplcModelTOFAuditTrial(filter: $filter) {
      modelTOFAuditTrial {
        action
        version
        region
        country
        productGroup
        productFamily
        businessModel
        programType
        programs
        createDate
        startDate
        endDate
        comments
        status
        whoChange
        lastModifiedDate
      }
    }
  }`

export const ocplcModelAvailabilityAuditTrial = `query ocplcModelAvailabilityAuditTrial($filter: ModelAuditAvailabilityRequest!) {
  ocplcModelAvailabilityAuditTrial(filter: $filter) {
    modelAuditTrial {
      action
      version
      region
      productGroup
      productFamily
      country
      businessModel
      createDate
      startDate
      endDate
      hStartDate
      hEndDate
      comments
      status
      whoChange
      ap
      la
      eu
      na
      lastModifiedDate
    }
  }
}`