import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { updateCancelInit } from "actions/modelActions";
import { CVGMGMT_TAB } from "constants/modelConstants";
import Label from "./label";
import ChoiceEdit from "./ChoiceEdit/ChoiceEdit";
import ItemEdit from "./ItemEdit/ItemEdit";
import ActionableButtons from "./ActionableButtons";
import MultiItemEdit from "./MultiItemEdit/MultiItemEdit";
import "./EditModel.scss";
import { useStyles,useCommonStyles } from "./IndexHelpers";
import { undoProperty } from 'actions/undoActions';
import { updateSaveInit } from 'actions/modelActions';
import { getChoiceAndItemData } from "pages/Utils/TabDataUtil";


const EditModel = (props) => {
	const classes = useStyles();
	const commonClasses = useCommonStyles();
	const dispatch = useDispatch();
	const choiceID = props.currentActiveNode?.choiceID;
	const parentId = props.currentActiveNode?.parentId;
	const itemID = props.currentActiveNode?.itemID;
	const sysChoiceID = props.currentActiveNode?.dataType;
	const isBulkEditMode = Array.isArray(props.currentActiveNode);
	const publishLoading = useSelector((state) => state.models.publishLoading);
	const [isEfficientlyEditedInBulkMode, setIsEfficientlyEditedInBulkMode] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	  
	let choiceData = useSelector(state => getChoiceAndItemData(state, props.selectedTab, choiceID, parentId, itemID));

	const editTitle = useMemo(() => {
        if ((props.datasetValues && props.datasetValues.length === 0)) return null;
        if (isBulkEditMode) return 'Bulk Edit';
        if (itemID) return 'Item Details';
        if (sysChoiceID === "SYS") return 'System Choice';
 
        return 'Choice Details';
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentActiveNode, props.datasetValues.length]);

	const handleSaveClick = async () => {
		if (isBulkEditMode) {
			props.setActiveNode({ ...props.activeNode, [props.selectedTab]: props.datasetValues[0] })
			props.setCheckedItems([]);
		}
		setIsSaving(true);
		dispatch(updateSaveInit(true));
		setIsSaving(false);
		props.setToggleNavigation(false);
		setIsEfficientlyEditedInBulkMode(false);
	};

	const handleCancelClick = () => {
		const cancelChoiceID = itemID ? parentId : choiceID;
		// executed when items are bulk edited
		if (!itemID && !cancelChoiceID && props.selectedTab !== CVGMGMT_TAB) {

			const bulkEditedChoices = props.datasetValues
				.filter((choice) => Object.values(choice.items).some((item) => props.checkedItems.includes(item?.itemID)))
				.map((choice) => choice.choiceID);
			props.setCheckedItems([]);
			props.setActiveNode({ ...props.activeNode, [props.selectedTab]: props.datasetValues[0] })
			dispatch(undoProperty({ bulkEditedChoices }));
			dispatch(updateCancelInit(true));
			props.setTabClicked(false);
			// dispatch(resetTree(props.toggleNavigation));
			props.setToggleNavigation(false);
			setIsEfficientlyEditedInBulkMode(false);
			return
		}
		dispatch(undoProperty({ itemID, choiceID : cancelChoiceID }));
	};

	const BulkEditData = useMemo(() => {
		return props.datasetValues?.map((choice) => {
			const checkedItems = Object.values(choice.items).filter((childItem) => props.checkedItems.includes(childItem.itemID));
			return checkedItems.length > 0 && { ...choice, items: [...checkedItems] };
		}).filter(Boolean);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.selectedTab, props.checkedItems]);

	const renderEditableContent = () => {
		if (props.datasetValues && props.datasetValues.length === 0) {
			return <div className={commonClasses.noItem}>No data found!</div>;
		}

		if (isBulkEditMode) {
			return <MultiItemEdit BulkEditData={BulkEditData} tabName={props.selectedTab} setIsEfficientlyEditedInBulkMode={setIsEfficientlyEditedInBulkMode} isBulkEditMode={isBulkEditMode}/>;
		}

		if (itemID) {
			return (
				<ItemEdit
					key={`displayEdit-${props.selectedTab}-${itemID}`}
					parentId={parentId}
					itemID={itemID}
					isSystemChoice={props.currentActiveNode?.isSystemChoice}
					itemData={choiceData}
					data ={props.currentActiveNodee}
					tabName={props.selectedTab}
				/>
			);
		}

		return (
			<ChoiceEdit
				key={`displayChoiceEdit-${props.selectedTab}-${choiceID}`}
				choiceID={choiceID}
				choiceData={choiceData}
				data={props.currentActiveNode}
				sysChoiceID={sysChoiceID}
				tabName={props.selectedTab}
			/>
		);
	};

	const areActionableButtonsVisible = (
		(props.datasetValues && props.datasetValues.length !== 0) &&
		(sysChoiceID !== "SYS" && Object.entries(parentId ?? {}).length > 0)
	  ) || isBulkEditMode;
	  
	return (
		<>
		<div className={classes.root} style={{ ...(isBulkEditMode && { display: "flex", flexDirection: "column" }) }}>
	        {publishLoading && (<Box className={classes.publishLoading}><CircularProgress color="primary" /></Box>)}
			<>
				<Label editTitle={editTitle} updatedSelectedData={choiceData} />
				{renderEditableContent()}
				<div className={commonClasses.actionableButtons}>
						{(areActionableButtonsVisible) && (
						<ActionableButtons
							isSaving={isSaving}
							handleSaveClick={handleSaveClick}
							handleCancelClick={handleCancelClick}
							dataType={props.dataType}
							kmatId={props.kmatId}
							currentVersion={props.currentVersion}
							isTabClicked={props.isTabClicked}
							setTabClicked={props.setTabClicked}
						/>
					)}
				</div>
			</>
		</div>
	</>
	);
};

export default EditModel;
