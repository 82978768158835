import React, { memo, useCallback, useMemo } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { OcplcKmatLockingQuery } from 'actions/ocplcAction';
import { LOCK_STATUS_DASHBOARD, VERSION_0003 } from 'constants/ocplcConstants';
import { confirmationDialogStyles } from './ocplcHelpers';
import { isPLCAdmin } from 'pages/Utils/OcplcUtil';
import { snackBar } from 'actions/modelActions';

const OcplcMenuList = memo(({ row, loginUserEmailId, userRole }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const { kmatId, status, productGroup, productLineID } = row;
    const ocplcAdmin = isPLCAdmin(userRole);

    const isLockStatusError = useSelector(state => state.ocplc.isLockStatusError);
    const lockStatus = useSelector(state => state.ocplc.lockStatus);

    const handleClose = useCallback(() => {
        dispatch({ type: LOCK_STATUS_DASHBOARD, payload: false });
    }, []);

    const handleClick = useCallback((type) => {
        if (!kmatId || !productLineID || !productGroup) {
            dispatch(snackBar(true, `Product Line is empty for KMAT : ${kmatId}`, 'error'));
        } else {
            if (type === 'edit') {
                dispatch(OcplcKmatLockingQuery(kmatId, ocplcAdmin, loginUserEmailId, status, navigate, productGroup, type, productLineID));
            } else if (type === 'viewRecord') {
                navigate(`/ocplc/${type}/${kmatId}/${productGroup}/${productLineID}`);
            }
        }
    }, [kmatId]);

    return (
        <>
            <Box>
                <Button onClick={() => handleClick('viewRecord')} aria-label="view record" startIcon={<VisibilityIcon />} />
                <Button onClick={() => handleClick('edit')} aria-label="edit" startIcon={<EditIcon />} />
            </Box>

            {isLockStatusError && (
                <ConfirmationDialog
                    open={isLockStatusError}
                    onClose={handleClose}
                    content={lockStatus}
                    BackdropProps={{ style: confirmationDialogStyles.backdrop }}
                    PaperProps={{ style: confirmationDialogStyles.paper }}
                />
            )}
        </>
    );
});

export default OcplcMenuList;