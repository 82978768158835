import React, { useCallback, useEffect, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers';
import Grid from '@mui/material/Grid2';
import Dialog from '@mui/material/Dialog';
import Drawer from '@mui/material/Drawer';
import { Checkbox, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, MenuItem, Select } from '@mui/material';
import dayjs from 'dayjs';
import { regionalFields } from 'pages/PlcDashboard/columnTypes';
import { updateModelAvailability } from 'actions/ocplcAction';
import { useDispatch } from 'react-redux';
import { updateCountryDescriptionById } from 'pages/Utils/OcplcUtil';
import { OCPLC_KE_OTHER_REGIONAL, OCPLC_REGIONAL, OCPLC_READ_ONLY, ROLE_NAME_FOR_KE } from 'constants/ocplcConstants';
import { useStyles } from './ocplcHelpers';

const EditOcplc = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { open, handleClose, plcDetails, countryList, businessModels, userEmail, selectedTableRegion, disabledAction,  assignedRole, kmatId, productLineID, copy } = props;
    const { version, region, country, countryDesc, productFamily, productDesc, businessModel, lastModifiedDate, createDate, productGroup, status, comments, startDate, endDate, whoChanged, apRegion, laRegion, euRegion, naRegion, wwStartDate, wwEndDate } = plcDetails || {};
    const [formData, setFormData] = useState({});
    const regionForCopyAndEdit = copy ? selectedTableRegion : region;
    const isDatesForCopy = (copy && selectedTableRegion !== "WW") || (!copy && region !== "WW");
    const isDatesForCopyAndEdit = (!copy && region === "WW") || (copy && selectedTableRegion === "WW");
    const isTableRegionWW = selectedTableRegion !== "WW" && region === "WW";
    const startDateValue = selectedTableRegion === "WW" ? startDate : wwStartDate;
    const endDateValue = selectedTableRegion === "WW" ? endDate : wwEndDate;
    const isRegionalAndReadonly = [OCPLC_REGIONAL, OCPLC_READ_ONLY].includes(assignedRole);
    const isOcplcKE = assignedRole === ROLE_NAME_FOR_KE && selectedTableRegion !== "WW";
    const isRegional = assignedRole.startsWith(OCPLC_KE_OTHER_REGIONAL) && region === "WW";
    const isFieldDisabled = !copy && (disabledAction || isTableRegionWW || isRegionalAndReadonly || isOcplcKE || isRegional);
    const isCheckboxDisabled = !copy && (disabledAction || isTableRegionWW || isRegionalAndReadonly || isRegional);
    
    const pseriesDateFields = regionalFields.flatMap(({ startField, startName, endField, endName }) => [
        plcDetails[startName] && {
            label: plcDetails[startName] || startName,
            id: startField,
            type: 'date',
            minDate: dayjs(wwStartDate),
            maxDate: dayjs(wwEndDate),
            disabled: disabledAction || isRegionalAndReadonly || isOcplcKE,
            value: plcDetails[startField] ? dayjs(plcDetails[startField]) : null,
            hide: !copy && region === "WW"
        },
        plcDetails[endName] && {
            label: plcDetails[endName] || endName,
            id: endField,
            disabled: disabledAction || isRegionalAndReadonly || isOcplcKE,
            type: 'date',
            minDate: dayjs(wwStartDate),
            maxDate: dayjs(wwEndDate),
            value: plcDetails[endField] ? dayjs(plcDetails[endField]) : null,
            hide: !copy && region === "WW"
        },
    ]).filter(Boolean);
    
    const renderFields = [
        { label: 'Version', id: "version", value: version, disabled: true, size: 4, hide: false },
        { label: 'Region', id: "region", value: regionForCopyAndEdit, disabled: true, size: 4, hide: false },
        { label: 'Country ID', id: "countryID", value: country, size: 4, type: 'select', disabled: isFieldDisabled, hide: false },
        { label: 'Country Description', id: "countryDesc", value: countryDesc, disabled: true, size: 4, hide: false },
        { label: 'Product Family', id: "productFamily", value: productFamily, disabled: true, size: 4, hide: false },
        { label: 'Product Description', id: "productDesc", value: productDesc, disabled: true, size: 4, hide: false },
        { label: 'Business Model', id: "bussinessModel", value: businessModel, size: 4, type: 'select', disabled: isFieldDisabled, hide: false },
        { label: 'Product Group', id: "productGroup", value: productGroup, disabled: true, size: 4, hide: false },
        { label: 'Status', id: "status", value: status, disabled: true, size: 4, hide: false },
        { label: 'Comment', id: "comment", value: comments, multiline: true, disabled: isFieldDisabled, size: 12, hide: false },
        { label: 'Who Changed', id: "whoChanged", value: whoChanged, disabled: true, size: 12, hide: false },
        { label: 'Start Date', id: "startDate", type: 'date', value: dayjs(startDateValue), disabled: isFieldDisabled, hide: isDatesForCopy },
        { label: 'End Date', id: "endDate", type: 'date', value: dayjs(endDateValue), disabled: isFieldDisabled, hide: isDatesForCopy },
        { label: 'WW Start Date', id: "wwStartDate", disabled: disabledAction || isDatesForCopy, type: 'date', value: dayjs(wwStartDate), hide: isDatesForCopyAndEdit },
        { label: 'Start Date', id: "regStartDate", type: 'date', value: dayjs(startDate), minDate: dayjs(wwStartDate), maxDate: dayjs(wwEndDate), disabled: disabledAction || isDatesForCopyAndEdit || isRegionalAndReadonly || isOcplcKE, hide: isDatesForCopyAndEdit  },
        { label: 'End Date', id: "regEndDate", type: 'date', value: dayjs(endDate), minDate: dayjs(wwStartDate), maxDate: dayjs(wwEndDate), disabled: disabledAction || isDatesForCopyAndEdit || isRegionalAndReadonly || isOcplcKE, hide: isDatesForCopyAndEdit  },
        { label: 'WW End Date', id: "wwEndDate", disabled: disabledAction ||isDatesForCopy, type: 'date', value: dayjs(wwEndDate), hide: isDatesForCopyAndEdit },
        ...pseriesDateFields,
        { label: 'Last Modified Date', type: 'text', id: "lastModified", value: lastModifiedDate, disabled: true, hide: false },
        { label: 'Create Date', type: 'text', id: "createDate", value: createDate, disabled: true, hide: false },
        { label: 'AP', id: "ap", type: 'checkbox', value: apRegion, disabled: isCheckboxDisabled, hide: isDatesForCopy },
        { label: 'EU', id: "eu", type: 'checkbox', value: euRegion, disabled: isCheckboxDisabled, hide: isDatesForCopy },
        { label: 'NA', id: "na", type: 'checkbox', value: naRegion, disabled: isCheckboxDisabled, hide: isDatesForCopy },
        { label: 'LA', id: "la", type: 'checkbox', value: laRegion, disabled: isCheckboxDisabled, hide: isDatesForCopy },
    ];

    useEffect(() => {
        const fieldValues = renderFields.reduce((acc, item) => { acc[item.id] = item.value; return acc }, {})
        setFormData(fieldValues);
    }, []);

    const handleChange = (event, field, type) => {
        let value;
        if (type === 'checkbox') {
            value = event.target.checked;
        } else if (type === 'date') {
            value = event ? dayjs(event) : "";
        } else {
            value = event.target.value;
        }
        setFormData(prevData => {
            let updatedData = { ...prevData, [field]: value };
            updatedData = updateCountryDescriptionById(updatedData, field, value, countryList);
            return updatedData;
        });
    };

    const renderCountryAndBusinessSelect = useCallback((id, field, type, disabled) => {
        const isCountry = field === 'Country ID';
        const dropdownList = isCountry ? countryList : businessModels;

        return (
            <FormControl fullWidth>
                <Select
                    value={formData[id] || ''}
                    onChange={(e) => handleChange(e, id, type)}
                    disabled={disabled}
                >
                    {dropdownList.length ? (
                        dropdownList.map(({ countryCode, value, text }, index) => (
                            <MenuItem key={index} value={countryCode || value}>
                                {countryCode || `${value}  | ${text}`}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled>No items available</MenuItem>
                    )}
                </Select>
            </FormControl>
        );
    },
        [formData, handleChange, disabledAction, countryList, businessModels]
    );

    const renderDateCheckboxTextField = useCallback((field, index) => {
        const { label, type, disabled, multiline, id, size = 3, minDate, maxDate } = field;
        const value = formData[id];
        return (
            <Grid size={size} key={index}>
                <Typography sx={{ fontSize: "14px", color: "black" }}>{label}</Typography>
                {type === "date" ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            disabled={disabled}
                            value={value ? dayjs(value) : null}
                            onChange={(date) => handleChange(date, id, type)}
                            minDate={minDate}
                            maxDate={maxDate}
                            format='DD/MM/YYYY'
                        />
                    </LocalizationProvider>
                ) : type === "checkbox" ? (
                    <FormControlLabel control={<Checkbox disabled={disabled} checked={!!value} onChange={(e) => handleChange(e, id, type)} />} />
                ) : type === "select" ? (
                    renderCountryAndBusinessSelect(id, label, type, disabled)
                ) : (
                    <TextField
                        fullWidth
                        disabled={disabled}
                        multiline={multiline}
                        minRows={3}
                        value={value || ""}
                        onChange={(e) => handleChange(e, id, type)}
                        className={classes.textField}
                    />
                )}
            </Grid>
        );
    },
        [formData, handleChange]
    );

    const onSaveChange = (e) => {
        e.preventDefault();
        const isOtherRegion = selectedTableRegion !== "WW";
        dispatch(updateModelAvailability(formData, plcDetails, userEmail, isOtherRegion, regionForCopyAndEdit, copy, kmatId, productLineID, selectedTableRegion, handleClose))
    };

    const DialogContentComponent = (
        <>
            <DialogContent>
                <Typography sx={{ marginBottom: "12px", wordBreak: "break-all" }} variant="h5">{copy ? 'COPY' : `${productDesc} ${productDesc && "-"}  ${productFamily}`}</Typography>
                <Grid container spacing={2}>
                    {renderFields.map((field, index) => !field.hide && renderDateCheckboxTextField(field, index))}
                </Grid>
            </DialogContent>
            <DialogActions sx={{ marginTop: "20px" }} className='dialog-actions'>
                <Button variant="outlined" onClick={handleClose} className="cancel-button">Cancel</Button>
                <Button variant="contained" color="primary" disabled={isFieldDisabled} className="add-Button" onClick={onSaveChange}>{copy ? 'Insert' : 'Update'}</Button>
            </DialogActions>
        </>
    );

    return copy ? (
        <Dialog open={open} onClose={handleClose} sx={{ borderRadius: "10px" }} maxWidth="md" fullWidth>
            {DialogContentComponent}
        </Dialog>
    ) : (
        <Drawer anchor="right" open={open} onClose={handleClose}>
            <Box sx={{ width: 850 }}>
                {DialogContentComponent}
            </Box>
        </Drawer>
    );
}

export default EditOcplc;
