export const colorTypes = {
    'M': '#FFAA33',
    'E': 'red',
    'V': '#1c7a17',
    'P': '#FFAA33',
}

export const regionalFields = Array.from({ length: 9 }, (_, i) => ({
    startField: `p${i + 1}S`,
    startName: `p${i + 1}SName`,
    endField: `p${i + 1}E`,
    endName: `p${i + 1}EName`,
}));

export const dateConfig = {
    WW: { startDateLabel: "Start Date", endDateLabel: "End Date", startDateValue: "startDate", endDateValue: "endDate" },
    default: { startDateLabel: "WW Start Date", endDateLabel: "WW End Date", startDateValue: "wwStartDate", endDateValue: "wwEndDate" }
};

export const columnTypes = {
    status: {
        type: 'text',
        name: 'status',
        matchFields: ['status']
    },
    productFamily: {
        type: 'text',
        name: 'productFamily',
        matchFields: ['productFamily']
    },
    productDesc: {
        type: 'text',
        name: 'productDesc',
        matchFields: ['productDesc']
    },
    region: {
        type: 'select',
        name: 'region',
        matchFields: ['region']
    },
    country: {
        type: 'select',
        name: 'country',
        matchFields: ['country']
    },
    countryDesc: {
        type: 'text',
        name: 'countryDesc',
        matchFields: ['countryDesc']
    },
    businessModel: {
        type: 'select',
        name: 'businessModel',
        matchFields: ['businessModel']
    },
    startDate: {
        type: 'date',
        name: 'startDate',
        matchFields: ['wwStartDate','startDate']
    },
    endDate: {
        type: 'date',
        name: 'endDate',
        matchFields: ['wwEndDate','endDate']
    },
    regStartDate: {
        type: 'date',
        name: 'regStartDate',
        matchFields: ['startDate']
    },
    regEndDate: {
        type: 'date',
        name: 'regEndDate',
        matchFields: ['endDate']
    },
    apRegion: {
        type: 'checkbox',
        name: 'apRegion',
        matchFields: ['apRegion']
    },
    euRegion: {
        type: 'checkbox',
        name: 'euRegion',
        matchFields: ['euRegion']
    },
    naRegion: {
        type: 'checkbox',
        name: 'naRegion',
        matchFields: ['naRegion']
    },
    laRegion: {
        type: 'checkbox',
        name: 'laRegion',
        matchFields: ['laRegion']
    },
    p1S: {
        type: 'date',
        name: 'p1S',
        matchFields: ['p1S']
    },
    p1E: {
        type: 'date',
        name: 'p1E',
        matchFields: ['p1E']
    },
    p2S: {
        type: 'date',
        name: 'p2S',
        matchFields: ['p2S']
    },
    p2E: {
        type: 'date',
        name: 'p2E',
        matchFields: ['p2E']
    },
    p3S: {
        type: 'date',
        name: 'p3S',
        matchFields: ['p3S']
    },
    p3E: {
        type: 'date',
        name: 'p3E',
        matchFields: ['p3E']
    },
    p4S: {
        type: 'date',
        name: 'p4S',
        matchFields: ['p4S']
    },
    p4E: {
        type: 'date',
        name: 'p4E',
        matchFields: ['p4E']
    },
    p5S: {
        type: 'date',
        name: 'p5S',
        matchFields: ['p5S']
    },
    p5E: {
        type: 'date',
        name: 'p5E',
        matchFields: ['p5E']
    },
    p6S: {
        type: 'date',
        name: 'p6S',
        matchFields: ['p6S']
    },
    p6E: {
        type: 'date',
        name: 'p6E',
        matchFields: ['p6E']
    },
    p7S: {
        type: 'date',
        name: 'p7S',
        matchFields: ['p7S']
    },
    p7E: {
        type: 'date',
        name: 'p7E',
        matchFields: ['p7E']
    },
    p8S: {
        type: 'date',
        name: 'p8S',
        matchFields: ['p8S']
    },
    p8E: {
        type: 'date',
        name: 'p8E',
        matchFields: ['p8E']
    },
    p9S: {
        type: 'date',
        name: 'p9S',
        matchFields: ['p9S']
    },
    p9E: {
        type: 'date',
        name: 'p9E',
        matchFields: ['p9E']
    },
}