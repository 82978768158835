import React, { useState, useCallback, memo } from 'react';
import { useDispatch } from 'react-redux';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DataTransportDropdown from 'pages/ocplcDashboard/dataTransport';
import Box from '@mui/material/Box';
import UploadDialog from './UploadDialogOcplc';
import DownloadDialog from './DownloadDialogOcplc';
import Button from '@mui/material/Button';
import DescriptionIcon from '@mui/icons-material/Description';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Popover from '@mui/material/Popover';
import ListItemButton from '@mui/material/ListItemButton';
import { useStyles } from './ocplcHelpers';
import { filterByStatus } from 'actions/ocplcAction';
import { iconButtonStyle } from 'pages/DashboardHelpers';
import LockIcon from '@mui/icons-material/Lock';
import { PLC_ADMIN, PLC_WW, ROLE_NAME_FOR_ADMIN, VERSION_0002, VERSION_0003 } from 'constants/ocplcConstants';
import { isPLCWW } from 'pages/Utils/OcplcUtil';

const UploadDownloadDataTransIcons = memo(({ tableData, kmatId, productGroup, productLineID, ocplcSelectedKmats, title, version, showLockandDownload = true, showUpload = true, disabled, userRole,region, selectedRows = [],dashboardrole, assignedRole, checkedRows=[], userEmail, setCheckedRows }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [dialogState, setDialogState] = useState({ upload: false, download: false });
    const [anchorEl, setAnchorEl] = useState(null);

    const openPopover = Boolean(anchorEl);
    const popoverId = openPopover ? "simple-popover" : undefined;

    const handleDialogClose = useCallback(() => {
        setDialogState({ upload: false, download: false });
        setAnchorEl(null);
    }, []);

    const handleUploadPlc = () => setDialogState(prevState => ({ ...prevState, upload: true }));
    const handleDownloadPlc = () => setDialogState(prevState => ({ ...prevState, download: true }));

    const handlePopoverClick = (event) => setAnchorEl(event.currentTarget);

    const handleShowError = useCallback(() => {
        dispatch(filterByStatus("E"));
        handleDialogClose();
    }, [handleDialogClose]);

    const handleRecentUpdates = useCallback(() => {
        dispatch(filterByStatus(["P", "M"]));
        handleDialogClose();
    }, [handleDialogClose]);

    const isPlcWW = isPLCWW(userRole);
    const isDashboardAndWW = title === "OCPLC Dashboard" && dashboardrole === PLC_WW;
    const isAssignedRoleAdmin = assignedRole === ROLE_NAME_FOR_ADMIN;
    const inViewRecord = version === VERSION_0003
    const isEditPage = version === VERSION_0002;
    const hasSelectedRows = checkedRows.length === 0;    
    const isDisabled = disabled || (isEditPage && !isAssignedRoleAdmin) || inViewRecord  || hasSelectedRows;
    
    const isNewStatus = selectedRows.some(detail => detail.status === "New");
    const isDashboradDTDisabled = disabled || dashboardrole !== PLC_ADMIN || isNewStatus;
    
    return (
        <Box sx={{ display: "flex" }}>
            <Button sx={{ ...iconButtonStyle }} endIcon={<FileDownloadOutlinedIcon />} onClick={handleDownloadPlc} disabled={disabled}> Download </Button>
            {showLockandDownload && <Button sx={{ ...iconButtonStyle }} startIcon={<LockIcon />} endIcon={<FileDownloadOutlinedIcon />} disabled={disabled || isPlcWW}>Lock and Download </Button>}
            {showUpload && <Button sx={{ ...iconButtonStyle }} endIcon={<FileUploadOutlinedIcon />} onClick={handleUploadPlc} disabled={disabled || isPlcWW || isDashboardAndWW}>Upload </Button>}
            <DataTransportDropdown dashboardDTDisabled={isDashboradDTDisabled} userEmail={userEmail} setCheckedRows={setCheckedRows} checkedRows={checkedRows} tableData={tableData} version={version} disabled={isDisabled} userRole={userRole} region={region} selectedRows={selectedRows} assignedRole={assignedRole} kmatId={kmatId} productGroup={productGroup} productLineID={productLineID}/>

            {title === "ocplcEdit" && (
                <Button variant="outlined"aria-describedby={popoverId}onClick={handlePopoverClick}size="medium" className={classes.moreIcon} >
                    {openPopover ? <DescriptionIcon htmlColor="#0278AB" /> : <MoreHorizIcon htmlColor="#0278AB" />}
                </Button>
            )}

            {dialogState.upload && (
                <UploadDialog
                    open={dialogState.upload}
                    setOpen={setDialogState}
                    handleClose={handleDialogClose}
                    ocplcSelectedKmats={ocplcSelectedKmats}
                />
            )}

            {dialogState.download && (
                <DownloadDialog
                    open={dialogState.download}
                    setOpen={setDialogState}
                    handleClose={handleDialogClose}
                    ocplcSelectedKmats={ocplcSelectedKmats}
                />
            )}

            {openPopover && (
                <Popover
                    id={popoverId}
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handleDialogClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    className={classes.popOver}
                >
                    <ListItemButton onClick={handleShowError}>Show Error</ListItemButton>
                    <ListItemButton onClick={handleRecentUpdates}>Recent Updates</ListItemButton>
                </Popover>
            )}
        </Box>
    );
});

export default UploadDownloadDataTransIcons;
