import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import "./styles.css";

export default function StatusInfo({ loading, loadingMessage, error }) {

  if (loading) {
    return (<div className="info"><div id="output">{loadingMessage}</div> <CircularProgress /></div>)
  }
  return <div className="info error">{error} !</div>
}
