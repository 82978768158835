import React from "react";
import { styled } from '@mui/material/styles';
import Radio from "@mui/material/Radio";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    border: "1px solid #2B2B2B",
    background: "#2B2B2B",
    borderRadius: "4px",
    padding: "4px 8px",
    width: "400px",
    height: "65px",
  },
});
const RadioIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 24,
  height: 24,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#FFFFFF',
}));

const RadioCheckedIcon = styled(RadioIcon)({
  backgroundColor: '#137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 24,
    height: 24,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 45%)',
    content: '""',
  },
});

export function RadioButton(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<RadioCheckedIcon />}
      icon={<RadioIcon />}
      {...props}
      tabIndex="-1"
    />
  );
}

