import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog from "components/ConfirmationDialog";
import fileDownload from "js-file-download";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { SYNTAX_CHECK_DONE } from "constants/modelConstants";
import { snackBar } from "actions/modelActions";
import { useStyles } from "../../pages/EditModel/ModelHeader/ModelHeaderHelpers";
import { exportFiles, syntaxCheck, publishRegional } from "actions/modelActions";

function WorkSpaceButtonRegional(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.models.activeTab);
  const isErrorOnValidate = useSelector((state) => state.models.isErrorOnValidate);
  const lengths = useSelector((state) => state.models.syntaxCheck.lengths);
  const canSyntaxCheck = useSelector((state) => state.models.canSyntaxCheck);
  const [syntaxChecking, setSyntaxChecking] = useState(false);
  const [isPublishing, setPublishing] = useState(false);
  const [syntaxCheckHasNoErrors, setSyntaxCheckHasNoErrors] = useState(false);
  const [openConfirmFilesPopup, setOpenConfirmFilesPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { kmatId } = props;
  let isValidate = useRef(false);

  useEffect(() => {
    if (lengths) {
      if (Object.values(lengths).every(length => length === 0)) {
        isValidate.current && dispatch(snackBar(true, "KB Regional Validated Successfully!", "success"));
        setSyntaxCheckHasNoErrors(true);
      } else {
        isValidate.current && dispatch(snackBar(true, "KB Regional Validated Failed!", "error"));
        setSyntaxCheckHasNoErrors(false);
      }
    }
    isValidate.current = false;
            // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lengths]);

  const handleSyntaxCheck = async () => {
    isValidate.current = true;
    try {
      setSyntaxChecking(true);
      dispatch({ type: SYNTAX_CHECK_DONE, payload: true });
      dispatch(syntaxCheck(kmatId));
    } catch (err) {
      dispatch(snackBar(true, err, "error"));
    } finally {
      setSyntaxChecking(false);
    }
  };

  const publish = () => {
    setPublishing(true);
    Promise.all([dispatch(publishRegional(kmatId, activeTab))])
      .then(() => {
        return navigate("/regional");
      })
      .catch((err) => {
        dispatch(snackBar(true, err, "error"));
      })
      .finally(() => {
        setPublishing(false);
      });
  };

  const generate = () => {
    dispatch(exportFiles(kmatId, null, true))
      .then((res) => {
        res.forEach((file) => { fileDownload(file.content, `${file.name}`) })
        dispatch(snackBar(true, "PCR & PCS Files generated successfully", "success"));
      })
      .catch((err) => {
        dispatch(snackBar(true, err, "error"));
      });
  };

  const onConfirmationGenerateFiles = () => {
    setOpenConfirmFilesPopup(false);
    handlePopClose();
    generate();
  };

  const closeConfirmFilesPopup = () => {
    setOpenConfirmFilesPopup(false)
    handlePopClose();
  }

  const handlePopClose = useCallback(() => { setAnchorEl(null) }, []);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div className="pcr-toolbar-right">
        <nav className="pcr-toolbar-left-nav">
          <Button
            id="pct_toolbar_validate_model"
            value="Validate"
            type="button"
            style={{ height: 43, marginRight: "10px", boxShadow: "none", padding: "8px 20px" }}
            className={!canSyntaxCheck || syntaxCheckHasNoErrors ? classes.checkIcon : "button slim secondary"}
            onClick={handleSyntaxCheck}
            disabled={!canSyntaxCheck}
            variant="contained"
          >
            {syntaxChecking ? "Syntax Checking..." : "Syntax Check"}
            {(syntaxCheckHasNoErrors || !canSyntaxCheck) && (<CheckCircleOutlineIcon className={classes.check} />)}
          </Button>
          <Button
            id="pct_toolbar_publish_model"
            value="Publish"
            type="button"
            variant="contained"
            className={classes.publish}
            onClick={publish}
            disabled={isErrorOnValidate[activeTab]}
          >
            {isPublishing ? "Publishing..." : "Publish Region"}
          </Button>
          <Button
            variant="outlined"
            aria-describedby={id}
            onClick={() => setOpenConfirmFilesPopup(true)}
            className={classes.moreIcon}
            size="medium"
          >
            <FileDownloadOutlinedIcon htmlColor="#0278AB" />
          </Button>
        </nav>
      </div>

      {openConfirmFilesPopup &&
        <ConfirmationDialog
          open={openConfirmFilesPopup}
          title="Confirmation"
          content="Do you want to Generate PCR & PCS Files?"
          onClose={closeConfirmFilesPopup}
          onConfirm={onConfirmationGenerateFiles}
          caption="Generate"
          bgColor="#0278AB"
        />}

    </>
  );
}

export default WorkSpaceButtonRegional;
