export const OcplcKmatLockingQuery = `query OcplcKmatLockingQuery($kmatId: String!, $isAdmin: Boolean!, $loginUserEmailId: String!, $status: String!, $isBack: Boolean!)
 {
    ocplcKmatLocking(filter: {
            kmatId: $kmatId,
            isAdmin: $isAdmin
            loginUserEmailId: $loginUserEmailId,
            status: $status,
            isBack: $isBack
        }) {
        response
        isError
    }
}
`;
