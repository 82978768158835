import React, { useState, useLayoutEffect } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const RenderTextfieldHeader = ({ colData, label, filters, handleChange }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    handleChange(colData, e.target.value);
  };

  useLayoutEffect(() => {
    setInputValue(filters[colData?.name]?.value || '');
  }, [filters, colData]);

  return (
    <div className='header-children'>
      <Typography variant='subtitle2' sx={{ marginBottom: '5px' }}> {label} </Typography>
      <TextField
        fullWidth
        id="text-field-controlled"
        placeholder='Search'
        value={inputValue}
        onChange={handleInputChange}
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position='end' style={{ cursor: 'pointer' }}>
                {inputValue ? <CloseIcon onClick={() => handleChange(colData, '')} /> : <SearchIcon className='search-icon' />}
              </InputAdornment>)
          }
        }}
        sx={{ '& fieldset': { borderRadius: '10px' }, maxWidth: '800px' }}
      />
    </div>
  );
};
export default RenderTextfieldHeader;
