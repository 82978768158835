/**
 * Decode a Base64 encoded string and returns the byte values of characters in the decoded string.
 * @param {string} string
 * @return {Uint8Array} return an array of 8-bit unsigned integers.
 */
export function u8arrFromBase64EncodedString(string) {
  const decodedString = atob(string);

  let decodedStringLength = decodedString.length;
  const u8arr = new Uint8Array(decodedStringLength);

  while (decodedStringLength--)
    u8arr[decodedStringLength] = decodedString.charCodeAt(decodedStringLength);

  return u8arr;
}
