import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add';
import CheckBoxBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from '@mui/icons-material/HelpOutlineOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import clsx from 'clsx';
import { SimpleTreeView } from '@mui/x-tree-view';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import TabPanel from 'components/TabPanel';
import { style, useStyles, ItemTooltip } from "./CVGEditHelpers"
import useGlobalStore from 'hooks/useGlobalStore';
import { updateChoiceItemFields, updateSaveInit } from 'actions/modelActions';
import { CustomSearch, searchFieldProps, CountBadge,useCommonStyles } from '../IndexHelpers';
import ActionableButtons from '../ActionableButtons';
import { undoProperty } from 'actions/undoActions';

const newCVGNameToArray = (newCVGName) => newCVGName.split(",").map((cvg) => cvg.trim()).filter(Boolean);

const CVGEdit = (props)  =>{
  const classes = useStyles();
  const dispatch = useDispatch();
  const commonClasses = useCommonStyles(); 
  const choiceID = props?.choiceID;

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [newCVGName, setNewCVGName] = useState("");
  const [duplicateNameError, setDuplicateNameError] = useState(null);
  const [selectedCVG, setSelectedCVG] = useState(null);
  const [selectedItemID, setSelectedItemID] = useState(null);
  const [opeDialog, setOpeDialogn] = useState(false);
  const [searchCVG, setSearchCVG] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [showOnlyChecked, setShowOnlyChecked] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [itemData, setItemData] = useGlobalStore(props?.selectedChoiceData, updateChoiceItemFields, { choiceID, tabCategory: props?.selectedCvgTab });

  useEffect(() => {
    if (!searchCVG) {
      if (!selectedCVG || !itemData.allowedCVGs.includes(selectedCVG)) {
        setSelectedCVG(itemData?.allowedCVGs.split(",")[0] ?? null);
      }
    } else {
      setSelectedCVG(itemData?.allowedCVGs.split(",").find(cvg => cvg.toLowerCase().includes(searchCVG.toLowerCase())) || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCVG, itemData?.allowedCVGs]);

  useEffect(() => {
    if (selectedTabIndex === 1)
      setSelectedItemID(Object.keys(props?.selectedChoiceData.items)[0] ?? null);
      setShowOnlyChecked(false);
  }, [props?.selectedChoiceData?.items, selectedTabIndex]);

  useEffect(() => {
    setSearchCVG(props?.cvgsFilter.length > 0 ? props?.cvgsFilter : "");
  }, [props?.cvgsFilter]);  

  const handleClose = () => {
    setOpeDialogn(false)
    setNewCVGName('')
    setDuplicateNameError(null)
  }

  const handleItemCheck = (itemID) => setItemData((prevState) => {
    const items = { ...prevState.items };
    const cvgs = items[itemID].cvgs;
    const updatedCVGs = cvgs.includes(selectedCVG) ? cvgs.filter((cvg) => cvg !== selectedCVG) : cvgs.concat(selectedCVG);
    items[itemID] = { ...items[itemID], edited: true, cvgs: updatedCVGs, cvg: updatedCVGs.join(" & ") };
    return ({ ...prevState, items: items })
  });


  const handleCvgCheck = (cvgName) => setItemData((prevState) => {
    const items = { ...prevState.items };
    const cvgs = items[selectedItemID].cvgs;
    const updatedCVGs = cvgs.includes(cvgName) ? cvgs.filter((cvg) => cvg !== cvgName) : cvgs.concat(cvgName);
    items[selectedItemID] = { ...items[selectedItemID], edited: true, cvgs: updatedCVGs, cvg: cvgs.join(' & ') };
    return ({ ...prevState, items: items })
  });

  const handleAddButtonClick = () => {
    const newCVGs = newCVGNameToArray(newCVGName);
    const duplicatedCVGs = newCVGs.filter(newCVG => itemData.allowedCVGs.split(',').some(cvg => cvg === newCVG));
    const duplicatedCVGsLength = duplicatedCVGs.length;

    if (duplicatedCVGsLength) {
      return setDuplicateNameError(`Duplicate cvg${duplicatedCVGsLength > 1 ? 's' : ''} ${duplicatedCVGs.join(', ')} in ${itemData.choiceID}!`);
    }

    setItemData((prevState) => ({ ...prevState, allowedCVGs: `${prevState.allowedCVGs},${newCVGs.join(',')}` }));
    setSelectedCVG(newCVGs[0]);
    setShowOnlyChecked(false);
    setSearchCVG("");
    handleClose();
  };

  const handleSearch = (term) => setSearchCVG(term);

  const handleItemSearch = (term) => setSearchItem(term);

  const handleClearSearch = () => {
    setSearchCVG('');
    setSelectedCVG();
  };

  const handleClearItemSearch = () => setSearchItem('');

  const handleSaveClick = async () => {
		setIsSaving(true);
		dispatch(updateSaveInit(true));
		setIsSaving(false);
	};

  const handleCancelClick = () => {
		dispatch(undoProperty({ itemID : '', choiceID }));
	};

  if (!choiceID || !Object.keys(itemData?.items)?.length) {
    return <div className={commonClasses.noItem} >No data found!</div>;
  }

  const allowedCVGs = itemData.allowedCVGs.split(',').filter(Boolean).sort();
  const canCheck = selectedCVG?.length

  return (
    <>
      <Grid container className={classes.root}>
        <TabPanel setEditTab={setSelectedTabIndex} onTabChange={(tabIndex) =>  setSelectedTabIndex(tabIndex)} />
      </Grid>

      <Grid container className={classes.root}>
        {selectedTabIndex === 0 &&
          <Grid size={{ xs: 12, sm: 6 }} className={classes.paper}>
            <div className={commonClasses.popperContainer}>
              <div className={commonClasses.popperHeader}> CVG List &nbsp;
                <CustomSearch {...searchFieldProps} placeholder="Search CVG.." value={searchCVG} handleClearSearch={handleClearSearch} onChange={(e) => handleSearch(e.target.value)} />
                <IconButton onClick={() => setOpeDialogn(true)}><AddIcon /></IconButton>
                {opeDialog && <Modal open={opeDialog}>
                  <Box sx={style}>
                    <Typography variant="h4" component="h2">Add a new CVG list</Typography>
                    <TextField
                      variant="outlined"
                      placeholder="CVG"
                      value={newCVGName}
                      autoFocus
                      className={classes.cellContent}
                      onChange={(e) => setNewCVGName(e.target.value.toLocaleUpperCase())}
                    />
                    <Typography className={classes.duplicateError}>{duplicateNameError}</Typography>
                    <Button disabled={!newCVGNameToArray(newCVGName).length} variant="contained" onClick={handleAddButtonClick} style={{ width: '80px' }} className={classes.saveButton}>Save</Button>
                    <Button variant="outlined" onClick={handleClose} style={{ borderRadius: "10px", width: "80px" }}>Cancel</Button>
                  </Box>
                </Modal>}
              </div>
              <div id="split-button-menu" className={commonClasses.popperList}>
                {(allowedCVGs.filter(cvg => cvg.toLowerCase().includes(searchCVG.toLowerCase()))).map((allowedCVG) => {
                  const selected =  Boolean(allowedCVG === selectedCVG);
                  return <div key={allowedCVG} className={clsx(commonClasses.cvg, { 'selected': selected })}>
                    <div className={classes.cvgSelection} onClick={() => setSelectedCVG(allowedCVG)}>
                      {selected ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                      <div className={classes.cvgName}>&nbsp;{allowedCVG}</div>
                    </div>
                    <CountBadge badgeContent={Object.values(itemData.items).filter((item) => item.cvgs.includes(allowedCVG)).length} showZero />&nbsp;
                  </div>
                })}
              </div>
            </div>
          </Grid>}

        {selectedTabIndex === 0 &&
          <Grid size={{ xs: 12, sm: 6 }} className={classes.paper}>
            <div className={commonClasses.popperContainer}>
              <div className={commonClasses.popperHeader}>Items List &nbsp;
                <Checkbox onChange={(e) => setShowOnlyChecked(e.target.checked)} color='primary' checked={showOnlyChecked} disabled={!canCheck}/>
                <CustomSearch {...searchFieldProps} placeholder="Search Items, description.." value={searchItem} handleClearSearch={handleClearItemSearch} onChange={(e) => handleItemSearch(e.target.value)} />
              </div>
              <div id="split-button-menu" className={commonClasses.popperList}>
                {(Object.values(itemData.items)).filter(item => item.name.toLowerCase().includes(searchItem.toLowerCase())).map((item) => {
                  const checked = item.cvgs.includes(selectedCVG);
                  const itemID = item.itemID;
                  if (showOnlyChecked && !checked) return null;
                  return (
                    <div key={itemID} style={{ cursor: canCheck ? 'pointer' : 'not-allowed' }} onClick={() => { if (canCheck) handleItemCheck(itemID) }} className={clsx(commonClasses.cvg, { 'selected': checked })}>
                      <div>{checked ? <CheckBoxIcon /> : <CheckBoxBlankIcon />}</div>
                      <div style={{ flexGrow: 1 }}>
                        &nbsp;<span className="boldText">{item.partNumber}</span>&nbsp;
                        {item.description}
                      </div>
                      {checked &&
                        <ItemTooltip title={itemData.items[itemID]?.cvg} placement="top-end" arrow>
                          <IconButton size="small" style={{ height: 24, width: 24 }}>
                            <InfoIcon />
                          </IconButton>
                        </ItemTooltip>
                      }
                    </div>
                  )}
                )}
              </div>
            </div>
          </Grid>}

        {selectedTabIndex === 1 &&
          <Grid size={{ xs: 12, sm: 6 }} className={classes.paper}>
            <div className={commonClasses.popperContainer}>
              <div className={commonClasses.popperHeader}> Items List</div>
              <div id="split-button-menu" className={commonClasses.popperList}>
                {Object.values(itemData.items).map((item) => {
                  const itemID = item.itemID;
                  const selected = itemID === selectedItemID;
                  return (
                    <div key={itemID} style={{ cursor: canCheck ? 'pointer': 'not-allowed'  }} className={clsx(commonClasses.cvg, { 'selected': selected })} onClick={() => { if (canCheck)  setSelectedItemID(itemID); }}>
                      {selected ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
                      <div style={{ flexGrow: 1 }}>
                        &nbsp;<span className="boldText">{item.partNumber}</span>&nbsp;
                        {item.description}
                      </div>
                      <CountBadge badgeContent={allowedCVGs.filter((cvg) => item.cvgs.includes(cvg)).length} showZero color='default' />
                    </div>
                  )}
                )}
              </div>
            </div>
          </Grid>}

        {selectedTabIndex === 1 &&
          <Grid size={{ xs: 12, sm: 6 }} className={classes.paper}>
            <div className={commonClasses.popperContainer}>
              <div className={commonClasses.popperHeader}>CVG List</div>
              <div id="split-button-menu" className={commonClasses.popperList}>
                {allowedCVGs.map((cvg) => {
                  const checked = itemData.items[selectedItemID]?.cvgs.includes(cvg);
                  return (
                    <div key={cvg} onClick={() => handleCvgCheck(cvg)} className={clsx(commonClasses.cvg, { 'selected': checked, 'disabled': selectedItemID === null })}>
                      <div>{checked ? <CheckBoxIcon /> : <CheckBoxBlankIcon />}</div>
                      <div>&nbsp;{cvg}</div>
                    </div>
                  )}
                )}
              </div>
            </div>
          </Grid>}
      </Grid>

      {selectedTabIndex === 2 &&
        <Grid>
          <div className={commonClasses.popperContainer}>
            <div className={commonClasses.popperHeader}>Tree View Associations</div>
            <div id="split-button-menu" className={commonClasses.popperList}>
              <SimpleTreeView aria-label="cvg items associations"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
              >
                {allowedCVGs.map((cvg, index) =>
                  <TreeItem key={cvg} itemId={`${cvg}-${index}`} label={cvg} classes={{ label: classes.treeLabel }}>
                    {Object.values(itemData.items).filter((item) => item.cvgs.includes(cvg)).map((item, index2) => 
                      <TreeItem key={`${cvg}-${item.itemID}`}  itemId={`${cvg}-${item.itemID}-${index2}`} label={item.name} classes={{ label: classes.treeLabel }}/>
                    )}
                  </TreeItem>
                )}
              </SimpleTreeView>
            </div>
          </div>
        </Grid>
      }
      <div className={commonClasses.actionableButtons}>
      <ActionableButtons isSaving={isSaving} handleSaveClick={handleSaveClick} handleCancelClick={handleCancelClick} currentVersion={props?.currentVersion} kmatId={props?.kmatId} dataType={props?.dataType} />
      </div>
    </>
  );
}

export default CVGEdit;