import React from 'react';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ITEMEDIT_LABELS } from 'constants/modelConstants';
import ErrorToolTip from 'pages/Common/ErrorToolTip';
import StatusChip from '../ChoiceEdit/StatusChip';
import DuplicatePartDescriptionsTooltip from '../DuplicatePartDescriptionsTooltip';
import { updateChoiceItemFields } from 'actions/modelActions';
import useGlobalStore from 'hooks/useGlobalStore';
import { useStyles } from './styles';
import { useAutoFocus } from '../IndexHelpers';
import { useRef } from 'react';
import dayjs from 'dayjs';

const ItemSettings = (props) => {
  const classes = useStyles();
  const tabName = props?.tabName;
  const [itemData, setItemData] = useGlobalStore(props?.data, updateChoiceItemFields, { choiceID : props?.choiceID, itemID : props?.itemID, tabCategory: tabName });
  const statusChip = itemData?.status && JSON.parse(itemData.status)?.status;

  const invalidFilterConditions = props?.syntaxCheck?.FilterCondition;
  const invalidDefaultConditions = props?.syntaxCheck?.DefaultCondition;
  const invalidCVGConditions = props?.syntaxCheck?.CounterCVG;
  const descriptionLengtErrors = props?.syntaxCheck?.descriptionLengtErrors;

  const textFieldRef = useRef(null);
  useAutoFocus(textFieldRef);

  const handleOnChange = (event, field) => {
    let newVal;
    if (event.target?.value?.startsWith('\n')) return;
    if (field === 'obsoleteOn') {
      newVal = event;
    }else {
      newVal = event.target.value;
    }

    if (field === 'partDescription' && newVal.length > 250) {
      newVal = newVal.slice(0, 250);
    }

    setItemData((prevData) => ({
      ...prevData,
      [field]: newVal,
      edited: true
    }));
  };
  const partDescriptionLength = itemData?.isSystemChoice ? 0 : itemData?.partDescription?.length;
  const isColor = !itemData?.isSystemChoice && (descriptionLengtErrors || itemData?.partDescription?.length > 30)

  return (
    <Grid container className={classes.root}>
      <Grid size={3}>
        <TextField
          disabled
          label={ITEMEDIT_LABELS[tabName]["ItemPartNumber"]}
          id="ItemPartNumber"
          variant="filled"
          margin="dense"
          slotProps={{ input: { disableUnderline: true } }}
          value={itemData?.partNumber || ""}
        />
      </Grid>
      <Grid size={3}>
        <TextField
          disabled
          label={ITEMEDIT_LABELS[tabName]["VcItemID"]}
          id="VcItemID"
          variant="filled"
          margin="dense"
          slotProps={{ input: { disableUnderline: true } }}
          value={itemData?.isSystemChoice ? "" : itemData?.valueID}
        />
      </Grid>
      <Grid size={6}>
        <TextField
          id="ChoiceItemDesc"
          variant="filled"
          margin="dense"
          label={ITEMEDIT_LABELS[tabName]["ChoiceItemDesc"]}
          slotProps={{ input :{ disableUnderline: true, classes: { input: classes.disabledFilledInput } }}}
          disabled={itemData?.isSystemChoice}
          value={itemData?.isSystemChoice ? "" : itemData?.partDescription}
          onChange={(event) => handleOnChange(event, "partDescription")}
        />
        <div className={classes.partDesc}>
          <span><DuplicatePartDescriptionsTooltip syntaxCheck={props?.syntaxCheck} /></span>
          <span style={{ size: "smaller", color: isColor ? "red" : "inherit" }}>{partDescriptionLength} chars.</span>
        </div>
      </Grid>
      <div className={classes.subtitle}>Config Rules & Base unit checks <StatusChip data={itemData} /></div>
        <Grid size={12}>
          <TextField
            disabled
            margin="dense"
            id="ItemRuleHint"
            variant="filled"
            multiline
            placeholder="Config Rules & Base unit checks"
            label="Config Rules & Base unit checks"
            slotProps={{
            input: {
              classes: { input: classes.textarea },
              disableUnderline: true
            }
          }}
            value={itemData?.configRules || ""}
          />
        </Grid>
        <Grid size={6}>
          <Typography className={classes.subtitle}> Filter Condition</Typography>
          <TextField
            inputRef={textFieldRef}
            id="ItemFilterCondition"
            variant="filled"
            margin="dense"
            multiline
            minRows={3}
            label={ITEMEDIT_LABELS[tabName]["ItemFilterCondition"]}
            // placeholder="FilterCondition"
            slotProps={{
            input: {
              classes: { input: classes.textarea },
              disableUnderline: true
             }
            }}
            autoFocus= {true}
            value={itemData?.filterCondition || ""}
            onChange={(event) => handleOnChange(event, "filterCondition")}
          />
          {invalidFilterConditions?.length > 0 && (<ErrorToolTip data={invalidFilterConditions} />)}
        </Grid>
        <Grid size={6}>
          <Typography className={classes.subtitle}> Default Condition</Typography>
          <TextField
            id="ItemDefaultCondition"
            variant="filled"
            margin="dense"
            minRows={3}
            label={ITEMEDIT_LABELS[tabName]["ItemDefaultCondition"]}
            // placeholder="DefaultCondition"
            multiline
            slotProps={{
              input: {
              classes: { input: classes.textarea },
              disableUnderline: true
              }
            }}
            disabled={itemData?.isSystemChoice}
            value={itemData?.defaultCondition || ""}
            onChange={(event) => handleOnChange(event, "defaultCondition")}
          />
          {invalidDefaultConditions?.length > 0 && (<ErrorToolTip data={invalidDefaultConditions} />)}
        </Grid>
      <Typography className={classes.subtitle}> CVG</Typography>
        <Grid size={6}>
          <TextField
            disabled
            id="ItemCvg"
            variant="filled"
            margin="dense"
            label={ITEMEDIT_LABELS[tabName]["ItemCvg"]}
            // placeholder="CVG"
            multiline
            slotProps={{
              input: {
              classes: { input: classes.textarea },
              disableUnderline: true,
              }
            }}
            value={itemData?.cvg || ""}
            onChange={(event) => handleOnChange(event, "cvg")}
          />
        </Grid>
        <Grid size={6}>
          <TextField
            id="ItemCounterCvg"
            variant="filled"
            margin="dense"
            disabled={itemData?.isSystemChoice}
            label={ITEMEDIT_LABELS[tabName]["ItemCounterCvg"]}
            // placeholder="CounterCVG"
            multiline
            slotProps={{
              input: {
              classes: { input: classes.textarea },
              disableUnderline: true
              }
            }}
            value={itemData?.counterCvg || ""}
            onChange={(event) => handleOnChange(event, "counterCvg")}
          />
         {invalidCVGConditions?.length > 0 && (<ErrorToolTip data={invalidCVGConditions} />)}
        </Grid>

      {statusChip === "obsolete" && (
        <Grid size={3}>
          <Typography className={classes.subtitle}>Obsolete Item</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Removing Date"
              slotProps={{
                textField: { InputProps: { disableUnderline: true }, variant: 'filled', margin: 'dense' },
              }}
              value={dayjs(itemData.obsoleteOn)}
              onChange={event => handleOnChange(event, 'obsoleteOn')}
            />
          </LocalizationProvider>
        </Grid>
      )}
    </Grid>
  );
};

export default ItemSettings;
