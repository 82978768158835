import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import { MODULE_TYPE, VERSION_0002, VERSION_0003 } from 'constants/ocplcConstants';

import { iconButtonStyle } from 'pages/DashboardHelpers';
import { isDTButtonDisabled } from 'pages/Utils/OcplcUtil';
import { completeModelAvailability, dashboardDataTransport, moduleWiseDataTransport } from 'actions/ocplcAction';
import { useDispatch } from 'react-redux';
import ConfirmationDialog from 'components/ConfirmationDialog';

const DataTransportDropdown = ({  selectedRows, dashboardDTDisabled, tableData, version, kmatId, productGroup, productLineID, disabled, userRole, region, assignedRole, checkedRows, setCheckedRows, userEmail }) => {
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [confirmDialogType, setConfirmDialogType] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);

    const isDisabled = isDTButtonDisabled(userRole, region, assignedRole, checkedRows);
    const toggleDialog = () => setOpenDialog(!openDialog);
    const toggleConfirmDialog = (dialogType) => {
        setConfirmDialogType(dialogType);
        setOpenConfirmDialog(!openConfirmDialog);
    };

    const handleCheckboxChange = (event) => {
        const { checked, name } = event.target;
        if (name === 'Full Data Transport') {
            setSelectedItems(checked ? ['Full Data Transport', ...tableData.map(item => item.subCategory)] : []);
        } else {
            setSelectedItems(checked ? [...selectedItems, name] : selectedItems.filter(item => item !== name));
        }
    };

    const handleOkClick = () => {
        const dataTransportObjs = selectedRows.map(item => ({
            productGroup: item.productGroup,
            productFamily: item.kmatId
        }));
        dispatch(dashboardDataTransport( userEmail, dataTransportObjs))
        setCheckedRows([]);
        setSelectedItems([]);
        toggleDialog();
    };

    const handleCancelClick = () => {
        setSelectedItems([]);
        toggleDialog();
    };

    const handleConfirmClick = () => {
        const pkId = checkedRows.map(row => row.pkId);
        dispatch(completeModelAvailability(pkId, userEmail, version, region, kmatId, productGroup, productLineID))
        setCheckedRows([]);
        toggleConfirmDialog('');
    };

    const handleDataTransportConfirmClick = () => {
        const pkIds = checkedRows.map(row => row.pkId);
        const moduleType = MODULE_TYPE;
        dispatch(moduleWiseDataTransport(pkIds, userEmail, moduleType, version, region, kmatId, productGroup, productLineID))
        setCheckedRows([]);
        setSelectedItems([]);
        toggleDialog();
        toggleConfirmDialog('');
    };

    const options = [{ subCategory: 'Full Data Transport' }, ...tableData];

    return (
        <>
            {version === VERSION_0002 ? (
                <>
                    <Button
                        color="primary"
                        disableRipple
                        sx={{ ...iconButtonStyle, width: "120px", marginLeft: "10px" }}
                        onClick={() => toggleConfirmDialog('complete')}
                        disabled={isDisabled}
                    >
                        Complete
                    </Button>
                    <Button
                        color="primary"
                        disableRipple
                        sx={{ ...iconButtonStyle, width: "120px" }}
                        onClick={() => toggleConfirmDialog('dataTransport')}
                        disabled={disabled}
                    >
                        Data Transport
                    </Button>
                </>
            ) : (
                <>
                    {version !== VERSION_0003 && (
                    <Button
                        color="primary"
                        disableRipple
                        sx={{ ...iconButtonStyle, width: "120px" }}
                        onClick={toggleDialog}
                        disabled={dashboardDTDisabled}
                    >
                        Data Transport
                    </Button>
                )}
                    <Dialog open={openDialog} onClose={handleCancelClick}>
                        <DialogTitle>Select Table for Data Transport</DialogTitle>
                        <DialogContent>
                            <Box sx={{ display: 'grid' }}>
                                {options.map((item, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                checked={selectedItems.includes(item.subCategory)}
                                                onChange={handleCheckboxChange}
                                                name={item.subCategory}
                                            />
                                        }
                                        label={item.subCategory}
                                    />
                                ))}
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancelClick}>Cancel</Button>
                            <Button onClick={handleOkClick} disabled={selectedItems.length === 0}>OK</Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
            <ConfirmationDialog
                open={openConfirmDialog}
                onClose={() => toggleConfirmDialog(confirmDialogType)}
                onConfirm={confirmDialogType === 'complete' ? handleConfirmClick : handleDataTransportConfirmClick}
                title="Confirmation"
                content={`Are you sure you want to ${confirmDialogType === 'complete' ? 'complete this action' : 'perform data transport'}?`}
                caption="Confirm"
                secondaryCaption="Cancel"
                bgColor="#0278ab"
            />
        </>
    );
};

export default DataTransportDropdown;