export const updateChoices = `mutation($model : UpdateChoicesRequest!){
    updateChoices(input : $model){
        kmatID
        modVer
        choiceID
        tabCategory
        selectionType
        dataRange
        defaultValue
        dataLength
        dataType
        requiredCondition
        displayCondition
        messages
        comments
        obsoleteOn
        isHidden
        isManual
        isTemplate
      }
  }`
