import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
import { setCookie } from "utils/function-utils";
import { CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Logo from "assets/hp_black_logo.png";
import hpLoginLogo from "assets/i/hpLoginLogo.svg";
import { rerouteCachedPath, rerouteFromRoot } from "./Utils";

import {
  HPUID_LOGIN_URL,
  USER_LOGIN_REQUEST
} from 'constants/userConstants';

const useStyles = makeStyles((theme) => ({
  logincontent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  landing: {
    borderRadius: "10px",
    width: "400px",
    minHeight: "180px"
  },
  typographyField: {
    margin: "30px"
  },
  logo: {
    // margin: "15px"
  },
  signInTypography: {
    margin: "15px"
  }
}));

function Login(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const loginError = location?.state?.loginError || null;
  const { userInfo, loading, kbUser } = useSelector((state) => state.user);

  useEffect(() => {
    if (userInfo) {
      const willRoute = rerouteCachedPath(userInfo, navigate, location)
      if (willRoute) return;
      rerouteFromRoot(kbUser, navigate, location.search)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);


  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'local') {
      setCookie("userInfo", "Json Stringify Data");
      setCookie("access_token", '');
      setCookie("expiresAt", '');
      window.location.href = '/dashboard';
    }
  }, [dispatch]);

  const hplogin = useCallback(() => {
    dispatch({ type: USER_LOGIN_REQUEST });
    window.location.href = HPUID_LOGIN_URL;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div className={classes.logincontent}>
      <img src={Logo} alt="logo" height={50} className={classes.logo} />
      <Typography sx={{ margin: "25px", fontSize: "20px" }}>Sign in with your HP account</Typography>
      {
        loginError &&
        <Typography sx={{ margin: "25px", fontSize: "18px", color: 'red' }} >{loginError}</Typography>
      }
      {loading ? (
        <div align="center">
          <CircularProgress />
        </div>
      ) : (
        <Box className={classes.landing} boxShadow={3}>
          <div className={classes.typographyField}>
            <Typography sx={{ fontSize: "16px" }}>
              You are connecting to:
            </Typography>
            <Typography sx={{ fontSize: "26px", marginTop: "5px" }}>
              KB-IDE
            </Typography>
            <Button onClick={hplogin} variant="outlined" sx={{ width: "100%", borderRadius: "10px", marginTop: "20px", border: "1px solid", color: "#027AAE" }}>
              <Grid container sx={{ display: "flex", alignItems: "center" }} >
                <Grid sx={{ marginRight: "20px", marginTop: "5px" }} >
                  <img src={hpLoginLogo} alt="logo" />
                </Grid>
                <Grid>
                  Continue with OneHP
                </Grid>
              </Grid>
            </Button>
          </div>
        </Box>

      )}
    </div>
  );
}

export default Login;
