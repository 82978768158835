import React from 'react';
import { useSelector } from 'react-redux';
import { TextField, Typography, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';
import ErrorToolTip from 'pages/Common/ErrorToolTip';
import { updateChoiceItemFields } from 'actions/modelActions';
import useGlobalStore from 'hooks/useGlobalStore';
import { getTab } from 'pages/Utils';
import { useStyles } from 'pages/EditModel/Tabs/styles';
import DuplicatePartDescriptionsTooltip from 'pages/EditModel/DuplicatePartDescriptionsTooltip';

const RegionalItemEdit = ({ itemID, choiceID, tabName }) => {
  const classes = useStyles();
  const dataTabName = getTab(tabName);
  let data = useSelector(state => state.models[dataTabName][choiceID]?.items[itemID])
  const invalidConditions = useSelector(state => state.models.syntaxCheck[dataTabName][choiceID]?.items[itemID])
  const [itemData, setItemData] = useGlobalStore(data, updateChoiceItemFields, { choiceID, itemID, tabCategory: data?.tabCategory });
  const invalidFilterConditions = invalidConditions?.FilterCondition;
  const descriptionLengtErrors = invalidConditions?.descriptionLengtErrors;

  const handleOnChange = (event, field) => {
    let newVal;
    newVal = event.target.value;
    if (field === 'partDescription' && newVal.length > 250) {
      newVal = newVal.slice(0, 250);
    }

    setItemData((prevData) => ({
      ...prevData,
      [field]: newVal,
      edited: true
    }));
  };

  const partDescriptionLength = itemData?.isSystemChoice ? 0 : itemData?.partDescription?.length;
  const isColor = !itemData?.isSystemChoice && (descriptionLengtErrors || itemData?.partDescription?.length > 30)

  return (
    <Grid container className={classes.root}>
        <Grid size={3}>
          <TextField
            disabled
            label="Part#"
            id="ItemPartNumber"
            variant="filled"
            margin="dense"
            slotProps={{ input: { disableUnderline: true }}}
            value={itemData?.partNumber || ""}
          />
        </Grid>
        <Grid size={3}>
          <TextField
            disabled
            label="Value ID"
            id="VcItemID"
            variant="filled"
            margin="dense"
            slotProps={{ input: { disableUnderline: true }}}
            value={itemData?.isSystemChoice ? "" : itemData?.valueID || ""}
          />
        </Grid>
        <Grid size={6}>
          <TextField
            id="ChoiceItemDesc"
            variant="filled"
            margin="dense"
            label="Part Description (VC)"
            slotProps={{ input: { disableUnderline: true }}}
            disabled={itemData?.isSystemChoice}
            value={itemData?.isSystemChoice ? "" : itemData?.partDescription}
            onChange={(event) => handleOnChange(event, "partDescription")}
          />
          <div className={classes.partDesc}>
            <span><DuplicatePartDescriptionsTooltip syntaxCheck={invalidConditions} /></span>
            <span style={{ size: "smaller", color: isColor ? "red" : "inherit" }}>{partDescriptionLength} chars.</span>
          </div>
        </Grid>
        <Grid size={12}>
          <Typography className={classes.subtitle}> Filter Condition</Typography>
          <TextField
            id="ItemFilterCondition"
            variant="filled"
            margin="dense"
            multiline
            minRows={3}
            label="Filter Condition (When to offer for selection)"
            slotProps={{ input: {classes: { input: classes.textarea }, disableUnderline: true }}}
            value={itemData?.filterCondition || ""}
            onChange={(event) => handleOnChange(event, "filterCondition")}
          />
          <Box>{invalidFilterConditions?.length > 0 && (<ErrorToolTip data={invalidFilterConditions} />)}</Box>
        </Grid>
      <Typography className={classes.subtitle}> CVG</Typography>
        <Grid size={12}>
          <TextField
            disabled
            id="ItemCvg"
            variant="filled"
            margin="dense"
            label="CVG"
            multiline
            slotProps={{ input: {classes: { input: classes.textarea }, disableUnderline: true,} }}
            value={itemData?.cvg || ""}
          />
        </Grid>
    </Grid>
  );
};

export default RegionalItemEdit;