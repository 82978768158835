import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Select from 'react-select';
import { updateUser } from 'actions/userActions';
import { useDispatch } from 'react-redux';
import { getInputProps } from 'pages/DashboardHelpers';


export const UpdateUserDialog = ({ open, chosenUser, remodeledRoleList, selectedValue, handleChange, onClose }) => {
  const dispatch = useDispatch();
  const [firstNameUpdation, setFirstNameUpdation] = useState(chosenUser?.firstName);
  const [lastNameUpdation, setLastNameUpdation] = useState(chosenUser?.lastName);
  const [remodeledArrayOfRolesForUpdation, setRemodeledArrayOfRolesForUpdation] = useState([]);

  useEffect(() => {
    setRemodeledArrayOfRolesForUpdation(chosenUser?.roles?.map(item => ({ value: item.id, label: item.name })))
  }, [chosenUser]);

  const handleUpdateUser = () => {
    dispatch(updateUser(firstNameUpdation, lastNameUpdation, chosenUser, selectedValue));
    onClose();
  };

  return (
    <Dialog className='custom-dialog' open={open} onClose={onClose}>
      <DialogTitle variant='h6'>Modify User Details</DialogTitle>
      <DialogContent>
        <Typography variant="h6" className='sub-header'> User Details </Typography>
        <TextField
          fullWidth
          variant="outlined"
          autoFocus={true}
          slotProps={{ input: getInputProps() }}
          placeholder="First Name"
          value={firstNameUpdation}
          onChange={(e) => { setFirstNameUpdation(e.target.value) }}
        />
        <TextField
          fullWidth
          variant="outlined"
          slotProps={{ input: getInputProps() }}
          placeholder="Last Name"
          value={lastNameUpdation}
          onChange={(e) => { setLastNameUpdation(e.target.value) }}
        />
        <TextField
          disabled
          fullWidth
          variant="outlined"
          slotProps={{ input: getInputProps() }}
          placeholder="Email"
          value={chosenUser?.email}
        />
        <Typography variant="h6" className='sub-header'> Update Role </Typography>
        <FormControl fullWidth>
          <Select
            placeholder="User Role"
            styles={{ control: (provided) => ({ ...provided, padding: '4px', borderRadius: "12px" }) }}
            value={selectedValue.length === 0 ? remodeledArrayOfRolesForUpdation : remodeledRoleList?.filter(role => selectedValue.includes(role.value))}
            options={remodeledRoleList}
            onChange={handleChange}
            menuPosition="fixed"
            isMulti
            isClearable={false}
          />
        </FormControl>
      </DialogContent>
      <DialogActions className='dialog-actions'>
        <Button variant='outlined' onClick={onClose} className='cancel-button'> Cancel </Button>
        <Button onClick={handleUpdateUser} variant="outlined" className='add-Button'>Update</Button>
      </DialogActions>
    </Dialog>
  );
}

