import {
    REFRESH_FILES,
    CLEAR_FILES_FILTERS,
    FILTER_FILES,
    TOGGLE_IS_FAVORITE,
} from 'constants/filesConstants';
import { applyFilters } from 'pages/Utils/FilterUtil';

const initFiles = {
    productLines: [],
    models: [],
    filters: {},
    filteredProducts: [],
    isLoading: true
}

export const filesReducer = (state = initFiles, { type, payload }) => {
    switch (type) {
        case REFRESH_FILES: {
            const newData = payload || initFiles;
            const newState = {
                ...newData,
                filters: state.filters,
                isLoading: false
            };

            const itemsToFilter = payload?.models?.length ? payload.models : [];
            const filteredProducts = applyFilters(Object.values(state.filters), itemsToFilter)

            return {
                ...newState,
                filteredProducts
            };
        }

        
        case CLEAR_FILES_FILTERS: {
            return {
                ...state,
                filters: {},
                filteredProducts: state.models
            };
        }

        case FILTER_FILES: {
            const { name, searchText, matchFields, type } = payload;
            const filters = {
                ...state.filters,
                [name]: {
                    value: searchText,
                    type,
                    matchFields
                }
            }
            
            const filteredProducts = applyFilters(Object.values(filters), state.models)

            return {
                ...state,
                filters,
                filteredProducts
            };
        }

        case TOGGLE_IS_FAVORITE: {
            const { kmatID } = payload;
            const models = state.models.map((model) => model.kmatID === kmatID ? ({...model, isFavorite: !model.isFavorite}) : model);
            const filteredProducts =  applyFilters(Object.values(state.filters), models);

            return {
                ...state,
                models,
                filteredProducts
            }
            
        }

        default: return state;
    }
}

export default filesReducer;