import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import MultiValueInput from './MultiValueInput';

const DownloadDialog = ({ open, handleClose, ocplcSelectedKmats = [] }) => {

    return (open &&
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{ style: { borderRadius: 10, width: 480, padding: 7, margin: 0 } }}
        >
            <DialogTitle>Download KMAT</DialogTitle>
            <DialogContent >
                Are you sure you want to download KMAT?
                <MultiValueInput ocplcSelectedKmats={ocplcSelectedKmats} />
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Button sx={{ marginLeft: "15px" }} className='cancel-button' variant='outlined' onClick={handleClose}>Cancel</Button>
                <Button className='add-Button' variant='contained' disabled={ocplcSelectedKmats?.length === 0} >Download</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DownloadDialog;
