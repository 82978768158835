import React, { useState } from "react";

//Material UI
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { getTab } from "pages/Utils";
//Material UI Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AddIcon from '@mui/icons-material/Add';

import AddNewChoiceDialog from "components/AddNewChoiceDialog/AddNewChoiceDialog"

import '../ReactCheckboxTree/ReactCheckboxTree.css';
import SearchBarTree from "./SearchBarTree";
import { IS_ADD_CHOICE_ENABLED } from "constants/modelConstants";
import useKeypress from "hooks/useKeypress";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    expandCollapse: {
        padding: '12px 5px',
        color: "black"
    }
}));

export default function CheckBoxTreeTopBar(props) {
    const classes = useStyles();

    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogClose = () => {
        setDialogOpen(false);
    };


    const onExpandCollapseClick = () => {
        props.onExpandCollapseClick(!props?.isAllExpanded);
    };

    const onIconClick = (event) => {
        setDialogOpen(!dialogOpen);
    };

    useKeypress({ c: onIconClick, }, getTab(props.selectedTab, IS_ADD_CHOICE_ENABLED));

  

    return (
        <div className='controls-container'>
            <div>
                <Tooltip title={props?.isAllExpanded ? 'Collapse All' : 'Expand All'} placement="top">
                    <IconButton onClick={onExpandCollapseClick} className={classes.expandCollapse}>
                        {!props?.isAllExpanded?<KeyboardArrowRightIcon/>:<KeyboardArrowDownIcon/>}
                    </IconButton>
                </Tooltip>
            </div>

            <div className='search-bar'>
                <SearchBarTree onSearch={props.onSearch} dataType={props.dataType} selectedTab={props.selectedTab} />
            </div>

            {getTab(props.selectedTab, IS_ADD_CHOICE_ENABLED) && <div className='crud-bar'>
                <Tooltip title={dialogOpen ? null : 'Add new choice'} placement="top">
                    <IconButton onClick={(event) => onIconClick(event)}>
                        <AddIcon/>
                    </IconButton>
                </Tooltip>
            </div>}

            {dialogOpen &&
                <AddNewChoiceDialog
                    dialogOpen
                    handleDialogClose={handleDialogClose}
                    kmatId={props.kmatId}
                    currentVersion={props.currentVersion}
                    activeTab={props.selectedTab}
                    dataType={props.dataType}
                    onSelect={(choice) => props.onNewAddition(choice)}
                />
            }

        </div>
    )
}