import React from 'react'
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteIcon from '@mui/icons-material/Delete';


export const findMatchingRole = (roleId, roles) => {
  const matchingRole = roles?.find(r => r.id === roleId);
  return matchingRole ? matchingRole.name : null;
};

export const getColumns = (updateKbUser, deleteKbUser) => [
  {
    headerName: '',
    field: 'avatar',
    headerClassName: 'users-data-grid-headers',
    flex: 0.1,
    sortable: false,
    renderCell: (params) => {
      return (
        <>
          <Avatar className='custom-avatar'>
            {params.row.firstName.charAt(0)}
            {params.row.lastName.charAt(0)}
          </Avatar>
        </>
      );
    }
  },
  {
    headerName: 'First name',
    field: 'firstName',
    headerClassName: 'users-data-grid-headers',
    headerAlign: 'left',
    flex: 0.2,
    sortable: false
  },
  {
    headerName: 'Last name',
    field: 'lastName',
    headerClassName: 'users-data-grid-headers',
    headerAlign: 'left',
    flex: 0.2,
    sortable: false
  },
  {
    headerName: 'Email',
    field: 'email',
    headerClassName: 'users-data-grid-headers',
    headerAlign: 'left',
    flex: 0.3,
    sortable: false
  },
  {
    headerName: 'KB Role',
    field: 'role',
    headerClassName: 'users-data-grid-headers role-name',
    headerAlign: 'left',
    flex: 0.20,
    sortable: false,
    renderCell: (params) => {
      return (
        <>
          {findMatchingRole(params.row.role, params.row.roles)}
        </>
      );
    }
  },
  {
    headerName: 'OCPLC Role',
    field: 'ocplcRole',
    headerClassName: 'users-data-grid-headers role-name',
    headerAlign: 'left',
    flex: 0.20,
    sortable: false,
    renderCell: (params) => {
      return (
        <>
           {findMatchingRole(params.row.ocplcRole, params.row.roles)}
        </>
      );
    }
  },
  {
    headerName: '',
    field: 'actions',
    headerClassName: 'users-data-grid-headers user-actions',
    headerAlign: 'left',
    flex: 0.13,
    sortable: false,
    renderCell: (params) => {
      return (
        <div className='header-actions'>
          <IconButton
            className='edit-user-button'
            disableRipple
            onClick={() => updateKbUser(params.row)}
            sx={{ cursor: "pointer", padding: "3px" }} >
            <DriveFileRenameOutlineIcon />
          </IconButton>
          <IconButton
            className='delete-user-button'
            disableRipple
            onClick={() => deleteKbUser(params.row)}
            sx={{ color: '#B90E0A', cursor: "pointer", padding: "3px" }} >
            <DeleteIcon />
          </IconButton>
        </div>
      );
    }
  },
];