import React, { useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CVGMGMT_TAB, REGIONAL_TAB, REGIONAL_TABS, STEP_NAMES_REGIONAL_TABS, WWNA_TAB, WW_TAB } from "constants/modelConstants";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ErrorIcon from '@mui/icons-material/Error';
import { getTab } from "pages/Utils";
import { useStyles } from "./stepperPanelHelpers";
import { cvgTabSelection, snackBar, tabSelection } from "actions/modelActions";
import CheckBoxTreeContainer from "components/ReactCheckboxTree/CheckBoxTreeContainer";
import CvgTree from "components/CvgTree/CvgTree";

function RegionalStepperPanel(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedRegionalTab, setSelectedRegionalTab] = useState(WWNA_TAB);
  const syntaxCheckDone = useSelector(state => state.models.syntaxCheckDone);
  const syntaxCheck = useSelector(state => state.models.syntaxCheck);
  const isOnCVGTab = selectedRegionalTab === CVGMGMT_TAB;
  const isOnRegionalTab = selectedRegionalTab === REGIONAL_TAB
  const isOnWWTab = selectedRegionalTab === WW_TAB;

  const handleSelectTab = (event, newValue) => {
    setSelectedRegionalTab(STEP_NAMES_REGIONAL_TABS[newValue]);
    dispatch(tabSelection(STEP_NAMES_REGIONAL_TABS[newValue]));
    isOnCVGTab && dispatch(cvgTabSelection(WWNA_TAB));
    dispatch(snackBar(false));
  };

  /**
   * Check any tab is having workspace error or not
   * @param {tab} string
   * @return {boolean} return true or false.
   */
  const isErrorOnValidateWorkSpace = (tab) => {
    const tabData = syntaxCheck[getTab(tab)];
    return tabData?.hasError;
  };

  useLayoutEffect(() => {
    const hasError = REGIONAL_TABS.reduce((acc, tab) => {
      acc[tab.id] = syntaxCheckDone ? isErrorOnValidateWorkSpace(tab["id"]) : true;
      return acc;
    }, {});
    dispatch({ type: "SET_IS_ERROR_ON_VALIDATE", payload: hasError })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syntaxCheckDone, syntaxCheck]);

  return (
    <>
      <div style={{ marginTop: "10px" }} className={classes.tabBorder}>
          <Tabs
            value={REGIONAL_TABS.findIndex(tab => tab.id === selectedRegionalTab)}
            onChange={handleSelectTab}
            aria-label="simple tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            {REGIONAL_TABS?.map(({ id, title }) => {
              const isError = isErrorOnValidateWorkSpace(id);
              return (
                <Tab
                  key={title}
                  iconPosition="end"
                  icon={syntaxCheckDone && isError ? (  <ErrorIcon className={selectedRegionalTab === id ? classes.activeDot : classes.dot} /> ) : null}
                  label={title}
                />
              );
            })}
          </Tabs>
      </div>

      {!isOnCVGTab ? (
        <CheckBoxTreeContainer
          dataType={props?.dataType}
          selectedTab={selectedRegionalTab}
          currentVersion={props?.currentVersion}
          kmatId={props?.kmatID}
          isOnRegionalTab={isOnRegionalTab}
          isOnWWTab={isOnWWTab}
        />
      ) : (
        <CvgTree dataType={props?.dataType} currentVersion={props?.currentVersion} kmatId={props?.kmatID} />
      )}
    </>
  );
}

export default RegionalStepperPanel;
