import React, { memo, useState, useCallback, useLayoutEffect } from 'react';

import '../ReactCheckboxTree/ReactCheckboxTree.css'
import ReactCheckboxTree from './ReactCheckboxTree'
import CheckBoxTreeTopBar from './CheckBoxTreeTopBar';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import useKeypress from 'hooks/useKeypress';
import { useActiveDataSet } from 'pages/Utils/TabDataUtil';
import { StaticData } from 'pages/EditModel/Tabs/StaticData';
import { useDispatch, useSelector } from 'react-redux';
import EditModel from 'pages/EditModel/index';
import EditRegional from 'pages/EditRegional';

export default memo(function CheckBoxTreeContainer(props) {
    const {
        selectedTab,
        isOnRegionalTab,
        isOnWWTab,
        toggleNavigation,
        dataType,
        kmatId,
        currentVersion,
        setToggleNavigation,
        isTabClicked,
        setTabClicked
    } = props;

    const { datasetValues } = useActiveDataSet(selectedTab);
    const expandActions = useSelector((state) => state.models.expandActions);
    const selectedItem = useSelector((state) => state.models.selectedItem);
    const [isAllExpanded, setIsAllExpanded] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [checkedItems, setCheckedItems] = useState([]);

    const dispatch = useDispatch();

    const initialActiveNodeState = { PM: null, Misc: null, CarePack: null, CustomService: null, Spares: null, Deploy: null, MandA: null };
    const [activeNode, setActiveNode] = useState(initialActiveNodeState);

    useLayoutEffect(() => {
        if (!selectedTab) return;
        setCheckedItems([])
        setIsAllExpanded(false);
        if (Object.keys(selectedItem).length > 0 && selectedItem?.[selectedTab] && expandActions[selectedTab]?.length > 0) {
            setActiveNode((prevActiveNode) => ({
                ...prevActiveNode,
                [selectedTab]: selectedItem[selectedTab]
            }));
        } else if (datasetValues && datasetValues.length > 0) {
            setActiveNode((prevActiveNode) => ({
                ...prevActiveNode,
                [selectedTab]: prevActiveNode[selectedTab] || datasetValues[0]
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab]);

    let currentActiveNode = activeNode[selectedTab] || datasetValues[0];

    const onExpandCollapse = useCallback((isAllExpanded) => {
        setIsAllExpanded(isAllExpanded);
    }, []);

    const onSearch = (text) => {
        setSearchKeyword(text);
    }

    const treeContainerSelect = (n) => {
        setActiveNode({ ...activeNode, [selectedTab]: n });
        dispatch({type: "SELECTED_ITEM", payload: n});
    }

    const handleViewEditItemsClick = () => {
        setActiveNode({ ...activeNode, [selectedTab]: [] });
        setToggleNavigation(true);
    };

    useKeypress({ b: handleViewEditItemsClick }, checkedItems?.length);

    return (
        <>
            {isOnRegionalTab || isOnWWTab
                ? (<Paper className='paper'><StaticData datasetValues={datasetValues} isOnRegionalTab={isOnRegionalTab} /> </Paper>)
                : (<Grid container spacing={1.5} className='root2'>
                    <Grid size={{ sm: 12, md: 4.2 }} style={{ display: toggleNavigation ? "none" : "flex", flexDirection: "column" }}>
                        <Paper className='paper'>
                            <div className='tree-container'>
                                <CheckBoxTreeTopBar
                                    isAllExpanded={isAllExpanded}
                                    dataType={dataType}
                                    selectedTab={selectedTab}
                                    onExpandCollapseClick={onExpandCollapse}
                                    onSearch={onSearch}
                                    onNewAddition={treeContainerSelect}
                                    kmatId={kmatId}
                                    currentVersion={currentVersion}
                                />
                                {/* Used for displaying the tree */}
                                <ReactCheckboxTree
                                    dataType={dataType}
                                    onExpandCollapse={onExpandCollapse}
                                    activeNode={activeNode}
                                    setActiveNode={setActiveNode}
                                    currentActiveNode={currentActiveNode}
                                    checkedItems={checkedItems}
                                    setCheckedItems={setCheckedItems}
                                    datasetValues={datasetValues}
                                    setToggleNavigation={setToggleNavigation}
                                    selectedTab={selectedTab}
                                    onExpandCollapseClick={isAllExpanded}
                                    searchKeyword={searchKeyword}
                                    currentVersion={currentVersion}
                                    kmatId={kmatId}
                                />
                            </div>
                            {dataType !== 'regional' && (
                            <Button variant="contained" className='viewBtn' disabled={!checkedItems.length} fullWidth onClick={handleViewEditItemsClick}>View/Edit Selected Items</Button>
                            )}
                        </Paper>
                    </Grid>
                    <Grid id="edit-container" size={{ sm: toggleNavigation ? true : 12, md: toggleNavigation ? true : 7.8 }} sx={{width:"100%"}} >
                        <Paper className='paper'>
                            {dataType !== 'regional' ? (
                                <EditModel
                                    activeNode={activeNode}
                                    setActiveNode={setActiveNode}
                                    currentActiveNode={currentActiveNode}
                                    checkedItems={checkedItems}
                                    setCheckedItems={setCheckedItems}
                                    dataType={dataType}
                                    selectedTab={selectedTab}
                                    datasetValues={datasetValues}
                                    toggleNavigation={toggleNavigation}
                                    setToggleNavigation={setToggleNavigation}
                                    currentVersion={currentVersion}
                                    kmatId={kmatId}
                                    isTabClicked={isTabClicked}
                                    setTabClicked={setTabClicked}
                                />
                            ) : (
                                <EditRegional
                                    dataType={dataType}
                                    selectedTab={selectedTab}
                                    kmatId={kmatId}
                                    activeNode={activeNode}
                                    setActiveNode={setActiveNode}
                                    currentActiveNode={currentActiveNode}
                                    datasetValues={datasetValues}
                                />
                            )}
                        </Paper>
                    </Grid>
                </Grid>
                )}
        </>
    )
})