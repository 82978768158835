import React, { useState, useCallback, useLayoutEffect, useMemo } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getColumns } from './columnsConfig';
import { DataGrid } from '@mui/x-data-grid';
import { dataGridStyles, ocplcContainerStyles } from '../DashboardHelpers';
import RoundedPagination from 'pages/Pagination';
import Box from '@mui/material/Box';
import { filterOcplcDashboard, modelAvailability, ocplcgetModelTOFAuditTrial, countryList } from 'actions/ocplcAction';
import EditOcplc from 'pages/ocplcDashboard/EditOcplc';
import ActionableButtons from './ActionableButtons';
import { noDataFoundStyles } from 'pages/ocplcDashboard/ocplcHelpers';
import { VERSION_0002, VERSION_0003 } from 'constants/ocplcConstants';
import FeedDialog from 'pages/ocplcDashboard/FeedTOFAudit';


const PlcEditDashboard = (props) => {
    const dispatch = useDispatch();
    const { version, region, kmatId, selectedTable, regionDropdown, productGroup, userRole, userEmail, assignedRole, productLineID, checkedRows, setCheckedRows } = props;
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [feedRowData, setFeedRowData] = useState(null);
    const [openEditPopup, setOpenEditPopup] = useState(false);
    const [openFeedDialog, setOpenFeedDialog] = useState(false);
    const disableAction = version === VERSION_0003 ? true : false;
    const { filteredModelData, modelAvailabilityData, countryListData, businessModels, isLoading, filtersEdit } = useSelector(state => state.ocplc);
    const newCountryList = [{ "countryCode": "ALL", "countryName": "ALL", "regionCode": "ALL", }, ...(countryListData ?? [])]

    useLayoutEffect(() => {
        dispatch(countryList(region));
        dispatch(modelAvailability(version, region, kmatId, productGroup, productLineID));
    }, [region, kmatId]);

    const handleFilterChange = useCallback((col, value) => {
        dispatch(filterOcplcDashboard({ ...col, searchText: value }))
    }, [dispatch]);

    const handleViewModel = useCallback((row) => {
        setOpenEditPopup(true);
        setSelectedRowData(row);
    }, []);

    const handleFeedDialog = useCallback((row, feedType) => {
        setOpenFeedDialog(true);
        setFeedRowData(feedType);
        dispatch(ocplcgetModelTOFAuditTrial(feedType, VERSION_0002, row.region, row.productFamily, row.country, row.businessModel));
    }, []);

    const handleRowClick = useCallback((params, event) => {
        if (event.target.type !== "checkbox") {
            event.defaultMuiPrevented = true;
            handleViewModel(params.row);
        }
    }, []);

    const handleRowSelection = useCallback((newSelection) => {
        const selectedItems = modelAvailabilityData.filter(row => newSelection.includes(row.pkId));
        setCheckedRows(selectedItems);
    }, [modelAvailabilityData, setCheckedRows]);
    
    const columns = getColumns(filtersEdit, modelAvailabilityData, handleFilterChange, newCountryList, businessModels, regionDropdown, handleViewModel, region, disableAction, handleFeedDialog);
    
    return (
        <Box sx={{ ...ocplcContainerStyles, minWidth: "100%" }}>

            {openEditPopup && selectedRowData && (
                <EditOcplc
                    open={openEditPopup}
                    handleClose={() => setOpenEditPopup(false)}
                    plcDetails={selectedRowData}
                    onSave={() => setOpenEditPopup(false)}
                    countryList={newCountryList}
                    businessModels={businessModels}
                    userEmail={userEmail}
                    selectedTableRegion={region}
                    disabledAction={disableAction}
                    assignedRole={assignedRole}
                    kmatId={kmatId}
                    productLineID={productLineID}
                    copy={false}
                />
            )}

            {openFeedDialog && <FeedDialog open={openFeedDialog} setOpenFeedDialog={setOpenFeedDialog} feedRowData={feedRowData} />}

            {selectedTable == "Model Availability" ? (
                <>
                    <ActionableButtons setCheckedRow={setCheckedRows} countryList={newCountryList} assignedRole={assignedRole} version={version} productGroup={productGroup} kmatId={kmatId} region={region} disableAction={disableAction} filteredModelData={filteredModelData} userRole={userRole} checkedRow={checkedRows} userEmail={userEmail} businessModels={businessModels} productLineID={productLineID} />

                    {modelAvailabilityData?.length > 0 ? (
                        <DataGrid
                            columns={columns}
                            rows={filteredModelData}
                            getRowId={(row) => row.pkId}
                            rowHeight={48}
                            loading={isLoading}
                            columnHeaderHeight={90}
                            onRowClick={handleRowClick}
                            slots={{ pagination: RoundedPagination }}
                            checkboxSelection
                            rowSelectionModel={checkedRows.map(row => row.pkId)}
                            onRowSelectionModelChange={handleRowSelection}
                            getRowClassName={(params) => `${params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'}`}
                            headerClassName='dashboard-grid-headers'
                            initialState={{ pagination: { paginationModel: { page: 0, pageSize: 10 } } }}
                            sx={dataGridStyles}
                            disableColumnFilter
                            disableColumnMenu
                        />
                    ) : (
                        !isLoading && modelAvailabilityData?.length === 0 && (<div style={noDataFoundStyles}>No data found</div>)
                    )}
                </>
            ) : (
                <div style={noDataFoundStyles}> No data found </div>
            )}
        </Box>
    )
}

export default PlcEditDashboard;
