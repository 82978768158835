export const updateModelAvailability= `mutation updateModelAvailability($input: UpdateCopyModelAvailabilityRequest!) {
  updateModelAvailability(input: $input) {
    message
  }
}`;

  export const updateTofModelAvailability= `mutation updateTofModelAvailability($input: ModelAvailabilityOthRegRequest!) {
    updateTofModelAvailability(input: $input) {
      message
    }
  }`;