import React, { useState, useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import useGlobalStore from "hooks/useGlobalStore";
import { updateChoiceItemFields } from 'actions/modelActions';
import { PreSelectedItem } from "./PreSelectedItem";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useStyles } from "./styles";


const defaultItem = { condition: '', type: '' };

const PreSelectedItems = ({ choiceID, isSystemChoice, itemID, tabName, data, syntaxCheck }) => {
  const classes = useStyles();

  const getItemSource = useMemo(() => {
   return data?.defaultComponents?.length ? data : { defaultComponents: [defaultItem] };
  }, [data]);

  const [itemWithDefaultComponents, setDefaultComponents] = useGlobalStore(getItemSource, updateChoiceItemFields, { choiceID, itemID, tabCategory: tabName });

  const [deleteIndex, setDeleteIndex] = useState(null);

  const handleClickDelete = useCallback((idx) => setDeleteIndex(idx), []);

  const handleDeleteClose = useCallback(() => setDeleteIndex(null), []);

  const handleEditComponent = (value, type, idx) => {
    setDefaultComponents((prevState) => ({ ...prevState, defaultComponents: prevState.defaultComponents.map((condition, index) => index === idx ? { ...condition, [type]: value } : condition), edited: true }));
  };

  const handleAddComponent = () => {
    setDefaultComponents(prevState => ({ ...prevState, defaultComponents: [...prevState.defaultComponents, { condition: '', type: '' }], edited: true }));
  };

  const deleteItem = () => {
    const newItems = itemWithDefaultComponents.defaultComponents.filter((_, index) => index !== deleteIndex);
    setDefaultComponents({ defaultComponents: newItems, edited: true });
    handleDeleteClose();
  };

  const PreSelectedItemToDelete = itemWithDefaultComponents?.defaultComponents[deleteIndex]?.condition

  return (
    <>
      <Box className={classes.root}>
        {itemWithDefaultComponents.defaultComponents?.map((component, idx) => {
          const condition = getItemSource.defaultComponents[idx]?.condition || component.condition;

          return (
            <PreSelectedItem
              key={`item-${idx}`}
              condition={component.condition}
              type={component.type}
              index={idx}
              handleEditComponent={handleEditComponent}
              handleDelete={handleClickDelete}
              tabName={tabName}
              isSystemChoice={isSystemChoice}
              errorData={syntaxCheck?.DefaultComponentCondition?.[condition]}
            />
          )
        })}

        {!isSystemChoice &&
          <Button
            variant="text"
            className={classes.addMsgButton}
            onClick={() => handleAddComponent()}
          >
            <AddIcon />
            Add New Pre-Selected Fields
          </Button>
        }
      </Box>
      
      {deleteIndex !== null &&
        <ConfirmationDialog
          open={deleteIndex !== null}
          title="Delete Pre-Selected item"
          content={<>You are about to delete "<b>{PreSelectedItemToDelete}</b>" defaulting component.<br /> Are you sure you want to delete it?</>}
          onClose={handleDeleteClose}
          onConfirm={deleteItem}
          caption="Delete"
          bgColor="#BE1313"
        />}
    </>
  );
};

export default PreSelectedItems;
