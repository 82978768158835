export const moduleWiseDataTransport = `query moduleWiseDataTransport($filter: ModuleWiseDTRequest!) {
  moduleWiseDataTransport(filter: $filter) {
    response
  }
}`;


export const completeModelAvailability = `mutation($input: CompleteModelAvailabilityRequest!) {
  completeModelAvailability(input: $input) {
    message
  }
}`;

export const dataTransport = `query dataTransport($filter: DataTransportRequest!) {
  dataTransport(filter: $filter) {
    response
  }
}`;

