import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { GraphqlApi } from "services/api";
import { useParams, useNavigate } from "react-router-dom";
import { snackBar } from "actions/modelActions";
import fileDownload from "js-file-download";
import { CustomTabPanel } from "./CustomTab";
import { FilesDataGrid } from "./FilesDataGrid";
import { Typography, Box } from '@mui/material';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Button from '@mui/material/Button';

export const FilePicker = () => {
    const { kmatID, tab } = useParams();
    const navigate = useNavigate();
    const [kind, setKind] = useState(tab || 'production');

    const dispatch = useDispatch();

    const [files, setFiles] = useState([]);
    const [scitSelect, setScitSelect] = useState([]);
    const [pilotSelect, setPilotSelect] = useState([]);
    const [prodSelect, setProdSelect] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        try {
            const getData = async () => {
                const { data, errors } = await GraphqlApi.query('pcxFiles', { kmatID });

                if (errors) {
                    for (const err of errors) {
                        if (err.message === 'forbidden') return;
                    }
                }

                setIsLoading(false);
                if (!data.pcxFiles) return;
                setFiles(data.pcxFiles);
            };
            getData()
        } catch (error) {
            dispatch(snackBar(true, error.message));
        }
    }, [kmatID, dispatch])

    const handleDownload = async () => {
        try {
            if (isLoading || !files) {
                throw new Error('No files selected for download');
            }

            const selectedIds = [...scitSelect, ...pilotSelect, ...prodSelect];
            const selection = files.filter(file => selectedIds.includes(file.name));

            if (!selection.length) {
                throw new Error('No files selected for download');
            }

            const res = await GraphqlApi.query('pcxDownload', { files: selection });
            const dlFiles = res.data.pcxDownload
            
            if (dlFiles) {
                dlFiles.forEach((file) => {
                    fileDownload(file.content, `${file.name}`);
                })
            }

        } catch (error) {
            dispatch(snackBar(true, error.message));
        }
    }


    const columns = [
        {
            headerName: 'Name',
            field: 'name',
            headerClassName: 'dashboard-data-grid-headers',
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div>
                        <Typography variant='subtitle2'>{params.row.name}</Typography>
                    </div>
                );
            },
            renderHeader: () => {
                return (
                    <Typography sx={{ margin: 0, padding: 0, paddingTop: '2px' }}>
                        Select All
                    </Typography>
                )
            }
        },
    ];

    const tabProps = (index = 0) => {
        return {
            id: `tab-${index}`,
            'aria-controls': `tabpanel-${index}`,
        };
    }

    const handleTabChange = (newValue) => {
        setKind(newValue);
        setProdSelect([]);
        setScitSelect([]);
        setPilotSelect([]);
        navigate(`/files/${kmatID}/${newValue}`);
    }


    return (
        <div style={{ display: 'flex', height: '100%', flexDirection: 'column', maxWidth: '700px' }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "15px", marginTop: "35px" }} justifyContent="space-between" alignItems="center">
                <Typography variant='h5'>
                    {kmatID}
                </Typography>
                <Button onClick={handleDownload} disabled={isLoading || !files || ![...scitSelect, ...pilotSelect, ...prodSelect].length} variant="outlined" >Download</Button>
            </Box>
            <TabContext value={kind}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} display="flex" justifyContent="space-between" alignItems="center">
                    <TabList onChange={(_, newValue) => handleTabChange(newValue)}>
                        <Tab label="Production" value="production" {...tabProps(0)} />
                        <Tab label="SCIT" value="scit" {...tabProps(1)} />
                        <Tab label="Pilot" value="pilot" {...tabProps(2)} />
                    </TabList>
                </Box>
                {
                    files ?
                        <>
                            <CustomTabPanel value={kind} index={0}>
                                <FilesDataGrid
                                    loading={isLoading}
                                    columns={columns}
                                    rows={files.filter(file => file.kind === 'production')}
                                    rowSelectionModel={prodSelect}
                                    onRowSelectionModelChange={newSelection => setProdSelect(newSelection)}
                                    getRowId={(row) => row.name}
                                />
                            </CustomTabPanel>
                            <CustomTabPanel value={kind} index={1}>
                                <FilesDataGrid
                                    loading={isLoading}
                                    columns={columns}
                                    rows={files.filter(file => file.kind === 'scit')}
                                    rowSelectionModel={scitSelect}
                                    onRowSelectionModelChange={newSelection => setScitSelect(newSelection)}
                                    getRowId={(row) => row.name}

                                />
                            </CustomTabPanel>
                            <CustomTabPanel value={kind} index={2}>
                                <FilesDataGrid
                                    loading={isLoading}
                                    columns={columns}
                                    rows={files.filter(file => file.kind === 'pilot')}
                                    rowSelectionModel={pilotSelect}
                                    onRowSelectionModelChange={newSelection => setPilotSelect(newSelection)}
                                    getRowId={(row) => row.name}
                                />
                            </CustomTabPanel>
                        </>
                        :
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                marginBottom: "15px",
                                marginTop: "35px"
                            }}
                            justifyContent="space-between"
                            alignItems="center"
                            >
                            {isLoading ? '' : 'No data found!'}
                        </Box>

                }


            </TabContext>
        </div>
    )
}