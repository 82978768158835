import React, { useState, useEffect } from 'react'
import { useDispatch } from "react-redux";
import { GraphqlApi } from 'services/api';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import "./ui-specs.css";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { snackBar } from "actions/modelActions";
import { uiSpecGridStyle } from 'pages/DashboardHelpers';

const consumerColumns = [
    {
        field: "id",
        flex: 1,
        headerName: "Consumer Choice IDs",
        sortable: false,
        headerAlign: "left"
    }
];

const commercialColumns = [
    {
        field: "id",
        flex: 1,
        headerName: "Commercial Choice IDs",
        sortable: false,
        headerAlign: "left"
    }
];

const printColumns = [
    {
        field: "id",
        flex: 1,
        headerName: "Print Choice IDs",
        sortable: false,
        headerAlign: "left"
    }
]

const renderDataGrid = (columns, rows, loading, handleRowOrderChange) => (
    <DataGridPro
        columns={columns}
        rows={rows}
        getRowId={(row) => row.id}
        loading={loading}
        rowHeight={38}
        rowReordering
        onRowOrderChange={handleRowOrderChange}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnMenu
        sx={uiSpecGridStyle}
        className='ui-specs-data-grid'
    />
);

function updateRowPosition(initialIndex, newIndex, rows) {
    return new Promise((resolve) => {
        const rowsClone = [...rows];
        const row = rowsClone.splice(initialIndex, 1)[0];
        rowsClone.splice(newIndex, 0, row);
        resolve(rowsClone);
    });
}

function UISpecs() {
    const [commercialData, setCommercialData] = useState([]);
    const [consumerData, setConsumerData] = useState([]);
    const [printData, setPrintData] = useState([]);
    const [formattedCommercialData, setFormattedCommercialData] = useState([]);
    const [formattedConsumerData, setFormattedConsumerData] = useState([]);
    const [formattedPrintData, setFormattedPrintData] = useState([]);
    const [arrayOfRowIDsForConsumer, setArrayofRowIDsForConsumer] = useState([]);
    const [arrayOfRowIDsForCommercial, setArrayofRowIDsForCommercial] = useState([]);
    const [arrayofRowIDsforPrint, setArrayofRowIDsForPrint] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [shouldBeDisabled, setShouldBeDisabled] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        GraphqlApi.query('choiceSequence')
            .then((res) => {
                res.data.choiceSequence?.forEach(eachSeq => {
                    if (eachSeq.family === 'CONS') {
                        setConsumerData(eachSeq.choiceIDs);
                        setArrayofRowIDsForConsumer(eachSeq.choiceIDs);
                    } else if (eachSeq.family === 'COMM') {
                        setCommercialData(eachSeq.choiceIDs);
                        setArrayofRowIDsForCommercial(eachSeq.choiceIDs);
                    } else {
                        setPrintData(eachSeq.choiceIDs);
                        setArrayofRowIDsForPrint(eachSeq.choiceIDs);
                    }
                })
            }).catch((error) => {
                dispatch(snackBar(true, error.message));
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFormattedConsumerData(consumerData.map(eachRow => {
            return {
                id: eachRow
            }
        }));
    }, [consumerData]);

    useEffect(() => {
        setFormattedCommercialData(commercialData.map(eachRow => {
            return {
                id: eachRow
            }
        }));
    }, [commercialData]);

    useEffect(() => {
        setFormattedPrintData(printData.map(eachRow => {
            return {
                id: eachRow
            }
        }));
    }, [printData]);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleRowOrderChangeForConsumer = async (params) => {
        const newRows = await updateRowPosition(
            params.oldIndex,
            params.targetIndex,
            formattedConsumerData,
        );

        setArrayofRowIDsForConsumer(newRows.map(eachRow => {
            return eachRow.id
        }));
        setFormattedConsumerData(newRows);
        setShouldBeDisabled(false);
    };

    const handleRowOrderChangeForCommercial = async (params) => {
        const newRows = await updateRowPosition(
            params.oldIndex,
            params.targetIndex,
            formattedCommercialData,
        );

        setArrayofRowIDsForCommercial(newRows.map(eachRow => {
            return eachRow.id
        }));
        setFormattedCommercialData(newRows);
        setShouldBeDisabled(false);
    };

    const handleRowOrderChangeForPrint = async (params) => {
        const newRows = await updateRowPosition(
            params.oldIndex,
            params.targetIndex,
            formattedPrintData,
        );

        setArrayofRowIDsForPrint(newRows.map(eachRow => {
            return eachRow.id
        }));
        setFormattedPrintData(newRows);
        setShouldBeDisabled(false);
    };

    const handleUpdateChoiceSequence = () => {
        GraphqlApi.query('updateChoiceSequence', {
            model: [
                {
                    family: 'CONS',
                    choiceIDs: arrayOfRowIDsForConsumer,
                },
                {
                    family: 'COMM',
                    choiceIDs: arrayOfRowIDsForCommercial
                },
                {
                    family: 'PRINT',
                    choiceIDs: arrayofRowIDsforPrint
                }
            ]
        }).then(() => {
            window.location.reload();
        }).catch((error) => {
            dispatch(snackBar(true, error.message));
        });
    }

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '15px', marginTop: '15px' }}>
                <Typography variant="h5">UI-Specs</Typography>
                <Button
                    variant="outlined"
                    disabled={shouldBeDisabled}
                    sx={{ marginLeft: "35px", borderRadius: "8px" }}
                    onClick={handleUpdateChoiceSequence}
                >
                    Update
                </Button>
            </Box>

            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="DataGrid Tabs">
                <Tab label="CONSUMER" />
                <Tab label="COMMERCIAL" />
                <Tab label="PRINT" />
            </Tabs>
         
                {selectedTab === 0 && renderDataGrid(consumerColumns, formattedConsumerData, consumerData.length === 0, handleRowOrderChangeForConsumer)}
                {selectedTab === 1 && renderDataGrid(commercialColumns, formattedCommercialData, commercialData.length === 0, handleRowOrderChangeForCommercial)}
                {selectedTab === 2 && renderDataGrid(printColumns, formattedPrintData, printData.length === 0, handleRowOrderChangeForPrint)}
                
        </>
    )
}

export default UISpecs;
