import React, { useEffect, useCallback, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { debounce, getCookie, setCookie } from "utils/function-utils";
import { rolesMap, rerouteCachedPath } from "./Utils/RerouteUtil";

const ProtectedRoute = ({ component: Component, auth, dataType }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  setCookie('rememberRedirect', JSON.stringify(location));
  const debouncedRerouteCachedPath = useCallback(() => debounce(rerouteCachedPath, 100), []);

  useEffect(() => {
    if(auth) setLoading(false);
    debouncedRerouteCachedPath(auth, navigate, location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, location, debouncedRerouteCachedPath]);

  const pathCookie = getCookie('rememberRedirect');
  const originalRedirect = pathCookie && JSON.parse(pathCookie);
  const page = originalRedirect?.pathname?.split('/')[1];
  const isUserRoleValid = auth?.roles?.some(role => rolesMap[role.id]?.accessible.includes(page));
  const defaultRoute = rolesMap[auth?.role]?.defaultRoute;

  if (loading) return null;

  if (dataType) {
    return auth ? <Component dataType={dataType} /> : <Navigate to="/" state={{ from: location }} />;
  }

  return isUserRoleValid ? <Component  auth={auth}/> : <Navigate to={defaultRoute || "/"} />;
};

export default ProtectedRoute;
