import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ErrorIcon from '@mui/icons-material/Error';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        borderBottom: '1px solid #ADADAD',
        marginTop: "8px",
        marginBottom: "15px",
    },
}));

export default function ItemsTabPanel({ activeTabIndex, setActiveTabIndex, hasComments, syntaxCheck }) {
    const classes = useStyles();
    const hasMessagesErros = Boolean(syntaxCheck?.MessageCondition);
    const hasPreselectionConditionsErros = Boolean(syntaxCheck?.DefaultComponentCondition);

    const handleChange = (event, newIndex) => {
        setActiveTabIndex(newIndex);
    };

    const tabs = [
        { label: 'Item Settings', id: 'item-settings' },
        { label: 'Pre-selected Items', id: 'pre-selected-items', icon: hasPreselectionConditionsErros},
        { label: 'Messages', id: 'messages', icon: hasMessagesErros },
        { label: hasComments ? <b style={{ color: "#2e2e2e" }}>User Comments</b> : "User Comments", id: 'user-comments' },
    ];

    return (
        <div className={classes.root}>
                <Tabs value={activeTabIndex} onChange={handleChange} aria-label="styled tabs" indicatorColor="primary" textColor="primary" >
                    {tabs.map((tab, id) => (
                        <Tab key={id} className={classes.tabs} iconPosition='end' label={tab.label} icon={tab.icon ? <ErrorIcon /> : null} />
                    ))}
                </Tabs>
        </div>
    );
}
