import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CVGMGMT_TAB, PM_TAB, REGIONAL_TAB, STEP_NAMES, TABS } from "constants/modelConstants";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import ErrorIcon from '@mui/icons-material/Error';
import { getTab } from "pages/Utils";
import { useStyles } from "./stepperPanelHelpers";
import { cvgTabSelection, snackBar, tabSelection } from "actions/modelActions";
import CheckBoxTreeContainer from "components/ReactCheckboxTree/CheckBoxTreeContainer";
import CvgTree from "components/CvgTree/CvgTree";

const StepperPanel = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(PM_TAB);
  const [toggleNavigation, setToggleNavigation] = useState(false);
  const [isTabClicked, setTabClicked] = useState(false);
  const syntaxCheck = useSelector(state => state.models.syntaxCheck);
  const syntaxCheckDone = useSelector(state => state.models.syntaxCheckDone);
  const isTabDataUpdated = useSelector(state => state.models.isTabDataUpdated);
  const isOnCVGTab = selectedTab === CVGMGMT_TAB;
  const isOnRegionalTab = selectedTab === REGIONAL_TAB;

  const iterableTabs = React.useMemo(() => {
    const indexRegionalTab = TABS.findIndex(tab => tab.id === REGIONAL_TAB);
    return props?.dataType === "template" ? TABS.slice(0, indexRegionalTab) : TABS
  }, [props?.dataType]);

  const handleSelectTab = (event, newValue) => {
    if (toggleNavigation) {
      setTabClicked(true)
    } else {
      setToggleNavigation(false)
      setSelectedTab(STEP_NAMES[newValue]);
      dispatch(tabSelection(STEP_NAMES[newValue]));
      isOnCVGTab && dispatch(cvgTabSelection(PM_TAB));
      dispatch(snackBar(false));
    }
  }

  return (
    <>
      <div style={{ marginTop: "15px" }} className={classes.tabBorder}>
          <Tabs
            value={iterableTabs.findIndex((tab) => tab.id === selectedTab)}
            onChange={handleSelectTab}
            aria-label="simple tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            {iterableTabs?.map(({ id, title }) => {
              const tabHasError = syntaxCheck[getTab(id)]?.hasError;
              const isTabUpdated = props?.dataType && isTabDataUpdated[id];
              return (
                <Tab
                  key={title}
                  iconPosition="end"
                  icon={syntaxCheckDone && tabHasError ? (<ErrorIcon className={selectedTab === id ? classes.activeDot : classes.dot} />) : null}
                  label={isTabUpdated ? (<b style={{ color: "#616161" }}>{title}</b>) : (title)} />)
            })}
          </Tabs>
      </div>

      {!isOnCVGTab ? (
        <CheckBoxTreeContainer
          dataType={props?.dataType}
          toggleNavigation={toggleNavigation}
          isOnRegionalTab={isOnRegionalTab}
          setToggleNavigation={setToggleNavigation}
          selectedTab={selectedTab}
          currentVersion={props?.currentVersion}
          kmatId={props?.kmatID}
          isTabClicked={isTabClicked}
          setTabClicked={setTabClicked}
        />
      ) : (
        <CvgTree dataType={props?.dataType} currentVersion={props?.currentVersion} kmatId={props?.kmatID} />
      )}
    </>
  );
}

export default memo(StepperPanel);
