import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import DashboardOutlined from '@mui/icons-material/DashboardOutlined';
import Dashboard from '@mui/icons-material/Dashboard';
import SouthAmerica from '@mui/icons-material/SouthAmerica';
import SouthAmericaTwoTone from '@mui/icons-material/SouthAmericaTwoTone';
import AdminPanelSettingsOutlined from '@mui/icons-material/AdminPanelSettingsOutlined';
import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings';
import FolderOpen from '@mui/icons-material/FolderOpen';
import Folder from '@mui/icons-material/Folder';
import CalendarViewMonth from '@mui/icons-material/CalendarViewMonth';
import CalendarViewMonthTwoTone from '@mui/icons-material/CalendarViewMonthTwoTone';
import ProductLogo from 'assets/icons/ProductLogo.png';
import Logo from 'assets/hp_black_logo.png';
import './PermanentDrawer.css';
import { getPlcRoles } from 'pages/Utils/OcplcUtil';

const menuItems = (userDetails) => {
    const hasRole = (role) => userDetails?.roles?.some(r => r.id === role);
    const ocplcRoles = getPlcRoles(userDetails?.roles || []);

    return [
        {
            showItem: hasRole('standard'),
            icon: <DashboardOutlined sx={{ fontSize: "28px" }} />,
            activeIcon: <Dashboard sx={{ fontSize: "28px" }} />,
            label: 'Dashboard',
            path: 'dashboard',
        },
        {
            showItem: hasRole('regional'),
            icon: <SouthAmerica sx={{ fontSize: "28px" }} />,
            activeIcon: <SouthAmericaTwoTone sx={{ fontSize: "28px" }} />,
            label: 'Regional Dashboard',
            path: 'regional',
        },
        {
            showItem: hasRole('admin'),
            icon: <AdminPanelSettingsOutlined sx={{ fontSize: "28px" }} />,
            activeIcon: <AdminPanelSettings sx={{ fontSize: "28px" }} />,
            label: 'Admin Panel',
            path: 'admin/users',
        },
        {
            showItem: hasRole('pcx_files'),
            icon: <FolderOpen sx={{ fontSize: "28px" }} />,
            activeIcon: <Folder sx={{ fontSize: "28px" }} />,
            label: 'PCX Files',
            path: 'files',
        },
        {
            showItem: hasRole('btp'),
            icon: <CalendarViewMonth sx={{ fontSize: "28px" }} />,
            activeIcon: <CalendarViewMonthTwoTone sx={{ fontSize: "28px" }} />,
            label: 'BTP',
            path: 'btp',
        },
        {
            showItem: ocplcRoles.length > 0,
            icon: <DashboardOutlined sx={{ fontSize: "28px" }} />,
            activeIcon: <Dashboard sx={{ fontSize: "28px" }} />,
            label: 'OCPLC',
            path: 'ocplc',
        }
    ];
}

const PermanentDrawer = (props) => {
    const navigate = useNavigate();
    const currentLocation = useLocation();

    return currentLocation.pathname !== '/' && currentLocation.pathname !== '/login' && (
        <Drawer classes={{ paper: 'permanent-drawer-paper' }} variant='permanent' anchor='left'>
            <Toolbar className='drawer-toolbar'><img src={ProductLogo} alt='logo' height={49} /></Toolbar>
            <Divider />
            <List className="paper-list">
                {menuItems(props.userDetails).map(({ icon, activeIcon, label, path, showItem }) => (showItem ?
                    <ListItem key={path} className='paper-list-item' onClick={() => navigate(`/${path}`)}>
                        <IconButton className={`${currentLocation.pathname.includes(path) ? 'currently-active' : ''}`} sx={{ borderRadius: '50px' }}>
                            {currentLocation.pathname.includes(path) ? activeIcon : icon}
                        </IconButton>
                        <Typography variant='subtitle2' className='paper-list-item-text' >{label}</Typography>
                    </ListItem>
                    : null)
                )}
            </List>
            <img src={Logo} className='paper-list-logo' alt='hp-logo' height={37} width={37} />
        </Drawer>
    );
}

export default PermanentDrawer;