import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from '@mui/material/InputAdornment';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';

export const useStyles = makeStyles((theme) => ({
  divContainer: {
    marginTop: "15px",
    marginBottom: "12px",
    display: "flex",
  },
  headerChildren: {
    marginRight: "20px",
  },
  checkIcon: {
    color: 'green',
    margin: "auto",
    marginRight: 0
  },
  regionSelect: {
    borderRadius: '10px !important',
    height: '2.6em',
    width: '240px',
    '& .MuiSelect-select': {
      marginTop: '10px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  paperProps:{
    scrollbarWidth: 'auto',
   },
  regionInputLabel: {
    marginTop: '-7px !important',
    fontSize: '12px',
    marginLeft: '6px',
  },
  regionTextField: {
    width: '250px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px !important',
      height: '2.9em',
    },
    '& .MuiInputBase-input': {
      marginTop: '10px',
    },
  },
  popOver: {
    "& .MuiPopover-paper": {
      padding: "8px 0px",
      textAlign: "center",
      minWidth: theme.spacing(20),
    },
  },
  moreIcon: {
    borderRadius: "10px",
    borderColor: "#0278AB",
    height: "35px",
    marginLeft: "10px",
    marginBottom: "5px",
  },
  textField: {
    '& .MuiInputBase-inputMultiline': { padding: "0px !important" }
  }
}));


export const pageSizeOptions = [10, 20, 30];

export const getRowClassName = (params) => {
  return `${params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'} clickable`;
};

export const boxStyles = { minWidth: '100%',maxWidth: '700px'};

export const paginationContainerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%'
};

export const rowsPerPageContainerStyles = {
  display: 'flex',
  alignItems: 'center'
};

export const typographyStyles = {
  marginLeft: 30,
  fontSize: '14px'
};

export const selectStyles = {
  marginLeft: 12,
  fontSize: '14px',
  width: 80
};

export const spanStyles = {
  marginLeft: 12
};

export const iconButtonStyles = (isChecked) => ({
  outline: isChecked ? '1px solid #0278AB' : '1px solid grey',
  borderRadius: '6px',
  padding: '2px 4px'
});

export const headerCheckboxStyles = { marginTop: "25px" };


export const dataTransportSelectStyles = {
  width: '200px',
  borderRadius: '6px',
  color: '#1976d2',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#1976d2',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#1976d2',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#1976d2',
  }
};
export const formControlStyles = {
  width: '200px',
};

export const noDataFoundStyles = {
  padding: '20px',
  textAlign: 'center',
  borderTop: '1px solid #959595',
  borderBottom: '1px solid #959595'
};

export const commonMenuProps = {
  PaperProps: {
      style: {
          maxHeight: 600,
          maxWidth: 280,
      },
  }
};

export const actionButtonsStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '10px'
};

export const confirmationDialogStyles = {
  backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  paper: {
      boxShadow: 'none',
  },
};

export const renderOcplcSelectHeader = (colData, filters, label, handleChange, items = []) => {
  const productLineFilter = filters[colData?.name]?.value;

  return (
    <div className='header-children'>
      <Typography variant='subtitle2' sx={{ marginBottom: "5px" }}> {label} </Typography>
      <FormControl fullWidth>
        {!productLineFilter && (
          <InputLabel disableAnimation shrink={false} focused={false} className='item-type-label-header'>  Select  </InputLabel>
        )}

        <Select
          value={productLineFilter || ''}
          onChange={(e) => handleChange(colData, e.target.value)}
          className='select-field'
          onBlur={(e) => e.stopPropagation()}
          MenuProps={commonMenuProps}
          endAdornment={productLineFilter && (
            <InputAdornment position="start" style={{ cursor: 'pointer' }}>
              <CloseIcon
                className='clear-filter'
                onClick={(e) => {
                  e.stopPropagation();
                  handleChange(colData, '')
                }}
              />
            </InputAdornment>
          )}
        >
          {items.length > 0 ? (
            items.map((item, index) => {
              const value = item.value || item.countryCode || item?.code;
              const labelText = colData?.name === 'businessModel' ? `${item.value}  | ${item.text}` : item.text || `${item?.countryCode || item?.code || 'N/A'}`;
              return (
                <MenuItem key={index} value={value}>
                  {labelText}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem disabled>No items available</MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
};