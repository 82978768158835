import React, { useState, useEffect, useCallback } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import DialogActions from '@mui/material/DialogActions';
import { RadioButton } from "pages/EditModel/ChoiceEdit/ChoiceEditHelpers";
import "./Dashboard.scss";
import { updateModel } from 'actions/dashboardActions';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import { getInputProps } from 'pages/DashboardHelpers';


const UpdateModelDialog = ({ rowDetails, open, onClose, setIsBtnMenuOpen }) => {
    const dispatch = useDispatch();

    const [productLineForUpdModel, setProductLineForUpdModel] = useState(null);
    const [productNameForUpdModel, setProductNameForUpdModel] = useState(null);
    const [usersForUpdModel, setUsersForUpdModel] = useState(null);
    const [isRelease, setIsRelease] = useState(false);
    const [inputValue, setInputValue] = useState(null);
    const [assignEditor, setAssignEditor] = useState(null)
    const { productLines, users } = useSelector(state => state.dashboard.role !== null && state.dashboard);

    useEffect(() => {
        if (rowDetails?.productLineID) setProductLineForUpdModel(rowDetails?.productLineID);
        if (rowDetails?.isReleased !== '') setIsRelease(rowDetails?.isReleased);
        setProductNameForUpdModel(rowDetails?.name || '');
        setUsersForUpdModel(rowDetails?.assignedToName || '');
        setAssignEditor(rowDetails?.assignedToName && { firstName: rowDetails?.assignedToName || "", lastName: "" });
        setUsersForUpdModel(rowDetails?.assignedToEmail || '');
    }, [open, rowDetails]);

    const handleUpdateModel = useCallback(() => {
        dispatch(updateModel(rowDetails, productLineForUpdModel, productNameForUpdModel, usersForUpdModel, isRelease));
        onClose();
        setIsBtnMenuOpen({ anchorEl: null, state: false });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, rowDetails, productLineForUpdModel, productNameForUpdModel, usersForUpdModel, isRelease]);

    const handleIsReleaseChange = (event) => {
        setIsRelease(event.target.value === 'true');
    };

    const handleUsersForUpdModelChange = useCallback((isFromAutocomplete, e) => {
        setAssignEditor(e || null)
        setUsersForUpdModel(isFromAutocomplete ? e?.email || "" : e?.target?.value);
    }, []);

    return (
        <Dialog open={open} onClose={onClose} className='custom-dialog'>
            <DialogTitle> {"Update Model Details"}</DialogTitle>
            <DialogContent>
                <Typography variant="h6" className='sub-header'>Product Details</Typography>
                <FormControl fullWidth >
                    {productLineForUpdModel === 'EMPTY' && (
                        <InputLabel disableAnimation shrink={false} focused={false} sx={{ textAlign: 'left', marginTop: "2px" }}> &nbsp; Product Line </InputLabel>
                    )}
                    <Select value={productLineForUpdModel} onChange={(e) => setProductLineForUpdModel(e.target.value)} className='product-select' >
                        {productLines?.map(eachLine => (
                            <MenuItem key={eachLine.id} value={eachLine.id}>
                                {eachLine.name} &nbsp; <Chip label={eachLine.count} size='small' sx={{ marginLeft: "auto" }} />
                            </MenuItem>
                        ))}
                    </Select>
                    <TextField
                        fullWidth
                        placeholder="Manufacturing Name"
                        variant="outlined"
                        slotProps={{ input: getInputProps() }}
                        value={productNameForUpdModel}
                        onChange={(e) => { setProductNameForUpdModel(e.target.value) }}
                    />
                </FormControl>
                <Typography variant="h6" className='sub-header'>Assign Editor</Typography>
                <FormControl fullWidth>
                    <Autocomplete
                        options={users}
                        id="auto-complete"
                        autoComplete
                        value={assignEditor || ""}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => { setInputValue(newInputValue) }}
                        getOptionLabel={(option) => option ? option.firstName + " " + option.lastName : ""}
                        onChange={(event, newValue) => { handleUsersForUpdModelChange(true, newValue) }}
                        renderInput={(params) => (<TextField {...params} slotProps={{ input: { ...params.InputProps, sx: { borderRadius: "12px" } } }} placeholder='Not Assigned' variant="outlined" />)}
                    />
                </FormControl>
                <Typography variant="h6" className='sub-header'>Is Released</Typography>
                <RadioGroup row name="row-radio-buttons-group" value={isRelease} onChange={handleIsReleaseChange} className='radio-group'>
                    <FormControlLabel value={true} control={<RadioButton />} label="Yes" />
                    <FormControlLabel value={false} control={<RadioButton />} label="No" />
                </RadioGroup>
            </DialogContent>
            <DialogActions className='dialog-actions'>
                <Button variant="outlined" className='cancel-button' onClick={onClose} autoFocus>
                    Cancel
                </Button>
                <Button variant="outlined" className='add-Button' onClick={(e) => handleUpdateModel(e)}>
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UpdateModelDialog;
