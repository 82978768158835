import React, { useState } from "react";
import { FormControl, FormControlLabel, TextField, RadioGroup, Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { CustomTooltip, RadioButton } from "../EditModel/ChoiceEdit/ChoiceEditHelpers";
import StatusChip from "../EditModel/ChoiceEdit/StatusChip";
import { useStyles } from "pages/EditModel/Tabs/styles";

const RegionalChoice = ({ data }) => {
  const classes = useStyles();
  const statusChip = data?.status && JSON.parse(data.status)?.status;
  const [choiceData, setChoiceData] = useState(data);

  const handleOnChange = (event, field) => {
    let newVal = event.target.value;

    setChoiceData((prevData) => ({
      ...prevData,
      [field]: newVal,
      edited: true
    }));
  };
  return !choiceData || choiceData?.dataType === "SYS" ? null : (
    <>
      <Grid container className={classes.root}>
      <form className={classes.form} >
        <Grid container>
          <Grid size={6}>
            <TextField
              disabled
              variant="filled"
              margin="dense"
              slotProps={{ input : {disableUnderline: true }}}
              id="char-id"
              label="ID"
              value={choiceData.choiceID}
            />
          </Grid>
          <Grid size={6}>
            <TextField
              disabled
              variant="filled"
              margin="dense"
              slotProps={{ input : {disableUnderline: true }}}
              id="char-desc"
              label="Description"
              value={choiceData.description}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.choiceSelection}>
          <Grid size={6}>
            <FormControl className={classes.formControl}>
              <Typography className={classes.selectionType}>
                Selection Type
              </Typography>
              <RadioGroup
                aria-label="selectionType"
                name="customized-radios"
                style={{ display: "inline" }}
                value={choiceData.selectionType}
              >
                <FormControlLabel
                  disabled
                  control={<RadioButton />}
                  value="Single"
                  label="Single"
                />
                <FormControlLabel
                  disabled
                  control={<RadioButton />}
                  value="Multiple"
                  label="Multiple"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {choiceData?.selectionType !== "Counter" && (
            <Grid size={6}>
              <FormControl className={classes.formControl}>
                <Typography className={classes.selectionType}>
                  Is Required?
                </Typography>
                <RadioGroup
                  aria-label="isRequired"
                  name="customized-radios"
                  style={{ display: "inline" }}
                  value={choiceData?.isRequired}
                  onChange={(e) => handleOnChange(e, "isRequired")}
                >
                  <FormControlLabel
                    disabled
                    control={<RadioButton />}
                    value={true}
                    label="Yes"
                  />
                  <FormControlLabel
                    disabled
                    control={<RadioButton />}
                    value={false}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
        </Grid>
        <Box className={classes.subtitle}>
          {" "}
          CVGs &nbsp;
          {statusChip === "updated" && (
            <CustomTooltip
              placement="top"
              title={<StatusChip data={choiceData} />}
            >
              <span className={classes.status}>
                {statusChip.charAt(0).toUpperCase() + statusChip.slice(1)}
              </span>
            </CustomTooltip>
          )}
        </Box>
        <Grid container>
          <Grid size={12}>
            <TextField
              disabled
              variant="filled"
              margin="dense"
              multiline
              slotProps={{
                input: { 
                classes: { input: classes.textarea },
                disableUnderline: true,
                }
              }}
              id="allowedCVGs"
              label="Allowed CVGs"
              value={choiceData.allowedCVGs}
            />
          </Grid>
        </Grid>
        <Typography className={classes.subtitle}> Conditions</Typography>
        <Grid container>
          <Grid size={6}>
            <TextField
              id="preConCode"
              disabled
              variant="filled"
              margin="dense"
              multiline
              slotProps={{
                input: {
                classes: { input: classes.textarea },
                disableUnderline: true,
                }
              }}
              name="displayCondition"
              label="Display Condition"
              value={choiceData && choiceData.displayCondition}
            />
          </Grid>
          <Grid size={6}>
            <TextField
              id="selConCode-input"
              disabled
              variant="filled"
              margin="dense"
              multiline
              slotProps={{
                input: {
                classes: { input: classes.textarea },
                disableUnderline: true,
                }
              }}
              name="requiredCondition"
              label="Required Condition"
              value={choiceData && choiceData.requiredCondition}
            />
          </Grid>
        </Grid>
        </form>
      </Grid>
    </>
  );
};

export default RegionalChoice;
