import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { USER_LOGIN_FAIL } from 'constants/userConstants';
import { Box } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

const LoginFail = () => {
    const dispatch = useDispatch();
    const { error } = useSelector((state) => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch({ type: USER_LOGIN_FAIL, payload: error || 'Login failed.' })
        navigate('/login', { loginError: 'Error signing in, please try again' })
    }, [dispatch, error, navigate]);

    return (
        <div>
            <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></Box>
        </div>
    )
};

export default LoginFail;
