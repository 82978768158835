import React, { useEffect } from "react";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { receiveAccessToken } from "actions/userActions";
import { useReroute } from 'pages/Utils/RerouteUtil';
import { Box } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

const LoginOk = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo, kbUser, loading, error } = useSelector((state) => state.user);
  const accessToken = Cookies.get("access_token");
  const expires = Cookies.get("expires");
  const reroute = useReroute(navigate);

  useEffect(() => {
    dispatch(receiveAccessToken(accessToken, expires))
  }, [accessToken, expires, dispatch,navigate]);

  useEffect(() => {
    if (!loading && userInfo && kbUser) {
        const role = kbUser?.role || kbUser?.ocplcRole;
        if (role) {
            reroute(role);
        }
    }
}, [userInfo, kbUser, reroute, loading]);

  useEffect(() => {
    if (error) {
      navigate('/login', { loginError: error })
    }
  }, [error, navigate])

  return (
    <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}><CircularProgress /></Box>
  );
};

export default LoginOk;