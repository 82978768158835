import React from 'react';

/**
 * useKeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 * @param {boolean} shouldEnable - flag to enable/disable the key press handling
 */
const useKeypress = (keyActions, shouldEnable) => {
  
  React.useEffect(() => {
    const handleKeyDown = (e) => {
      const charCode = String.fromCharCode(e.which).toLowerCase();

      Object.keys(keyActions).forEach((key) => {
        if (!shouldEnable) {
          if ((e.ctrlKey || e.metaKey) && (charCode === key) && e.shiftKey)
            e.preventDefault();
          return;
        }
        const isCtrlShiftKey = (e.ctrlKey || e.metaKey) && e.shiftKey && charCode === key;
        const isOtherKey = (key === "b" || key === "s") && (e.ctrlKey || e.metaKey) && charCode === key;
        const isEscapeKey = key === "Escape" && e.key === "Escape";
        const isArrowUpandDown = (key === "ArrowUp" || key === "ArrowDown" || key === "Enter") && (e.ctrlKey || e.metaKey) && (e.key === key);
        if (isCtrlShiftKey || isOtherKey || isEscapeKey || isArrowUpandDown) {
          e.preventDefault();
          keyActions[key]();
        }
      });
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => { document.removeEventListener('keydown', handleKeyDown) }
  }, [shouldEnable, keyActions]);
}

export default useKeypress;