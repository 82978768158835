import React from 'react';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { TOF_AUDIT_TRAIL } from 'constants/ocplcConstants';

const tofColumns = [
    { field: 'action', headerName: 'Action', width: 100, sortable: false },
    { field: 'region', headerName: 'Region', width: 80, sortable: false },
    { field: 'country', headerName: 'Country', width: 80, sortable: false },
    { field: 'productFamily', headerName: 'Product Family', width: 150, sortable: false },
    { field: 'businessModel', headerName: 'Business Model', width: 150, sortable: false },
    { field: 'productGroup', headerName: 'Product Group', width: 150, sortable: false },
    { field: 'programType', headerName: 'Program Type', width: 150, sortable: false },
    { field: 'programs', headerName: 'Programs', width: 100, sortable: false },
    { field: 'startDate', headerName: 'Start Date', width: 250, sortable: false },
    { field: 'endDate', headerName: 'End Date', width: 250, sortable: false },
    { field: 'comments', headerName: 'Comments', width: 300, sortable: false },
    { field: 'whoChange', headerName: 'Who Changed', width: 200, sortable: false },
    { field: 'createDate', headerName: 'Create Date', width: 250, sortable: false },
    { field: 'lastModifiedDate', headerName: 'Last Modified Date', width: 250, sortable: false },

];

const modelColumns = [
    ...tofColumns.slice(0, 6),
    { field: 'status', headerName: 'Status', width: 80, sortable: false },
    { field: 'ap', headerName: 'AP', width: 80, sortable: false },
    { field: 'la', headerName: 'LA', width: 80, sortable: false },
    { field: 'eu', headerName: 'EU', width: 80, sortable: false },
    { field: 'na', headerName: 'NA', width: 80, sortable: false },
    ...tofColumns.slice(8)
];

const FeedDialog = (props) => {
    const dispatch = useDispatch();
    const { open, setOpenFeedDialog, feedRowData } = props;
    const modelAuditTOFTrial = useSelector(state => state.ocplc.modelAuditTOFTrial);

    const getColumns = () => feedRowData === "modelOfferAudit" ? modelColumns : tofColumns;

    const handleCloseFeedDialog = () => {
        setOpenFeedDialog(false);
        dispatch({ type: TOF_AUDIT_TRAIL, payload: [] });
    }
    return (
        <Paper>
            <Dialog open={open} onClose={handleCloseFeedDialog} maxWidth={false}
                sx={{
                    '& .MuiDialog-paper': {
                        width: '100%',
                        maxWidth: 'none',
                        borderRadius: '12px'
                    }
                }}>
                <DialogTitle>{feedRowData === "modelOfferAudit" ? "Audit Trial - Model" : "Marketing Program Audit Trial"}</DialogTitle>
                <DialogContent>
                    <Box sx={{ height: 600, width: '100%' }}>
                        <DataGrid
                            rows={modelAuditTOFTrial?.map((row, index) => ({ id: index, ...row }))}
                            columns={getColumns()}
                            rowHeight={40}
                            disableSelectionOnClick
                            columnHeaderHeight={50}
                            hideFooterPagination={true}
                            hideFooter={true}
                            sx={{ '& .MuiDataGrid-columnHeaderTitleContainer': { display: 'flex' }, "& .MuiDataGrid-columnSeparator": { display: "none" }, }}
                            disableColumnFilter
                            disableColumnMenu
                            getRowClassName={(params) => `${params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'}`}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleCloseFeedDialog} className='cancel-button'>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

export default FeedDialog;