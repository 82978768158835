import React, { useState } from "react";
import ConfirmationDialog from "components/ConfirmationDialog";

const DisabledDeleteOptionForChoice = (props) => {
  const [openDelete, setOpenDelete] = useState(true);
  const deleteItem = props.choiceID?.replace("-", " ");

  const handleDeleteClose = () => {
    setOpenDelete(false);
    props.setDeleteDialogOpen(false);
  };

  return (
    <>
      {openDelete && (
        <ConfirmationDialog
          open={openDelete}
          title="Delete Choice"
          content={<>Before deleting choice <b> '{deleteItem}' </b>, Kindly remove items associated to this choice</>}
          onClose={handleDeleteClose}
          bgColor="#BE1313"
        />)}
    </>
  );
};

export default DisabledDeleteOptionForChoice;
