import React, { useEffect, useState, useRef } from 'react';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { RadioButton } from "../ChoiceEdit/ChoiceEditHelpers";
import ErrorToolTip from "../../Common/ErrorToolTip";
import StatusChip from "../ChoiceEdit/StatusChip";
import { useGlobalStore } from 'hooks/useGlobalStore';
import { updateChoiceItemFields } from 'actions/modelActions';
import { useAutoFocus } from '../IndexHelpers';
import { useStyles } from './styles';

export const ChoiceSettings = (props) => {
  const classes = useStyles();
  const [displayConditionClass, setDisplayConditionClass] = useState(false);
  const [choiceData, setChoiceData] = useGlobalStore(props?.data, updateChoiceItemFields, { choiceID: props?.choiceID, tabCategory: props?.tabName });
  const invalidDisplayConditions = props?.syntaxCheck?.DisplayCondition;
  const invalidRequiredConditions = props?.syntaxCheck?.RequiredCondition;

  useEffect(() => {
    if (choiceData?.selectionType === "Counter" || (choiceData?.isRequired === true && choiceData?.selectionType === "Single")) {
      setDisplayConditionClass(true)
    } else {
      setDisplayConditionClass(false)
    }
  }, [choiceData?.selectionType, choiceData?.isRequired]);

  const handleOnChange = (event, field) => {
    if (event.target?.value?.startsWith('\n')) 
      return;
    let newVal = event.target.value;
    newVal = newVal ==="true" ? true : newVal ==="false" ? false : newVal;

    setChoiceData((prevData) => ({
      ...prevData,
      [field]: newVal,
      edited: true
    }));
  };

  const textFieldRef = useRef(null);
  useAutoFocus(textFieldRef);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Grid container className={classes.root}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid container>
          <Grid size={6}>
            <TextField
              disabled
              variant="filled"
              margin="dense"
              slotProps={{ input: { disableUnderline: true } }}
              id="char-id"
              label="ID"
              value={choiceData?.selectionType === "Counter" ? "" : choiceData?.choiceID || ""}
            />
          </Grid>
          <Grid size={6}>
            <TextField
              disabled
              variant="filled"
              margin="dense"
              slotProps={{ input: { disableUnderline: true } }}
              id="char-desc"
              label="Description"
              value={choiceData?.selectionType === "Counter" ? "" : choiceData?.description || ""}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.choiceSelection}>
          <Grid size={6}>
            <FormControl className={classes.formControl}>
              <Typography className={classes.selectionType}>Selection Type</Typography>
              <RadioGroup
                aria-label="selectionType"
                name="customized-radios"
                style={{ display: "inline" }}
                value={choiceData?.selectionType ?? ''}
                onChange={e => handleOnChange(e, 'selectionType')}
              >
                <FormControlLabel
                  control={<RadioButton />}
                  value="Single"
                  label="Single"
                />
                <FormControlLabel
                  control={<RadioButton />}
                  value="Multiple"
                  label="Multiple"
                  disabled={choiceData?.dataType === "NUM"}
                />
                <FormControlLabel
                  control={<RadioButton />}
                  value="Counter"
                  label="Counter"
                  disabled={choiceData?.dataType === "CHAR"}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {choiceData?.selectionType !== "Counter" && (
            <Grid size={6}>
              <FormControl className={classes.formControl}>
                <Typography className={classes.selectionType}>Is Required?</Typography>
                <RadioGroup
                  aria-label="isRequired"
                  name="customized-radios"
                  style={{ display: "inline" }}
                  value={choiceData?.isRequired ?? ''}
                  onChange={e => handleOnChange(e, 'isRequired')}
                >
                  <FormControlLabel
                    control={<RadioButton />}
                    value={true}
                    label="Yes"
                  />
                  <FormControlLabel
                    control={<RadioButton />}
                    value={false}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
          {choiceData?.selectionType === "Counter" && (
            <Grid size={6}>
              <FormControl>
                <span className={classes.numBoxHolder}>
                  <TextField
                    label="Range"
                    variant="filled"
                    margin="dense"
                    slotProps={{ input: { disableUnderline: true } }}
                    value={choiceData?.dataRange}
                    onChange={e => handleOnChange(e, 'dataRange')}
                  />
                  <TextField
                    label="Default"
                    variant="filled"
                    margin="dense"
                    slotProps={{ input: { disableUnderline: true } }}
                    value={choiceData?.defaultValue}
                    onChange={e => handleOnChange(e, 'defaultValue')}
                  />
                  <TextField
                    label="Size"
                    variant="filled"
                    margin="dense"
                    slotProps={{ input: { disableUnderline: true } }}
                    value={choiceData?.dataLength}
                    onChange={e => handleOnChange(e, 'dataLength')}
                  />
                </span>
              </FormControl>
            </Grid>
          )}
        </Grid>
        <div className={classes.subtitle}> Config Rules & CVGs <StatusChip data={choiceData} /></div>
        <Grid container direction="column">
          <Grid item>
            <TextField
              disabled
              variant="filled"
              margin="dense"
              id="selConCode-hint"
              placeholder="Config Rules"
              label="Config Rules"
              value={choiceData?.configRules}
              multiline
              slotProps={{ input: { classes: { input: classes.textarea }, disableUnderline: true } }}
            />
          </Grid>
          <Grid item>
            <TextField
              disabled
              variant="filled"
              margin="dense"
              id="allowedCVGs"
              label="Allowed CVGs"
              placeholder="Allowed CVGs"
              value={choiceData?.allowedCVGs}
              multiline
              slotProps={{
                input: {
                  classes: { input: classes.textarea },
                  disableUnderline: true
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid size={6}>
          <Typography className={classes.subtitle}> Display Condition</Typography>
            <TextField
              inputRef={textFieldRef}
              id="preConCode"
              disabled={displayConditionClass}
              variant="filled"
              margin="dense"
              multiline
              slotProps={{
                input: {
                  classes: { input: classes.textarea },
                  disableUnderline: true
                }
              }}
              name="displayCondition"
              label="Display Condition"
              // placeholder="Display Condition"
              value={choiceData?.displayCondition}
              onChange={e => handleOnChange(e, 'displayCondition')}
              autoFocus={!displayConditionClass}
            />
            {invalidDisplayConditions?.length > 0 && (<ErrorToolTip data={invalidDisplayConditions} />)}
          </Grid>
          <Grid size={6}>
            <Typography className={classes.subtitle}>Required Condition</Typography>
            <TextField
              id="selConCode-input"
              disabled={displayConditionClass}
              variant="filled"
              margin="dense"
              multiline
              slotProps={{
                input: {
                  classes: { input: classes.textarea },
                  disableUnderline: true
                }
              }}
              onChange={e => handleOnChange(e, 'requiredCondition')}
              name="requiredCondition"
              label="Required Condition"
              // placeholder="Required Condition"
              value={choiceData?.requiredCondition}
            />
            {invalidRequiredConditions?.length > 0 && (<ErrorToolTip data={invalidRequiredConditions} />)}
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default ChoiceSettings;
