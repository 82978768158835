import React, { useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography  from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import { dataGridStyles } from '../DashboardHelpers';
import './Dashboard.scss';
import { importModel } from 'actions/dashboardActions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

const ImportModelDialog = ({ open, onClose, importModelColumns, rowDetails }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const memoizedColumns = useMemo(() => {
        return importModelColumns((importDetails) => {
            dispatch(importModel(importDetails.recordID, rowDetails.kmatID, navigate));
        });
    }, [rowDetails.kmatID]); 

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{ className: 'custom-dialog-paper' }}>
            <DialogTitle variant='h5'>{"Model Updates"}
                <Typography variant='subtitle2' sx={{ marginTop: "20px" }}> Select a file to Import.</Typography>
            </DialogTitle>
            <DialogContent sx={{ height: "260px" }}>
                {open && (
                    <DataGrid
                        columns={memoizedColumns}
                        rows={rowDetails.imports}
                        getRowId={(row) => row.fileDate}
                        loading={!rowDetails.imports}
                        rowHeight={49}
                        columnHeaderHeight={35}
                        className='dashboard-data-grid'
                        disableSelectionOnClick
                        getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'}
                        headerClassName='dashboard-grid-headers'
                        sx={dataGridStyles}
                        hideFooter
                        disableColumnFilter
                        disableColumnMenu
                    />
                )}
            </DialogContent>
            <DialogActions className='dialog-actions'>
                <Button variant="outlined" className='update-model-buttons' onClick={onClose} autoFocus>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImportModelDialog;