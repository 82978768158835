import React, { useEffect, useState } from 'react';
import BtpDashboard from 'pages/Btp/index';
import BtpEnvironment from './BtpEnv';
import { btpPanel } from './DashboardHelpers';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { getBtpStatus, refreshBtps } from 'actions/btpAction';
import { useDispatch, useSelector } from 'react-redux';
import { GraphqlApi, GraphqlApiSecondary } from 'services/api';
import { REACT_APP_ENVIRONMENT, REACT_APP_SECONDARY_ENVIRONMENT } from 'constants/userConstants';

const BtpPanel = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(REACT_APP_ENVIRONMENT);
  const { getBTPStatus, getBtpCacheInfo } = useSelector(state => state.btps);
  const Api = activeTab === REACT_APP_ENVIRONMENT ? GraphqlApi : GraphqlApiSecondary;
  const filteredKmats = getBTPStatus?.isRunning ? getBtpCacheInfo.filter(item => item.value === 'Y').map(item => item.key) : [];
  const [checkedRows, setCheckedRows] = useState({ [REACT_APP_ENVIRONMENT]: [], [REACT_APP_SECONDARY_ENVIRONMENT]: [] });

  useEffect(() => {
    setCheckedRows({ [activeTab]: filteredKmats });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBTPStatus, getBtpCacheInfo]);

  const btpTabs = [
    { label: REACT_APP_ENVIRONMENT, value: REACT_APP_ENVIRONMENT, graphqlApi: GraphqlApi },
    { label: REACT_APP_SECONDARY_ENVIRONMENT, value: REACT_APP_SECONDARY_ENVIRONMENT, graphqlApi: GraphqlApiSecondary },
  ];

  const handleCheckboxChange = (rows) => {
    setCheckedRows({ [activeTab]: rows });
  };

  const handleTabClick = (tabValue) => {
    setActiveTab(tabValue);
    setCheckedRows({ [tabValue]: [] });
    dispatch(
      getBtpStatus(btpTabs.find((tab) => tab.value === tabValue).graphqlApi)
    );
    dispatch(
      refreshBtps(btpTabs.find((tab) => tab.value === tabValue).graphqlApi)
    );
  };

  return (
    <>
      {REACT_APP_SECONDARY_ENVIRONMENT && (
        <Tabs value={activeTab} sx={{ borderBottom: '.5px solid #ADADAD', width: '150px', marginTop: '10px' }}>
          {btpTabs.map(({ label, value }) => (
            <Tab key={value} label={label} value={value} onClick={() => handleTabClick(value)} style={{ marginRight: '2rem' }} />
          ))}
        </Tabs>
      )}
      <Box sx={btpPanel}>
        <div style={{ flex: 1.1 }}>
          <BtpDashboard checkedRows={checkedRows[activeTab]} onCheckboxChange={handleCheckboxChange} Api={Api} />
        </div>
        <div style={{ flex: 1 }}>
          <BtpEnvironment checkedRows={checkedRows[activeTab]} Api={Api} activeTab={activeTab} />
        </div>
      </Box>
    </>
  );
};

export default BtpPanel;
