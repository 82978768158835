import { importModel } from "./importModel"
import { syntaxCheck } from "./syntaxCheck"
import { syntaxCheckRegional } from "./syntaxCheckRegional"
import { createDraft } from "./createDraft"
import { renewToken } from "./renewToken";
import { exportData } from "./exportData";
import { exportRegionalData } from "./exportRegionalData";
import { publishDraft } from "./publishDraft";
import { publishRegion } from "./publishRegion";
import { updateItems } from "./updateItems";
import { updateRegionalItem } from "./updateRegionalItem";
import { updateChoices } from "./updateChoices"
import { createChoiceNew } from "./createChoice"
import { createItem } from "./createItem"
import { createRegionalItem } from "./createRegionalItem"
import { getUsers } from './getUsers'
import { createKbUser } from './createKbUser'
import { deleteKbUser } from './deleteKbUser'
import { updateKbUser } from './updateKbUser'
import { dashboard } from './dashboard'
import { fetchModel } from './fetchModel'
import { updateModel } from './updateModel'
import { importModelV2 } from './importModelV2'
import { templates } from './templates'
import { fetchTemplate } from './fetchTemplate'
import { fetchRegional } from './fetchRegional'
import { regionals } from './regionals'
import { kbUser } from './kbUser'
import { choiceSequence } from "./choiceSequence"
import { updateChoiceSequence } from "./updateChoiceSequence"
import { deleteItem } from "./deleteitem"
import { deleteRegionalItem } from "./deleteRegionalItem"
import { deleteChoice } from "./deleteChoice"
import { switchRole } from "./switchRole"
import { addFavorite, pcxDownload, pcxFiles, pcxModels, removeFavorite } from "./PCX"
import { getBtpCacheInfo, getBTPStatus, triggerBTP } from "./BTP"
import { downloadExcelFile } from "./downloadExcelFile"
import { favoriteDownload } from "./favoriteDownload"
import { startValidation } from "./startValidation"
import { ocplcDashboard } from "./ocplcDashboard"
import { ocplcTableDropDown } from "./ocplcTables";
import { modelAvailability ,otherRegional  } from "./modelAvailablity";
import { ocplcCountryDropDown } from "./ocplcCountryDropDown";
import { getOcplcBusinessModel } from "./getOcplcBusinessModel";
import { ocplcRegionDropDown } from "./ocplcRegionDropdown";
import { OcplcKmatLockingQuery } from "./OcplcKmatLockingQuery";
import { getOcplcProductFamily } from "./filterOcplcKmat";
import { productNameUpdate } from "./ProductNameUpdate";
import { deleteModelAvailability } from "./deleteOcplc";
import { ocplcModelTOFAuditTrial, ocplcModelAvailabilityAuditTrial  } from "./ocplcTOFAuditTrial";
import { updateModelAvailability } from "./ocplcUpdateModelAvailability";
import { updateTofModelAvailability } from "./ocplcUpdateModelAvailability";
import { createModelAvailability, GetProductLine } from "./addocplc";
import { completeModelAvailability, dataTransport, moduleWiseDataTransport } from "./moduleWiseDataTransport";

export const queries = {
  importModel,
  syntaxCheck,
  syntaxCheckRegional,
  createDraft,
  renewToken,
  exportData,
  exportRegionalData,
  publishDraft,
  publishRegion,
  updateItems,
  updateRegionalItem,
  updateChoices,
  createChoiceNew,
  createItem,
  createRegionalItem,
  getUsers,
  createKbUser,
  deleteKbUser,
  updateKbUser,
  dashboard,
  fetchModel,
  updateModel,
  importModelV2,
  templates,
  fetchTemplate,
  fetchRegional,
  regionals,
  kbUser,
  choiceSequence,
  updateChoiceSequence,
  deleteItem,
  deleteRegionalItem,
  deleteChoice,
  switchRole,
  addFavorite,
  pcxDownload,
  pcxFiles,
  pcxModels,
  removeFavorite,
  getBtpCacheInfo,
  getBTPStatus,
  triggerBTP,
  downloadExcelFile,
  favoriteDownload,
  startValidation,
  ocplcDashboard,
  ocplcTableDropDown,
  modelAvailability,
  ocplcCountryDropDown,
  getOcplcBusinessModel,
  otherRegional,
  ocplcRegionDropDown,
  OcplcKmatLockingQuery,
  getOcplcProductFamily,
  productNameUpdate,
  deleteModelAvailability,
  ocplcModelAvailabilityAuditTrial,
  ocplcModelTOFAuditTrial,
  updateModelAvailability,
  updateTofModelAvailability,
  createModelAvailability,
  GetProductLine,
  moduleWiseDataTransport,
  completeModelAvailability,
  dataTransport
 };
