import React, { useRef, useState, useEffect, useCallback, memo, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

//react-checkbox-tree
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import '../ReactCheckboxTree/ReactCheckboxTree.css'

import { getTab } from 'pages/Utils';

//Material UI icons
import AddIcon from '@mui/icons-material/Add';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RemoveIcon from '@mui/icons-material/Remove';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import CheckboxTreeLabel from './CheckboxTreeLabel';
import CheckboxTreePopover from './CheckboxTreePopover';
import useKeypress from 'hooks/useKeypress';
import { highlightNode } from './HighLightNode';
import {  getCurrentId,getIdFromElementsList } from 'pages/Utils/FindNodeUtil';
import { rememberProperty } from 'actions/undoActions';

function ReactCheckboxTree(props) {

    const dispatch = useDispatch();
    const {setActiveNode, setCheckedItems} = props;
    const syntaxCheck = useSelector((state) => state.models.syntaxCheck[getTab(props.selectedTab)]);
    const newlyCreatedChoiceOrItem = useSelector((state) => state.models.newlyCreatedChoiceOrItem);
    const expandActions = useSelector((state) => state.models.expandActions);

    const selectedNode = useRef();
    const elementIndex = useRef(0);
    const elementsList = useRef();

    const [nodes, setNodes] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [anchorEl, setAnchorEl] = useState(0);

    let nodesFromApi = { items: props?.datasetValues }
    let currentActiveNode = (props?.currentActiveNode?.parentId === "0" ? props?.currentActiveNode?.choiceID : props?.currentActiveNode?.itemID) || nodes[0]?.id;

    useEffect(() => {
        elementIndex.current = null;
        elementsList.current = Array.from(document.getElementsByClassName('rct-node-clickable'));
        highlightNode(currentActiveNode, elementIndex, elementsList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        dispatch(rememberProperty(props?.currentActiveNode?.choiceID));
    }, [expanded, nodes]);

    useEffect(() => {
        onExpandCollapse(props.onExpandCollapseClick);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedTab, props.onExpandCollapseClick]);

    useLayoutEffect(() => {
        onSearch(props?.searchKeyword);
    }, [props?.searchKeyword, props?.datasetValues]);

    useEffect(() => {
        if (Object.keys(syntaxCheck).length !== 0 && nodesFromApi?.items?.length > 0) {
            nodesFromApi.items = nodesFromApi.items.map(item => {
                return getUpdatedData(item);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [syntaxCheck, nodesFromApi?.items])

    useEffect(() => {
        if (!props?.searchKeyword) {
            const updatedNodes = nodesFromApi.items.map(item => getUpdatedData(item));
            setNodes(convertDataToTreeNodes({ items: updatedNodes }));
        }
    }, [syntaxCheck, props?.searchKeyword, props?.datasetValues]);
    


    useKeypress({ ArrowUp: () => handleKeyPress(-1), ArrowDown: () => handleKeyPress(1), Enter: () => handleKeyPress(0), }, nodes?.length);    

    useEffect(() => {
        if (!newlyCreatedChoiceOrItem) return;
        //Try to implement this with nodes as dependency
        let timer = setTimeout(() => {
            if (newlyCreatedChoiceOrItem?.choiceID) {
                let isNewPNExist = Boolean(newlyCreatedChoiceOrItem?.partNumber);

                //if new item is added, set its parent to expanded state.
                if (!expanded.includes(newlyCreatedChoiceOrItem?.choiceID))
                    setExpanded([...expanded, newlyCreatedChoiceOrItem?.choiceID]);

                let itemId = isNewPNExist ? (newlyCreatedChoiceOrItem?.partNumber + '-' + newlyCreatedChoiceOrItem.choiceID + '-' + newlyCreatedChoiceOrItem?.tabCategory).toUpperCase() : '';
                let elementId = itemId || newlyCreatedChoiceOrItem?.choiceID;
                highlightNode(elementId, elementIndex, elementsList);
                // dispatch({ type: "SELECTED_ITEM", payload: elementId });
                dispatch({ type: "REMEMBER_TREE_EXPANSION", payload: [newlyCreatedChoiceOrItem?.choiceID] });
            }
        })
        return () => clearTimeout(timer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newlyCreatedChoiceOrItem?.choiceID, newlyCreatedChoiceOrItem.partNumber])

    //Refactor
    function getUpdatedData(data) {
        let updatedItems = data.items && Object.keys(data.items).length > 0 ? data.items : {};
        let hasChoiceError = false;
        let choiceInvalidConditions = syntaxCheck[data.choiceID];
        hasChoiceError = props.dataType === "model" && Boolean(choiceInvalidConditions);

        updatedItems = Object.entries(updatedItems).reduce((acc, [key, child]) => {
            const hasItemError = Boolean(choiceInvalidConditions?.items[child.itemID]);
            acc[key] = { ...child, isError: hasItemError };
            return acc;
        }, {});

        return { ...data, isError: hasChoiceError, items: updatedItems };
    };
    const focusNode = (container) => {
        container.tabIndex = 0;
        container.focus();
    }
    const highlightNextElement = (element) => {
        if (element !== undefined) focusNode(element);
    }
    const handleKeyPress = useCallback((navigate) => {
        const newIndex = elementIndex.current + navigate;
        if (newIndex < 0) return;
        const { currentId,currentElement } = getCurrentId({ newIndex, elementIndex, elementsList });
        if (newIndex >= 0 && newIndex < elementsList.current.length && navigate !== 0) {
            getIdFromElementsList({ currentId, nodesFromApi, selectedNode, announceSelection });
            highlightNextElement(currentElement);

        }
        else if (navigate === 0) {
            const isCurrentlyExpanded = expanded.includes(currentId);
            const newExpanded = isCurrentlyExpanded
                ? expanded.filter(id => id !== currentId)
                : [...expanded, currentId];
            setExpanded(newExpanded);
            dispatch({ type: "REMEMBER_TREE_EXPANSION", payload: newExpanded });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expanded]);

    const convertDataToTreeNodes = (nodes) => (
        nodes?.items?.map(node => convertDataToTreeNode(node)) || []
    );

    const convertDataToTreeNode = (node, parentNode) => {
        let count = node.items && Object.keys(node.items).length;
        let text = node.choiceID || node.itemID;

        let treeNode = {
            id: text,
            value: text,
            key: text,
            disabled: !(Boolean(count) || Boolean(parentNode)),
            className: props.dataType === "regional" ? "hide-checkboxes" : "",
            label: <CheckboxTreeLabel node={node} count={count} onMoreIconClick={onMoreIconClick} choiceData={parentNode} announceSelection={announceSelection} currentVersion={props.currentVersion} kmatId={props.kmatId} dataType={props.dataType} />
        };
        if (count) {
            treeNode.children = Object.values(node.items).map(childNode => convertDataToTreeNode(childNode, node));
        }
        return treeNode;
    }

    const onCheck = (value, targetNode) => {
        if (!targetNode?.disabled) {
            setCheckedItems(value);
            onClick(targetNode);
        }
    };

    const onExpand = (value) => {
        dispatch({ type: "REMEMBER_TREE_EXPANSION", payload: value });
        setExpanded(value);
    };

    const onClick = useCallback((event) => {
        let foundNode = event?.label?.props?.node;
        announceSelection(foundNode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.activeNode]);

    const announceSelection = (node) => {
        setActiveNode(prevState => ({ ...prevState, [props?.selectedTab]: node }));
        highlightNode((node.choiceID || node.itemID), elementIndex, elementsList);
        dispatch({ type: "SELECTED_ITEM", payload: node });
        dispatch(rememberProperty(node.choiceID || node.parentId));
    }


    const onSearch = (value) => {
        if (value === '') {
            setNodes(convertDataToTreeNodes(nodesFromApi)); return;
        }
        const lowercasedValue = value.toLowerCase();
        const filteredNodesFromApi = {
            ...nodesFromApi,
            items: nodesFromApi.items.reduce((acc, node) => {
                const matchesParent = node.text?.toLowerCase().includes(lowercasedValue);
                const matchingChildren = findMatchingChildren(node.items, lowercasedValue);
                // Create a new node with potentially filtered children
                let newNode = getUpdatedData({ ...node, items: matchingChildren });
                if (matchesParent || Object.keys(matchingChildren).length > 0) {
                    acc.push(newNode);
                }
                return acc;
            }, [])
        };
        const nodes = convertDataToTreeNodes(filteredNodesFromApi);
        setNodes(nodes);
    }

    function findMatchingChildren(items, value) {
        return Object.entries(items).reduce((acc, [key, entry]) => {
            if (entry.text?.toLowerCase().includes(value)) {
                acc[key] = entry;
            }
            return acc;
        }, {});
    }

    function onExpandCollapse (isAllExpanded = props.onExpandCollapseClick) {
        let listOfToBeExpandedNodes = [];
        if (isAllExpanded) {
            listOfToBeExpandedNodes = nodes?.map((node) => node.children ? node.value : '');
        } else {
            listOfToBeExpandedNodes = expandActions?.[props.selectedTab] || [];
        }
        setExpanded(listOfToBeExpandedNodes);
    }

    const onMoreIconClick = (e, id) => {
        selectedNode.current = nodesFromApi.items.filter((nodeFromApi) => nodeFromApi.choiceID === id || nodeFromApi.itemID === id)[0];

        // Adding the active-color class for the selected element
        announceSelection(selectedNode.current);
        setAnchorEl(e.currentTarget.closest('#parentOfMoreIcon'));
        e.stopPropagation();
    }

    const handleClose = () => { setAnchorEl(null) }

    return (
        <>
            <div id="checkbox-tree-view">
                <CheckboxTree
                    id={'tr'}
                    checked={props?.checkedItems}
                    expanded={expanded}
                    nodes={nodes}
                    checkModel='all'
                    onCheck={onCheck}
                    onExpand={onExpand}
                    showNodeIcon={false}
                    showNodeTitles={true}
                    nativeCheckboxes={false}
                    expandOnClick={true}
                    onClick={onClick}
                    icons={{
                        check: <CheckBoxIcon className='checked-color' />,
                        uncheck: <CheckBoxOutlineBlankIcon className='unchecked-color' />,
                        halfCheck: <IndeterminateCheckBoxIcon className='checked-color' />,
                        expandClose: <KeyboardArrowRightIcon />,
                        expandOpen: <KeyboardArrowDownIcon />,
                        expandAll: <AddIcon />,
                        collapseAll: <RemoveIcon />,
                        parentClose: <span className="rct-icon rct-icon-parent-close" />,
                        parentOpen: <span className="rct-icon rct-icon-parent-open" />,
                        leaf: <span className="rct-icon rct-icon-leaf" />,
                    }}
                />
                <CheckboxTreePopover nodesFromApi={nodesFromApi} selectedNode={selectedNode} dataType={props.dataType} anchorEl={anchorEl} setAnchorEl={setAnchorEl} handleClose={handleClose} announceSelection={announceSelection} currentVersion={props.currentVersion} kmatId={props.kmatId}></CheckboxTreePopover>
            </div>
        </>
    );
}

export default memo(ReactCheckboxTree);
