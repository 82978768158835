import React, { useState } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog';
import TextField from '@mui/material/TextField';

const AddCopyDeleteConfirmationDialog = ({ isOpen, onClose, onDelete }) => {
    const [comments, setComments] = useState('');

    const handleDelete = () => {
        onDelete(comments);
    };

    return (
        <ConfirmationDialog
            open={isOpen}
            onClose={onClose}
            title="Delete Confirmation"
            content="Are you sure you want to delete this item? Please provide a reason for deletion."
            onConfirm={handleDelete}
            caption="Delete"
            bgColor="#BE1313"
            disabled={comments.trim() === ''}
        >
            <TextField
                autoFocus
                margin="dense"
                label="Comments"
                type="text"
                fullWidth
                variant="standard"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
            />
        </ConfirmationDialog>
    );
};

export default AddCopyDeleteConfirmationDialog;