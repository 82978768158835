import React, { useLayoutEffect, useRef, useState } from "react";

import { CustomSearch, searchFieldProps } from 'pages/EditModel/IndexHelpers';
import "../ReactCheckboxTree/ReactCheckboxTree.css";
import useKeypress from "hooks/useKeypress";

export default function SearchBarTree(props) {
  const { onSearch, selectedTab, dataType } = props;
  const [searchKeyword, setSearchKeyword] = useState("");
  const searchRef = useRef();
  const handleSearch = (text) => {
    setSearchKeyword(text);
    onSearch(text); 
  };

  useLayoutEffect(() => {
    setSearchKeyword("");
    onSearch("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const handleClearSearch = () => {
    setSearchKeyword("");
    onSearch("");

  };

  useKeypress({ f: () => searchRef.current.focus() }, true);

  return (
    <CustomSearch {...searchFieldProps}
      placeholder={
        dataType !== "regional"
          ? "Search Choice, Items, Labels, Regions"
          : "Search Choice, Items"
      }
      className='searchBar'
      value={searchKeyword}
      inputRef={searchRef}
      style={{ width: '100%', marginTop: "0px" }}
      handleClearSearch={handleClearSearch}
      onChange={(e) => handleSearch(e.target.value)}
    />
  );
}
