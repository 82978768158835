import React from "react";
import ErrorToolTip from "../Common/ErrorToolTip";

const DuplicatePartDescriptionsTooltip = (props) => {
  
  const partDescriptionErrors = props?.syntaxCheck?.PartDescription;
  const hasDuplicatedDescription = partDescriptionErrors?.find((descriptionError) => descriptionError.key === "duplicateDescription");
  const hasInvalidDescription = partDescriptionErrors?.find((descriptionError) => descriptionError.key === "invalidDescription");

  return hasDuplicatedDescription
    ? (<ErrorToolTip data={[hasDuplicatedDescription]} descrError />)
    : hasInvalidDescription
      ? (<ErrorToolTip data={[hasInvalidDescription]} descrError />)
      : (null);
};

export default DuplicatePartDescriptionsTooltip;
