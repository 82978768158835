import React, { useState } from "react";
import ChoiceTabPanel from 'components/ChoiceTabPanel';
import Messages from "../Tabs/Messages";
import ChoiceSettings from '../Tabs/ChoiceSettings'
import Comments from '../Tabs/Comments';
import { useSelector } from "react-redux";
import { getTab } from "pages/Utils";

const ChoiceEdit = (props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const syntaxCheck = useSelector(state => state.models.syntaxCheck[getTab(props?.tabName)][props?.data?.choiceID]);

  const tabs = [
    <ChoiceSettings
      key={`editChoice-settings-${props?.data?.choiceID}`}
      choiceID={props?.data?.choiceID}
      tabName={props.tabName}
      data={props?.choiceData}
      syntaxCheck={syntaxCheck}
    />,
    <Messages
      key={`editChoice-messages-${props?.data?.choiceID}-${JSON.stringify(props?.data?.messages)}`}
      choiceID={props?.data?.choiceID}
      tabName={props.tabName}
      data={props?.choiceData}
      syntaxCheck={syntaxCheck}
    />,
    <Comments
      key={`editChoice-comments-${props?.data?.choiceID}-${JSON.stringify(props?.data?.comments)}`}
      choiceID={props?.data?.choiceID}
      tabName={props.tabName}
      data={props?.choiceData}
    />
  ]

  return props?.sysChoiceID === "SYS" ? null : (
    <>
        <ChoiceTabPanel
          activeTabIndex={activeTabIndex}
          setActiveTabIndex={setActiveTabIndex}
          choiceID={props?.data?.choiceID}
          tabName={props?.tabName}
          hasComments= {Boolean(props?.choiceData?.comments?.length)} 
          syntaxCheck={syntaxCheck}
        />
      {tabs[activeTabIndex]}
    </>
  );
};

export default ChoiceEdit;
