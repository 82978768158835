export const highlightNode = (id, elementIndex, elementsList) => {
    if (id) {
        removeHighlightNode();
        let el = document.getElementById(id)?.parentElement;
        el?.classList.add('active-color');

        // Setting the index of the selected node in the elementIndex
        let parentOfel = el?.closest('.rct-node-clickable');
        let index = elementsList.current?.findIndex((element) => element === parentOfel);
        elementIndex.current = index > -1 ? index : elementIndex.current;
    }
}

const removeHighlightNode = () => {
    let activeElements = document.querySelectorAll('.active-color');
    activeElements.forEach(element => element.classList.remove('active-color'));
}