import React, { useState, useCallback, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GraphqlApi } from 'services/api';
import { logout, renewToken, resetAccessCookies } from "./actions/userActions";
import { SESSION_WARNING_TIMEOUT, TOKEN_EXPIRATION_MIN, } from "./constants/configConstants";
import Cookies from "js-cookie";
import TopBar from "components/TopBar";
import Login from "pages/Login";
import NotFound from "./pages/NotFound";
import LoginFail from "./pages/LoginFail";
import LoginOk from "./pages/LoginOk";
import Dashboard from "pages/Dashboard"
import RegionalDashboard from "pages/RegionalDashboard";
import FilesDashboard from "pages/Files";
import AdminPanelTab from "pages/AdminPanelTab";
import BtpPanel from "pages/BtpPanel";
import { FilePicker } from 'pages/Files/FilePicker';
import ConfirmationDialog from "components/ConfirmationDialog";
import PermanentDrawer from './components/PermanentDrawer/PermanentDrawer'
import ProtectedRoute from "./pages/ProtectedRoute";
import GenericSnackbar from "components/GenericSnackbar";
import "./App.css";
import KmatDetails from "pages/EditModel/ModelHeader/KmatDetails";
import OcplcDashboard from "pages/ocplcDashboard";
import  OcplcEditPage  from "pages/ocplcDashboard/OcplcEditPage";
import { clearAllCookies } from "utils/function-utils";
import useGetMUILicense from "hooks/useGetMUILicense";


function App() {
  const logoutTimer = useRef(null);
  const interval = useRef(null);
  const dispatch = useDispatch();
  const [isExitDialogOpen, setIsExitDialogOpen] = useState(false);
  const [isExitDialogLoading, setIsExitDialogLoading] = useState(false);
  const [openTokenWarning, setOpenTokenWarning] = useState(false);
  const userSignin = useSelector((state) => state.user);
  const showGenericSnackbar = useSelector((state) => state.models.showGenericSnackbar);
  const [userDetails, setUserDetails] = useState(null);
  const [remainingSecondsToken, setRemainingSecondsToken] = useState("");

  useGetMUILicense();

  /* Fetch userInfo and expiration from state when user is logged in (Comment out these lines for local development) */
  const userInfo = userSignin && userSignin.userInfo ? userSignin.userInfo : JSON.parse(Cookies.get('userInfo') || null);
  const expires = Cookies.get("expires");
  Cookies.set("userInfo",JSON.stringify(userInfo));

  /* Use default user info and expiration when user is not logged in (For local development only, update with your own credentials) */
  // const userInfo = { userEmail: "abc@hp.com", userName: "abc" }
  // const expires = 0;

  const secondsToTime = secs => ({ h: Math.floor(secs / 3600), m: Math.floor(secs / 60) % 60, s: secs % 60 });

  /* useEffect hook to fetch user data when user is trying to login */
  useEffect(() => {
    const excludedPaths = ["/","/loginok"];
    if (userInfo) {
      GraphqlApi.query('kbUser')
        .then((res) => {
          dispatch({ type: 'KB_USER_DATA', payload: res.data.kbUserWithRoles });
          setUserDetails(res.data.kbUserWithRoles);
        }).catch((err) => {
          const message = err?.message || 'Error getting user details.';
          dispatch({ type: "USER_LOGIN_FAIL", payload: message })

        });
    } else if (!excludedPaths.includes(window.location.pathname)) {
      window.location.pathname = "/";
    }
  }, [userInfo?.userEmail]);


  /* Function to manage warning dialog closure and execute user logout (Specifically for Token Expiration). */
  const handleWarningDialogClose = () => {
    setOpenTokenWarning(false);
    clearInterval(interval.current);
    resetAccessCookies();
    dispatch(logout());
    setTimeout(() => {
        window.location.pathname = "/"; 
        window.location.reload();
    }, 500);  
  };

  /* Function to handle the logout dialog open */
  const handleLogoutDialogOpen = useCallback(() => {
    setIsExitDialogOpen(true);
  }, [setIsExitDialogOpen]);

  /* Function to handle the logout dialog close */
  const handleLogoutDialogClose = useCallback(() => {
    setIsExitDialogOpen(false);
    setIsExitDialogLoading(false);
  }, [setIsExitDialogOpen, setIsExitDialogLoading]);

  /* Function to handle user logout */
  const handleLogout = useCallback(() => {
    setIsExitDialogLoading(true);
    dispatch(logout());
    handleLogoutDialogClose();
  }, [dispatch, setIsExitDialogLoading, handleLogoutDialogClose]);

  /* Function to handle token renewal and session continuation (Exclusively for Token Expiration). */
  const handleRenewToken = () => {
    dispatch(renewToken());
    setOpenTokenWarning(false);
    clearInterval(interval.current);
  };

  /* useEffect hook to set the timer for user notification about token expiration */
  useEffect(() => {
    const expiration = new Date(expires * 1000 - TOKEN_EXPIRATION_MIN * 60000);
    const remainingTime = expiration.getTime() - new Date().getTime();
    if (remainingTime > 0) {
      let tsec = SESSION_WARNING_TIMEOUT;
      logoutTimer.current = setTimeout(() => {
        setOpenTokenWarning(true);
        interval.current = setInterval(() => {
          const time = secondsToTime(--tsec);
          const displayTime = time.m > 0 ? `${time.m + " Minutes " + time.s + " Seconds"}` : `${time.s + " Seconds"}`;
          setRemainingSecondsToken(displayTime);

          if (displayTime === "0 Seconds") handleWarningDialogClose();
        }, 1000);
      }, remainingTime);
    } else {
      clearTimeout(logoutTimer.current);
    }
    // Clear on dispose
    return () => {
      clearAllCookies();
      clearTimeout(logoutTimer.current);
      clearInterval(interval.current);
    };
  }, [expires]);

  return (
    <Router>
      <div className="pcr-container">
        {window.location.pathname !== '/' && window.location.pathname !== "/login" ?
          <TopBar logout={handleLogoutDialogOpen} userDetails={userDetails} /> : null}

        <main className={window.location.pathname === "/" || window.location.pathname === "/login" ? 'pcr-login-page' : 'pcr-main'}>
          {window.location.pathname === "/" || window.location.pathname === "/login" ? null : <PermanentDrawer userDetails={userDetails} />}
          {showGenericSnackbar && <GenericSnackbar showGenericSnackbar = {showGenericSnackbar} />}

          <div className={"pcr-landing-content"}>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/loginok" element={<LoginOk />} />
              <Route path="/loginfail" element={<LoginFail />} />
              <Route 
                path="/dashboard" 
                element={<ProtectedRoute component={Dashboard} auth={userDetails} />} 
              />
              <Route 
                path="/files" 
                element={<ProtectedRoute component={FilesDashboard} auth={userDetails} />} 
              />
              <Route 
                path="/btp" 
                element={<ProtectedRoute component={BtpPanel} auth={userDetails} />} 
              />
              <Route 
                path="/files/:kmatID/:tab" 
                element={<ProtectedRoute component={FilePicker} auth={userDetails} />} 
              />
              <Route 
                path="/regional" 
                element={<ProtectedRoute component={RegionalDashboard} auth={userDetails} />} 
              />
              <Route
                path="/ocplc"
                element={<ProtectedRoute component={OcplcDashboard} auth={userDetails} />}
              />
              <Route
                path="/ocplc/:type/:kmatId/:productGroup/:productLineID"
                element={<ProtectedRoute component={OcplcEditPage} auth={userDetails} />}
              />
              <Route 
                path="/admin/:tab" 
                element={<ProtectedRoute component={AdminPanelTab} auth={userDetails} />} 
              />
              <Route 
                path="/model/:kmatId/:version" 
                element={<ProtectedRoute component={KmatDetails} dataType="model" auth={userDetails} />} 
              />
              <Route 
                path="/admin/templates/:kmatId" 
                element={<ProtectedRoute component={KmatDetails} dataType="template" auth={userDetails} />} 
              />
              <Route 
                path="/regional/:kmatId" 
                element={<ProtectedRoute component={KmatDetails} dataType="regional" auth={userDetails} />} 
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </main>
      </div>

      {/* Logout Dialog */}
      {isExitDialogOpen &&
        <ConfirmationDialog
          open={isExitDialogOpen}
          title="Confirmation"
          content="Are you sure you want to logout?"
          onClose={handleLogoutDialogClose}
          loading={isExitDialogLoading}
          onConfirm={handleLogout}
          caption="Yes, Logout"
          bgColor="#BE1313"
        />}

      {/* Warning Dialog for token expiration  */}
      {openTokenWarning &&
        <ConfirmationDialog
          open={openTokenWarning}
          title="Warning (Token Expiration)"
          content={<>Your session will expire in <b>{remainingSecondsToken}.</b><br />Please click on "Continue" If you want to retake the session.</>}
          onClose={handleRenewToken}
          loading={isExitDialogLoading}
          onConfirm={handleWarningDialogClose}
          caption="Sign out"
          secondaryCaption="Continue Session"
          bgColor="#BE1313"
        />}
    </Router>
  );
}

export default App;
