export const SELECTED_OCPLC_KMAT = "SELECTED_OCPLC_KMAT";
export const LOCK_STATUS_DASHBOARD = "LOCK_STATUS_DASHBOARD";
export const MODULE_WISE_DATA_TRANSPORT = "MODULE_WISE_DATA_TRANSPORT";
export const COMPLETE_MODEL_AVAILABILITY = "COMPLETE_MODEL_AVAILABILITY";
export const DELETE_MODEL_AVAILABILITY = "DELETE_MODEL_AVAILABILITY";
export const DASHBOARD_DATA_TRANSPORT = "DASHBOARD_DATA_TRANSPORT";

// Model Availability
export const MODEL_AVAILABILITY = "MODEL_AVAILABILITY";
export const COUNTRY_LIST = "COUNTRY_LIST";
export const BUSINESS_MODEL = "BUSINESS_MODEL";
export const REGION_DROPDOWN = "REGION_DROPDOWN";
export const FILTER_OCPLC_EDIT_DASHBOARD = "FILTER_OCPLC_EDIT_DASHBOARD";
export const FILTER_BY_STATUS = 'FILTER_BY_STATUS';
export const HIDE_LABELS = ["Product Family", "Status"];
export const VERSION_0002 = '0002';
export const VERSION_0003 = '0003';
export const PLC_ADMIN = 'plc_admin';
export const PLC_WW = 'plc_ww';
export const PLC_REGIONAL = 'plc_regional';
export const PLC = 'plc';
export const PLC_AP = 'plc_ap';
export const PLC_LA = 'plc_la';
export const PLC_NA = 'plc_na';
export const PLC_EU = 'plc_eu';
export const TOF_AUDIT_TRAIL = "TOF_AUDIT_TRAIL"
export const UPDATE_MODEL_AVAILABILITY = "UPDATE_MODEL_AVAILABILITY";
export const CREATE_EXISTING_MODEL_AVAILABILITY = "CREATE_EXISTING_MODEL_AVAILABILITY";
export const ROLE_FROM_DROPDOWN = 'OCPLC Read Only';

export const REGIONS = ['AP', 'LA', 'NA', 'EU'];

export const REGION_AP = 'AP';
export const REGION_LA = 'LA';
export const REGION_NA = 'NA';
export const REGION_EU = 'EU';
export const REGION_WW = 'WW';


export const MATERIAL_AVAILABILITY = 'Material Availability';

export const roleRegionMap = {
    'OCPLC KE - AP': 'AP',
    'OCPLC KE - NA': 'NA',
    'OCPLC KE - EU': 'EU',
    'OCPLC KE - LA': 'LA',
    'OCPLC KE': 'WW',
    'OCPLC Admin': 'WW',
    'OCPLC Read Only': 'WW',
    'OCPLC Regional': 'WW',
}

export const CREATE_MODEL_AVAILABILITY = "CREATE_MODEL_AVAILABILITY"
export const GET_PRODUCT_LINE = "GET_PRODUCT_LINE"

export const ROLE_NAME_FOR_ADMIN = "OCPLC Admin"
export const ROLE_NAME_FOR_KE = "OCPLC KE"
export const ROLE_NAME_FOR_KE_AP = "OCPLC KE - AP"
export const ROLE_NAME_FOR_KE_NA = "OCPLC KE - NA"
export const ROLE_NAME_FOR_KE_EU = "OCPLC KE - EU"
export const ROLE_NAME_FOR_KE_LA = "OCPLC KE - LA"
export const OCPLC_REGIONAL = 'OCPLC Regional';
export const OCPLC_READ_ONLY = 'OCPLC Read Only';
export const OCPLC_KE_OTHER_REGIONAL = 'OCPLC KE -';

export const REQUIRED_FIELDS = ['PRODUCT_LINE', 'PRODUCT_FAMILY', 'PRODUCT_DESC', 'BUSINESS_MODEL', 'START_DATE', 'END_DATE', 'COMMENT'];
export const PRODUCT_FAMILY_PATTERN = /^[a-zA-Z0-9]{6}-999$/;

export const DASHBOARD_TITLE = "OCPLC Dashboard";
export const MODULE_TYPE = "ModelAvailability"