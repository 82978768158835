import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Grid from "@mui/material/Grid2";
import { createChoice } from "actions/modelActions";
import { RadioButton } from "pages/EditModel/ChoiceEdit/ChoiceEditHelpers";
import { getInputProps } from "pages/DashboardHelpers";


export default function AddNewChoiceDialog(props) {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({ choiceId: "", choiceDescription: "", choiceDataType: "CHAR", isHidden: false });
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(!(formData.choiceId && formData.choiceDescription));
  }, [formData.choiceId, formData.choiceDescription]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ 
        ...prevData, 
        [name]: name === "isHidden" ? value === "true" : value 
    }));
};

  const handleBlur = () => {
    setFormData((prevData) => ({ ...prevData, choiceId: prevData.choiceId.toUpperCase() }));
  };

  const handleCreateChoice = (e) => {
    e.preventDefault();
    dispatch(createChoice(formData, props));
  };

  return (
    <Dialog open={props.dialogOpen} onClose={(e) => props.handleDialogClose(e)} className='custom-dialog'>
      <DialogTitle> Add New Choice </DialogTitle>
      <DialogContent>
        <Typography gutterBottom variant="h6"> Choice Details </Typography>
        <TextField
          variant="outlined"
          fullWidth
          placeholder="Choice ID"
          value={formData.choiceId}
          autoFocus={true}
          slotProps={{ input: getInputProps() }}
          onChange={handleInputChange}
          onBlur={handleBlur}
          name="choiceId"
        />
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Description"
          value={formData.choiceDescription}
          slotProps={{ input: getInputProps() }}
          onChange={handleInputChange}
          name="choiceDescription"
        />
        <FormControl>
          <Grid container className="sub-header" >
            <Grid>
              <Typography gutterBottom variant="h8" >Data Type </Typography>
              <RadioGroup row name="choiceDataType" value={formData.choiceDataType} onChange={handleInputChange}>
                <FormControlLabel value="CHAR" control={<RadioButton />} label="CHAR" />
                <FormControlLabel value="NUM" control={<RadioButton />} label="NUM" />
              </RadioGroup>
            </Grid>
            <Grid style={{ marginLeft: "90px" }} >
              <Typography gutterBottom variant="h8"> Is Hidden </Typography>
              <RadioGroup row name="isHidden" value={formData.isHidden} onChange={handleInputChange}>
                <FormControlLabel value={true} control={<RadioButton />} label="Yes" />
                <FormControlLabel value={false} control={<RadioButton />} label="No" />
              </RadioGroup>
            </Grid>
          </Grid>
        </FormControl>
      </DialogContent>
      <DialogActions className='dialog-actions'>
        <Button variant="outlined" onClick={props.handleDialogClose} className="cancel-button">
          Cancel
        </Button>
        <Button variant="contained" disabled={disabled} onClick={handleCreateChoice} className="add-Button">
          + &nbsp; Add Choice
        </Button>
      </DialogActions>
    </Dialog>
  );
}
