import Tooltip from "@mui/material/Tooltip";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

export const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiFormControl-root": {
            margin: theme.spacing(0.1),
            width: "-webkit-fill-available",
        },
        "& .MuiInputBase-input.Mui-disabled": {
            color: `${theme.palette.primary.main}`,
            fontSize: "18px",
        },
        "& .MuiOutlinedInput-inputMarginDense": {
            color: "black",
        },
        "& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense": {
            padding: "8px 0",
        },
    },
    paper: {
        height: "100%",
        maxHeight: "100%",
        overflow: "hidden",
        marginTop: "2px",
        zIndex: "1000",
    },
    icon: {
        position: "relative",
        float: "right",
        top: "5px",
        right: "20px",
        cursor: "pointer",
    },
    cellContent: {
        width: "384px",
        height: "80px",
        justifyContent: "center",
        [`& fieldset`]: {
            borderRadius: 10,
            paddingTop: 20,
            paddingBottom: 15,
        },
    },
    saveButton: {
        marginRight: "13px !important",
        borderRadius: "10px !important",
        padding: "7px 17px 7px 17px !important",
    },
    cvgSelection: {
        flexGrow: 1,
        display: "flex",
        alignItems: "flex-start",
        padding: "3px 5px 2px 5px !important",
    },
    cvgName: {
        width: "440px",
        overflowWrap: "break-word",
    },
    duplicateError: {
        color: "red",
        marginLeft: "5px !important",
        marginBottom: "5px !important",
        height: "30px !important"
    },
    treeLabel: {
        padding: "5px 0px",
    },
}));

export const style = {
    top: "50%",
    left: "50%",
    position: "fixed",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
};

export const ItemTooltip = withStyles({
    tooltip: {
        color: "black",
        backgroundColor: "cyan",
        fontSize: "0.8rem",
    },
})(Tooltip);
