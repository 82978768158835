import React, { useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import RenderTextfieldHeader from 'pages/renderTextFieldHeader';
import { renderSelectHeader } from "../DashboardHelpers";
import { useDispatch } from 'react-redux';
import { SELECTED_OCPLC_KMAT } from 'constants/ocplcConstants';
import OcplcMenuList from './ocplcMenulist';

const columnTypes = {
    kmatId: {
        type: 'text',
        name: 'kmatId',
        matchFields: ['kmatId'],
    },
    status: {
        type: 'text',
        name: 'status',
        matchFields: ['status']
    },
    productLineName: {
        type: 'text',
        name: 'productLineName',
        matchFields: ['productLineName'],
    },
    productName: {
        type: 'text',
        name: 'productName',
        matchFields: ['manufacturingName'],
    },
};

const DefineColumns = ({ filters, handleChange, ocplcProductLines, statuses, userRole, userEmail }) => {


    return [
        {
            headerName: 'KMAT ID',
            field: 'kmatId',
            headerClassName: 'dashboard-data-grid-headers',
            flex: 0.8,
            sortable: false,
            renderCell: (params) => <Typography variant='subtitle2'>{params.row.kmatId}</Typography>,
            renderHeader: () => <RenderTextfieldHeader colData={columnTypes?.kmatId} label={"KMAT ID"} filters={filters} handleChange={handleChange} />,
        },
        {
            headerName: 'Product Name',
            field: 'productName',
            headerClassName: 'dashboard-data-grid-headers',
            headerAlign: 'left',
            flex: 1.0,
            sortable: false,
            renderCell: (params) => <Typography variant='subtitle2'>{params.row.manufacturingName}</Typography>,
            renderHeader: () => <RenderTextfieldHeader colData={columnTypes?.productName} label={"Product Name"} filters={filters} handleChange={handleChange} />,
        },
        {
            headerName: 'Status',
            field: 'status',
            headerClassName: 'dashboard-data-grid-headers',
            headerAlign: 'left',
            flex: 1.0,
            sortable: false,
            renderCell: (params) => {
                const status = params.row.status || '';
                const importsCount = params.row.imports ? Object.keys(params.row.imports).length : 0;
                return (
                    <div className='row-status'>
                        <Typography variant='subtitle2'>
                            {status.charAt(0).toUpperCase() + status.slice(1)}
                        </Typography>
                        {importsCount > 0 && <div className='imports-count'>{importsCount}</div>}
                    </div>
                );
            },
            renderHeader: () => renderSelectHeader(columnTypes?.status, filters, 'Status', handleChange, statuses),
        },
        {
            headerName: 'Product Line',
            field: 'productLine',
            headerClassName: 'dashboard-data-grid-headers',
            flex: 1.0,
            sortable: false,
            renderHeader: () => renderSelectHeader(columnTypes?.productLineName, filters, 'Product Line', handleChange, ocplcProductLines),
            renderCell: (params) => (
                <div className='effective-date-data'>
                    <Typography variant='subtitle2'>{params.row.productLineName}</Typography>
                </div>
            ),
        },
        {
            headerName: '',
            field: 'actions',
            headerClassName: 'dashboard-data-grid-headers',
            width: 130,
            sortable: false,
            renderCell: (params) => (
                <div className="action-button-class">
                    <OcplcMenuList
                     row={params.row}
                    loginUserEmailId={userEmail}
                    userRole={userRole}
                      />
                </div>
            ),
        },
    ];
};

export default DefineColumns;