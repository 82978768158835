import React, { useState, useEffect } from 'react';
import { Button, FormControlLabel, Checkbox, Typography } from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSelector, useDispatch } from 'react-redux';
import { createModelAvailability, filterOcplcKmat, GetProductLine, modelAvailability } from 'actions/ocplcAction';
import { PRODUCT_FAMILY_PATTERN, REQUIRED_FIELDS } from 'constants/ocplcConstants';
import dayjs from 'dayjs';

const initialFormData = (version, region, productGroup, userEmail) => ({
  VERSION: version || '',
  REGION: region || '',
  PRODUCT_LINE: '',
  PRODUCT_FAMILY: '',
  PRODUCT_DESC: '',
  COUNTRY: 'ALL',
  BUSINESS_MODEL: '',
  PRODUCT_GROUP: productGroup || '',
  START_DATE: null,
  END_DATE: null,
  COMMENT: '',
  apRegion: true,
  euRegion: true,
  naRegion: true,
  laRegion: true,
  whoChanged: userEmail
});

const AddDialog = ({ open, onClose, version, region, productGroup, productLineID, userEmail, kmatId, businessModels }) => {
  const dispatch = useDispatch();
  const productLine = useSelector(state => state.ocplc.productLine) || [];

  const [formData, setFormData] = useState({});
  const [productFamilyError, setProductFamilyError] = useState('');

  useEffect(() => {
    if (open) {
      setFormData(initialFormData(version, region, productGroup, userEmail));
      dispatch(GetProductLine(productGroup));
    }
  }, [open, version, region, productGroup, userEmail]);


  const handleChange = (field, value) => {
    if (field === "PRODUCT_FAMILY") {
      setProductFamilyError("");
      setFormData((prevFormData) => ({ ...prevFormData, [field]: value }));
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [field]: value }));
    }
  };

  const handleBlur = (label) => {
    if (label === "PRODUCT_FAMILY") {
      setFormData((prevFormData) => ({ ...prevFormData, PRODUCT_FAMILY: prevFormData.PRODUCT_FAMILY.toUpperCase() }));
    }
  };

  const handleAddClick = () => {
    if (!PRODUCT_FAMILY_PATTERN.test(formData.PRODUCT_FAMILY)) {
      setProductFamilyError('Product Family must be 6 alphanumeric characters followed by -999');
      return;
    }
    dispatch(createModelAvailability(formData)).then((message) => {
      if (message === 'Success') {
        dispatch(modelAvailability(version, region, kmatId, productGroup, productLineID));
        dispatch(filterOcplcKmat(productLineID))
        onClose();
      }
    });
  };

  const dataSourceMapping = {
    PRODUCT_LINE: productLine,
    BUSINESS_MODEL: businessModels
  };

  const formFields = [
    { label: "PRODUCT_GROUP", editable: false },
    { label: "VERSION", editable: false },
    { label: "REGION", editable: false },
    { label: "PRODUCT_LINE", editable: true },
    { label: "PRODUCT_FAMILY", editable: true },
    { label: "PRODUCT_DESC", editable: true },
    { label: "BUSINESS_MODEL", editable: true },
    { label: "START_DATE", editable: true },
    { label: "END_DATE", editable: true }
  ];

  const isFormValid = () => {
    return REQUIRED_FIELDS.every(field => formData[field]);
  };

  const renderField = (field) => {
    const { label, editable } = field;
    const commonProps = {
      key: label,
      container: true,
      alignItems: "center",
    };

    if (label === "START_DATE" || label === "END_DATE") {
      return (
        <Grid {...commonProps}>
          <Grid item xs={4}><Typography>{label} :</Typography></Grid>
          <Grid item xs={8}>
            <DatePicker label={label} value={dayjs(formData[label])} onChange={(value) => handleChange(label, value)} inputVariant="outlined" slotProps={{ textField: { error: false }}} />
          </Grid>
        </Grid>
      );
    }

    if (dataSourceMapping[label]) {
      return (
        <Grid {...commonProps}>
          <Grid item xs={4}><Typography>{label}</Typography></Grid>
          <Grid item xs={8}>
            <FormControl fullWidth>
              {formData[label] === "" && <InputLabel shrink={false}>{label}</InputLabel>}
              <Select value={formData[label]} onChange={(e) => handleChange(label, e.target.value)} variant="outlined" displayEmpty>
                {dataSourceMapping[label].map((item) => (
                  <MenuItem key={item.value} value={item.id || item.value}>
                    {item.name || ` ${item.value} | ${item.text}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid {...commonProps}>
        <Grid item xs={4}><Typography>{label}</Typography></Grid>
        <Grid item xs={8}>
          <TextField
            value={formData[label]}
            onChange={(e) => handleChange(label, e.target.value)}
            variant="outlined"
            fullWidth
            onBlur={() => handleBlur(label)}
            InputProps={{ readOnly: !editable, placeholder: label }}
            disabled={!editable}
            sx={{ backgroundColor: !editable ? '#f0f0f0' : 'inherit' }}
            error={label === 'PRODUCT_FAMILY' && !!productFamilyError}
            helperText={label === 'PRODUCT_FAMILY' && productFamilyError}
          />
        </Grid>
      </Grid>
    );
  };

  const regions = [
    { label: 'AP', id: 'apRegion' },
    { label: 'EU', id: 'euRegion' },
    { label: 'NA', id: 'naRegion' },
    { label: 'LA', id: 'laRegion' },
  ];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            borderRadius: 2,
          }
        }}
      >
        <DialogTitle sx={{ marginTop: "2px" }}>
          Add new KMAT
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {formFields.map((field) => (
              <Grid item xs={12} key={field.label}>
                {renderField(field)}
              </Grid>
            ))}
            <Grid item xs={12}>
              {regions.map((region) => (
                <FormControlLabel
                  key={region.id}
                  control={<Checkbox checked={!!formData[region.id]} onChange={(e) => handleChange(region.id, e.target.checked)} />}
                  label={region.label}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs={4}><Typography>COMMENT</Typography></Grid>
                <Grid item xs={8}>
                  <TextField
                    value={formData.COMMENT}
                    onChange={(e) => handleChange('COMMENT', e.target.value)}
                    variant="outlined"
                    fullWidth
                    InputProps={{ readOnly: false, placeholder: 'COMMENT' }}
                    sx={{ backgroundColor: 'inherit' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} container justifyContent="flex-end" margin={2}>
            <Button variant="outlined" color="primary" onClick={onClose}>Cancel</Button>
            <Button variant="contained" color="primary" sx={{ marginLeft: 1 }} onClick={handleAddClick} disabled={!isFormValid()}>
              Add
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};

export default AddDialog;