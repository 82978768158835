import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { StyledTableCell, StyledTableRow, useRowStyles } from "../MultiItemEdit/MultiItemEditHelpers";

export const StaticData = (props) => {
  const { root, accordionDetails } = useRowStyles();
  const [expanded, setExpanded] = useState({});

  const toggleAccordion = (choiceID) => {
    setExpanded(prevExpanded => ({ ...prevExpanded, [choiceID]: !prevExpanded[choiceID] }));
  };

  return (
    <Table aria-label="collapsible table">
      {/* <TableBody> */}
        {/* <TableRow> */}
          {/* <TableCell> */}
              {props?.datasetValues?.map((row) => {
                const choiceID = row.choiceID;

                return (
                  <Table key={choiceID} style={{maxWidth: '100%'}}>
                    <TableBody>
                      <TableRow>
                        <TableCell className={root}>
                          <Accordion sx={{ boxShadow: "none" }} expanded={expanded[choiceID] || false}>
                            <AccordionSummary aria-controls={`choice id ${choiceID} content`} expandIcon={<KeyboardArrowDownIcon />} onClick={() => toggleAccordion(choiceID)}  id={choiceID}>
                              {row.name}
                            </AccordionSummary>
                            {expanded[choiceID] && (
                            <AccordionDetails className={accordionDetails}>
                              <TableContainer component={Paper} style={{ margin: "5px 0px 8px 0px" }}>
                                <Table aria-label="customized table">
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableCell width={5} />
                                      <StyledTableCell width={50}>Part#</StyledTableCell>
                                      <StyledTableCell width={50}>Value Id</StyledTableCell>
                                      <StyledTableCell width={300}>Description</StyledTableCell>
                                      <StyledTableCell width={100}>Filter Condition</StyledTableCell>
                                      <StyledTableCell width={100}>CVG</StyledTableCell>
                                      {props?.isOnRegionalTab && <StyledTableCell width={50}>Region</StyledTableCell>}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {Object.values(row.items).map((item) => (
                                      <StyledTableRow key={item.itemID}>
                                        <StyledTableCell />
                                        <StyledTableCell>{item.partNumber}</StyledTableCell>
                                        <StyledTableCell>{item.isSystemChoice ? null : item.valueID}</StyledTableCell>
                                        <StyledTableCell>{item.description}</StyledTableCell>
                                        <StyledTableCell>{item.filterCondition}</StyledTableCell>
                                        <StyledTableCell>{item.cvg}</StyledTableCell>
                                        {props?.isOnRegionalTab && <StyledTableCell>{item.comments}</StyledTableCell>}
                                      </StyledTableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </AccordionDetails>
                            )}
                          </Accordion>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                );
              })}
          {/* </TableCell> */}
        {/* </TableRow> */}
      {/* </TableBody> */}
    </Table>
  );
};
