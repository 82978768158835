import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { CAREPACK_TAB, CUSTOMSERVICE_TAB, DEPLOY_TAB, MISC_TAB, SPARES_TAB } from "constants/modelConstants";

export const useStyles = makeStyles((theme) => ({
  popOver: {
      "& .MuiPopover-paper": {
          padding: "15px 10px",
          // minWidth: theme.spacing(15),
          display: 'flex',
          gap: '0',
          flexDirection: 'column',
          textAlign: 'left',
          marginTop: '125px',
      },
  },
    btpContainer: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      maxWidth: '1350px',
    },
    btpEnvContainer: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '1350px',
      height: '195px',
    },
}));

export const container = {
  display: 'flex !important',
  height: '100%',
  flexDirection: 'column !important',
  maxWidth: '700px',
}

export const ocplcContainerStyles = {
  display: 'flex',
  height: '100%',
  maxHeight: '780px',
  flexDirection: 'column',
  maxWidth: '1350px',
};

export const btpPanel = {
  display: 'flex',
  height: '100%',
  width: '100%',
  gap: '50px',
};

export const boxStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "15px",
  marginTop: "25px",
};

export const iconButtonStyle = {
  marginLeft:"10px",
  borderRadius: "5px",
  backgroundColor: "#ebf1f5",
  cursor: "pointer",
  marginBottom: "5px",
  fontSize: ".9rem",
  "&:hover": {
    backgroundColor: "#e2e9ee",
  },
}

export const iconButtonSubmit = {
  ...iconButtonStyle,
  backgroundColor: "#2471A3",
  "&:hover": {
    backgroundColor: "#2471A3",
  },
}

export const options = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true
};

export const materialTypeSelect = {
  padding: "7px 0px 7px 0px !important",
  borderRadius: "12px !important"
}

export const getInputProps = () => ({
  style: {
    borderRadius: "12px",
    padding: "6px",
    marginBottom: "10px"
  },
});
export const uiSpecGridStyle = {
  "& .MuiDataGrid-cell": { border: "none" },
  minHeight: "900px",
  width: "50%",
  height: "auto",
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-scrollbarFiller.MuiDataGrid-scrollbarFiller--borderTop": {
    borderTop: "none",
  },
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "#f8f8f8 !important",
  },
  '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none',
  },
};

export const dataGridStyles = {
  "& .MuiDataGrid-columnSeparator": {
    display: "none"
  },
  "& .MuiDataGrid-columnHeaderDraggableContainer": {
    display: "flex",
    alignItems: "center",
    },
  "& .MuiDataGrid-container--top [role='row'], & .MuiDataGrid-container--bottom [role='row']": {
    backgroundColor:"#f8f8f8 !important", 
  },
  '& .MuiDataGrid-cell': {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    display: 'inline'
  },
  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus .MuiDataGrid-columndHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
    outline: 'none !important',
  },
  '& .MuiDataGrid-virtualScrollerContent': {
    flexBasis: "0px !important"
  },
  '& .MuiDataGrid-cell:focus': {
      outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
      outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus': {
      outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none',
  },
  '& .MuiDataGrid-columnHeaderCheckbox': {
    paddingLeft: "4px",
    paddingTop: "25px"
  },
};

export const getDefaultMaterialType = (choiceID, tabCategory) => {
  if (choiceID === "PROD" || choiceID === "PILOT" || tabCategory === MISC_TAB) {
    return "S3_C";
  } else if (tabCategory === CAREPACK_TAB || tabCategory === CUSTOMSERVICE_TAB || tabCategory === DEPLOY_TAB) {
    return "XD";
  } else if (tabCategory === SPARES_TAB) {
    return "S3_L";
  } else {
    return "LF";
  }
};


export const renderSelectHeader = (colData, filters, label, handleChange, items) => {
  const productLineFilter = filters[colData.name]?.value;
  return (
    <div className='header-children'>
      <Typography variant='subtitle2' sx={{ marginBottom: "5px" }}> {label} </Typography>
      <FormControl fullWidth>
        {!productLineFilter && (<InputLabel disableAnimation shrink={false} focused={false} className='item-type-label-header'> Select</InputLabel>)}
        <Select
          value={productLineFilter || ''}
          onChange={(e) => handleChange(colData, e.target.value)}
          className='select-field'
          onBlur={e => e.stopPropagation()}
          endAdornment={productLineFilter &&
            <InputAdornment position="start" style={{ cursor: 'pointer' }}>
              <CloseIcon className='clear-filter' onClick={(e) => { e.stopPropagation(); handleChange(colData, '') }} />
            </InputAdornment>} >
          {items && items.map((item, index) => (
            <MenuItem key={index} value={item.name || item} className='product-lines-and-counts'>
              {item.name ? (<div style={{ display: 'flex' }}> {item.name} { Number.isFinite(item.count) && <span className='product-line-count'>{item.count}</span>}</div>) : (item)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};


