import { AP_TAB, CAREPACK_TAB, CUSTOMSERVICE_TAB, CVGMGMT_TAB, DEPLOY_TAB, EU_TAB, FETCH_SUCCESS, LA_TAB, MANDA_TAB, MISC_TAB, NAGPSA_TAB, NA_TAB, PM_TAB, REGIONAL_TAB, SPARES_TAB, STEP_NAMES, WWAP_TAB, WWEU_TAB, WWLA_TAB, WWNA_TAB, WW_TAB } from "constants/modelConstants";

export const CONTENT_KEY = "text"; // key in choice/item for searching

// Process each relevant data set using processModelData
const propertiesToProcess = [
  "dataPM",
  "dataMisc",
  "dataRegional",
  "dataCarePack",
  "dataCustomService",
  "dataDeploy",
  "dataSpares",
  "dataMandA",
  'dataWWNA',
  'dataWWLA',
  'dataWWEU',
  'dataWWAP',
  'dataNA',
  'dataLA',
  'dataEU',
  'dataAP',
  'dataNAGPSA',
  'dataWW',
];

// A data set with the possibility of syntax checking errors
const syntaxCheckProperties = [
  "dataPM",
  "dataMisc",
  "dataCarePack",
  "dataCustomService",
  "dataDeploy",
  "dataSpares",
  "dataMandA",
  'dataWWNA',
  'dataWWLA',
  'dataWWEU',
  'dataWWAP',
  'dataNA',
  'dataLA',
  'dataEU',
  'dataAP',
  'dataNAGPSA',
];

export const getInitialSyntaxCheck = () => syntaxCheckProperties.reduce((acc, property) => {
  acc[property] = {}; return acc}, {});

export const getActiveDataSet = (state, action) => {
  try {
    const { activeCVGTab, activeTab: stateActiveTab } = state;
    const {
      type: actionType,
      payload: { dataType, ...actionPayload },
      activeTab: actionActiveTab,
    } = action;

    let modelData, activeTab;

    if (isFetchSuccess(actionType)) {
      modelData = actionPayload;
      activeTab = stateActiveTab;
    } else {
      modelData = state;
      activeTab = actionActiveTab;
    }

    const dataKey = getDataKey(activeTab, activeCVGTab);
    const { processedData } = processModelData(modelData[dataKey], dataType);

    const processedProperties = propertiesToProcess.reduce((acc, propName) => {
      if (modelData[propName]) {
        const { processedData } = processModelData(modelData[propName], dataType);
        acc[propName] = processedData;
      }
      return acc;
    }, {});

    return {
      ...processedProperties,
      activeDataSet: processedData,
      versions: modelData.versions,
      canSyntaxCheck: modelData.kmat?.canSyntaxCheck,
    };
  } catch (error) {
    console.error(error)
  }
};

const isFetchSuccess = (actionType) => actionType === FETCH_SUCCESS;

const processModelData = (modelData, dataType) => {
  const processedData = {};

  modelData?.forEach((choice) => {
    processedData[choice.choiceID] = processChoice(choice, dataType);
  });

  return { processedData };
};

export const processChoice = (choice, dataType) => {
  const processedChoice = {
    ...choice,
    parentId: "0",
    id: choice.choiceID,
    description: choice.description,
    ...formatSelectionType(choice?.selectionType),
    name: formatChoiceName(choice),
    [CONTENT_KEY]: formatChoiceText(choice, dataType),
    isExpanded: false,
    messages: JSON.parse(choice.messages || "[]"),
    items: {},
  };

  choice.items?.forEach((item) => {
    processedChoice.items[item.itemID] = processItem(item, choice);
  });

  return processedChoice
};

export const formatChoiceName = (choice) => `${choice.description} (${choice.choiceID})`;

export const formatSelectionType = (rawSelectionType) => {
  const [selectionType, requiredType] = rawSelectionType.split(" ");
  return {
    selectionType,
    isRequired: requiredType === "Required",
  };
}

export const formatItemText = (item) => {
  const template = item.isTemplate ? 'template-' : "";
  const manual = item.isManual ? 'manual-' : "";
  const { status } = item.status && item.status !== "{}" ? JSON.parse(item.status) : { status: "" };

  return `${item.partNumber}-${item.description}-${item.region}-${template + manual + status}`;
}

export const formatChoiceText = (choice, dataType) => {
  const template = choice.isTemplate ? 'template-' : "";
  const manual = choice.isManual ? 'manual-' : "";
  const hidden = choice.isHidden ? 'hidden-' : "";
  const numeric = choice.dataType === "NUM" ? 'numeric-' : '';
  const { status } = choice.status && choice.status !== "{}" ? JSON.parse(choice.status) : { status: "" };

  return `${choice.choiceID}-${choice.description}-${template + manual + hidden + status + numeric}${dataType === 'regional' ? `-${choice.configRules}` : ''}`;
};

export const isSystemChoice = (data) => data.includes("SYS");

const processItem = (item, choice) => {
  return {
    ...item,
    id: item.itemID,
    parentId: choice.choiceID,
    name: formatItemName(item), // remains the same
    [CONTENT_KEY]: formatItemText(item), // new filters
    global_flag: choice.global_flag,
    messages: JSON.parse(item.messages || "[]"),
    defaultComponents: item?.defaultComponents ? JSON.parse(item.defaultComponents) : [],
    vcItemId: deriveVcItemId(item),
    cvgs: processCvg(item.cvg),
    isSystemChoice: isSystemChoice(choice?.dataType),
  };
};

const processCvg = (cvgString) => cvgString.replace(/ - /g, "&").split("&").map((x) => x.trim()).filter(x => x) || [];

export const deriveVcItemId = (item) => {
  if (item.partNumber.includes("AV#")) {
    return item.partNumber.replace("AV#", "");
  }
  return item.partNumber.replace("AV", "");
};

export const formatItemName = (item) => `${item.partNumber}-${item.description}`;

export const getTabUpdateStatus = (tabData) => {
  return STEP_NAMES.reduce((status, tab) => {
    const data = tabData[tab] || {};
    status[tab] = Object.values(data).some(res => {
      if (res.status !== "{}") return true;
      return false;
    });
    return status;
  }, {});
};

export const sortAndDisplayData = (obj) => {
  const sortedKeys = obj && Object.keys(obj).sort() || [];
  const result = (sortedKeys).reduce((acc, key) => {
    acc[key] = obj[key];
    return acc;
  }, {});
  return result;
}

export const getDataKey = (activeTab, activeCVGTab) => {
  const isCVGMGMT = activeTab === CVGMGMT_TAB;

  const tabMapping = {
    [PM_TAB]: "model",
    [WWNA_TAB]: "model",
    [MISC_TAB]: "dataMisc",
    [CAREPACK_TAB]: "dataCarePack",
    [CUSTOMSERVICE_TAB]: "dataCustomService",
    [DEPLOY_TAB]: "dataDeploy",
    [SPARES_TAB]: "dataSpares",
    [MANDA_TAB]: "dataMandA",
    [WWLA_TAB]: "dataWWLA",
    [WWEU_TAB]: "dataWWEU",
    [WWAP_TAB]: "dataWWAP",
    [NA_TAB]: "dataNA",
    [LA_TAB]: "dataLA",
    [EU_TAB]: "dataEU",
    [AP_TAB]: "dataAP",
    [NAGPSA_TAB]: "dataNAGPSA",
    [WW_TAB]: "dataWW",
    [REGIONAL_TAB]: "dataRegional"
  };

  if (tabMapping[activeTab]) {
    return tabMapping[activeTab];
  }

  if (isCVGMGMT && tabMapping[activeCVGTab]) {
    return tabMapping[activeCVGTab];
  }

  return activeTab;
};

export const processSavedChoice = (choice, { description, status, configRules }, dataType) => {
  const { choiceID, isHidden, isTemplate, isManual, selectionType, messages,dataType: choiceDataType, ...rest } = choice;

  return {
    ...rest,
    ...(formatSelectionType(selectionType)),
    messages: messages ? JSON.parse(messages) : [],
    [CONTENT_KEY]: formatChoiceText({ choiceID, description, status, isManual, isHidden, isTemplate, configRules, dataType: choiceDataType }, dataType),
    isHidden,
    isTemplate,
    isManual,
  }
}

export const processSavedItem = (item, { description, partNumber, status, region }) => {
  const { messages, defaultComponents, isTemplate, isManual, ...rest } = item;

  return {
    ...rest,
    isTemplate,
    isManual,
    messages: messages ? JSON.parse(messages) : [],
    defaultComponents: defaultComponents ? JSON.parse(defaultComponents) : [],
    cvgs: processCvg(item.cvg),
    [CONTENT_KEY]: formatItemText({isTemplate, isManual, description, partNumber, status, region }),
  }
}

export function getSyntaxErrors(condition) {
  return [
    { key: "invalidCondition", value: condition.invalidCondition},
    { key: "invalidChoiceIds", value: condition.invalidChoiceIds?.join(", ") },
    { key: "duplicateChoiceids", value: condition.duplicateChoiceids?.join(", ") },
    { key: "duplicateCVGs", value: condition.duplicateCVGs?.join(", ") },
    { key: "invalidExpressions", value: condition.invalidExpressions },
  ].filter(e => e.value)
}

export function mapToChoiceConditionNames(conditions) {
  return conditions.reduce((acc, condition) => {
    const conditionName = condition.conditionName;

    if (conditionName !== "MessageCondition") {
      acc[conditionName] = getSyntaxErrors(condition);
    } else if(!(acc[conditionName])) {
      acc[conditionName] = conditions
        .filter((condition) => condition.conditionName === conditionName)
        .reduce((acc, condition) =>{
          acc[condition.invalidCondition] =  getSyntaxErrors(condition);
          return acc
        }, {})
    }
    return acc;
  }, {})
}

export function mapToItemConditionNames(conditions) {
  return conditions.reduce((acc, condition) => {
    const conditionName = condition.conditionName;
    if (conditionName !== "MessageCondition" && conditionName !== "DefaultComponentCondition" ) {
      acc[condition.conditionName] = getSyntaxErrors(condition);
    } else if (!acc[conditionName]) {
      acc[conditionName] = conditions
        .filter((condition) => condition.conditionName === conditionName)
        .reduce((acc, condition) =>{
          acc[condition.invalidCondition] =  getSyntaxErrors(condition);
          return acc
        }, {})
    }
    return acc;
  }, {})
}

export function mapToItemDescriptionNames(conditions) {
  return conditions.reduce((acc, condition) => {
    acc[condition.descriptionName] = getItemDescriptionErrors(condition);
    return acc;
  }, {})
}

export function getItemDescriptionErrors(condition) {
  return [
    { key: "duplicateDescription", value: condition.duplicateChoiceItems?.map((item) => item.itemPartNumber).join(", ") },
    { key: "invalidDescription", value: condition.descriptionLength ? condition.invalidDescription : null },
  ].filter(e => e.value)
};

export const getCurrentVersion = (dataType, version) => {
  if (dataType === "model") return version;
  return dataType === "template" ? "1.0" : "1.1";
};