import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid2';
import Chip from '@mui/material/Chip';
import { SELECTED_OCPLC_KMAT } from 'constants/ocplcConstants';

const MultiValueInput = ({ ocplcSelectedKmats }) => {
    const dispatch = useDispatch();
    const [ocplcDownloadKmats, setOcplcDownloadKmats] = useState(ocplcSelectedKmats || []);

    const removeSelectedKmats = (value) => {
        const updatedSelectedKmats = ocplcDownloadKmats.filter((item) => item !== value);
        setOcplcDownloadKmats(updatedSelectedKmats);
        dispatch({ type: SELECTED_OCPLC_KMAT, payload: updatedSelectedKmats });
    };

    return (
        <div style={{marginTop: '10px'}}>
                {ocplcDownloadKmats.map((ocplcDownloadKmat) => (
                    <Chip
                        key={ocplcDownloadKmat}
                        label={ocplcDownloadKmat}
                        style={{ margin: '5px' }}
                        size='medium'
                        onDelete={() => removeSelectedKmats(ocplcDownloadKmat)}
                    >
                        {ocplcDownloadKmat}
                    </Chip>
                ))}
        </div>
    );
}

export default MultiValueInput;
