import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import React, { useState } from "react";
import Grid from '@mui/material/Grid2';

const useStyles = makeStyles((theme) => ({
  textValue: {
    color: "black",
    width: "100%",
    maxWidth: "500px",
    wordBreak: "break-word"
  },
  container: {
    border: "1px solid #D1F5CE ",
    backgroundColor: "#D1F5CE ",
    borderRadius: "10px",
    padding: "10px",
    marginTop: "10px",
    maxHeight: 450,
    overflow: "auto"
  },
  status: {
    border: "1px solid #1C7A17",
    background: "#1C7A17",
    borderRadius: "24px",
    padding: "5px 8px 0px 8px",
    fontFamily: "Forma DJR Micro !important",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#FFFFFF",
  },
  viewMoreButton: {
    padding: "5px 8px 1px 8px",
    color: "#1C7A17 !important",
    fontSize: "14px",
    cursor: "pointer"
  },
  labelText: {
    margin: "2px 0px 5px 11px !important",
    display: "flex !important",
    flexDirection: "row !important"
  },
  closeButton: {
    backgroundColor: "#EBF5FB !important",
    color: "#3498DB !important ",
  },
  actions : {
    justifyContent: "flex-start !important",
    marginLeft: "18px !important"
  },
  subHeader: {
    fontSize: "20px",
    fontWeight: 500,
  },
}))

const StatusChip = ({ data }) => {

  const statusChip = data?.status && JSON.parse(data.status);
  const configData = statusChip?.fields && statusChip?.fields.find((item) => item.name === "config_rules")
  const cvgsData = statusChip?.fields && statusChip?.fields.find((item) => item.name === "cvgs")
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Grid className={classes.labelText} >
        {statusChip && statusChip?.fields && statusChip?.status === "updated" && (
          <Typography style={{ display: "flex", flexDirection: "row" }}>
            <span className={classes.status}>{statusChip.status.charAt(0).toUpperCase() + statusChip.status.slice(1)}</span>
            <span className={classes.viewMoreButton} onClick={() => handleOpen()}>View More.</span>
          </Typography>
        )}

        {open &&
          <Dialog PaperProps={{ style: { borderRadius: 10, minWidth: 900, padding: 12, margin: 0 } }} open={open} onClose={handleClose}>
            <DialogTitle variant='h5'> {"Updated Values"}</DialogTitle>
            <DialogContent>
              <Grid container className={classes.container}>
                <Grid size={6}>
                  <Typography className={classes.subHeader}>Old Values</Typography>
                  <Typography><b>Allowed CVGs : </b><span className={classes.textValue}>{cvgsData ? (cvgsData.old ? cvgsData.old : "None") : "None"}</span> </Typography>
                  <Typography><b>Config Rules : </b><span className={classes.textValue}>{configData ? (configData.old ? configData.old?.split("\n").map((i, key) => { return <div key={key}>{i}</div> }) : "None") : "None"}</span></Typography>
                </Grid>
                <Grid size={6}>
                  <Typography className={classes.subHeader}>New Values</Typography>
                  <Typography><b>Allowed CVGs : </b><span className={classes.textValue}>{cvgsData ? (cvgsData.new ? cvgsData.new : "None") : "None"}</span> </Typography>
                  <Typography><b>Config Rules : </b><span className={classes.textValue}>{configData ? (configData.new ? configData.new?.split("\n").map((i, key) => { return <div key={key}>{i}</div> }) : "None") : "None"}</span></Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.actions}>
              <Button variant="contained" className={classes.closeButton} onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </Dialog>}
      </Grid>
    </>
  );
}
export default StatusChip;
