import React, { useRef } from "react";
import Grid from "@mui/material/Grid2";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import ModelField from "./ModelField";
import productLine from "assets/i/productLine.svg";
import version from "assets/i/version.svg";
import Img from "components/Img";
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { useStyles } from "./ModelHeaderHelpers";
import { useSelector, useDispatch } from "react-redux";
import RegionalStepperPanel from "components/StepperPanel/regionalIndex";
import StepperPanel from "components/StepperPanel";
import { updateProductName } from "actions/modelActions";
import IconButton from '@mui/material/IconButton';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import Box from '@mui/material/Box';

const KmatHeaderCard = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const inputRef = useRef(null);

    const versions = useSelector((state) => state.models.versions);
    const ocplcProductName = useSelector((state) => state.models.kmat.ocplcProductName);

    const handleSave = () => {
        const productName = inputRef.current.value;
        dispatch(updateProductName(productName));
    }

    const headerCard = [
        {
            primaryText: "Product Line",
            secondaryText: props?.kmat?.lineName,
            avatarSrc: <Img src={productLine} className={classes.cardView} />,
            show: true,
            isClickable: false
        },
        {
            primaryText: "Manufacturing Name",
            secondaryText: props?.kmat?.productName,
            avatarSrc: (
                <DesktopWindowsOutlinedIcon htmlColor="#0278AB" className={classes.cardView} />
            ),
            show: true,
            isClickable: false,
        },
        {
            primaryText: "KMAT-ID",
            secondaryText: props?.kmat?.kmatID,
            avatarSrc: (
                <GradeOutlinedIcon htmlColor={props?.kmat?.isReleased ? "#0278AB" : "green"} className={classes.cardView} />
            ),
            show: props?.dataType !== "template",
            isClickable: false,
        },
        {
            primaryText: "Product Name",
            avatarSrc: <DesktopWindowsOutlinedIcon htmlColor="#0278AB" className={classes.cardView} />,
            renderComponent: (header) => (
                props.dataType === 'model' && (
                    <Grid item style={{ flex: '1 1 auto' }}>
                        <Paper elevation={0} className={`${classes.paper} ${header.isClickable && 'paper-click-element'}`} style={{ cursor: 'pointer' }}>
                            <ListItem>
                                <ListItemAvatar>{header.avatarSrc}</ListItemAvatar>
                                <ListItemText 
                                    primary={header.primaryText} 
                                    secondary={
                                        <div style={{ position: 'relative' }}>
                                            <input
                                                type="text"
                                                defaultValue={ocplcProductName}
                                                ref={inputRef}
                                                className={`${classes.input} ${classes.noBorderInput}`}
                                                style={{ paddingRight: '30px' }}
                                            />
                                            <IconButton 
                                                onClick={handleSave} 
                                                className={classes.saveButton} 
                                                style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}
                                            >
                                                <CheckBoxOutlinedIcon/>
                                            </IconButton>
                                        </div>
                                    }
                                    style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                                />
                            </ListItem>
                        </Paper>
                    </Grid>
                )
            ),
            show: true,
            isClickable: true,
        },
        {
            primaryText: "PM File",
            secondaryText: props?.kmat?.pmFile,
            avatarSrc: <DescriptionOutlinedIcon htmlColor="#0278AB" fontSize='large' className={classes.cardView} />,
            customGrid: 3,
            show: props?.dataType !== 'regional',
            isClickable: false,
        },
        {
            primaryText: "Version",
            avatarSrc: <Img src={version} className={classes.cardView} />,
            renderComponent: (header) => (
                <ModelField
                    key={"version"}
                    caption="Version"
                    currentVersion={props?.currentVersion}
                    header={header}
                    kmatID={props?.kmatID}
                    versions={versions}
                />
            ),
            show: props?.dataType === 'model',
            isClickable: true,
            customGrid: 1,
        },
    ];

    return (
        <>
            <div className={classes.root}>
            <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
                    {headerCard.map((header, index) => {
                        return (
                            header.show && (
                                header.isClickable
                                    ?
                                    header.renderComponent(header)
                                    :
                                    <Grid size={header.customGrid || 2} key={index + "-grid-element"}>
                                        <Paper elevation={0} className={`${classes.paper} ${header.isClickable && 'paper-click-element'}`} style={header.isClickable ? { cursor: 'pointer' } : {}}>
                                            <ListItem>
                                                <ListItemAvatar>{header.avatarSrc}</ListItemAvatar>
                                                <ListItemText primary={header.primaryText} secondary={header.secondaryText} />
                                            </ListItem>
                                        </Paper>
                                    </Grid>
                            )
                        );
                    })}
                </Grid>

                {props?.dataType === 'regional'
                    ? <RegionalStepperPanel kmatID={props?.kmatID} currentVersion={props?.currentVersion} dataType={props?.dataType} />
                    : <StepperPanel kmatID={props?.kmatID} currentVersion={props?.currentVersion} dataType={props?.dataType} />
                }
            </div>
        </>
    );
};

export default KmatHeaderCard;
