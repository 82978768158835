import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import CheckIcon from '@mui/icons-material/Check';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './ocplcHelpers';
import { MATERIAL_AVAILABILITY, VERSION_0002, roleRegionMap } from 'constants/ocplcConstants';
import { capitalizeFirstLetter, filterDetails, findSelectedRole, getPlcRoles, getVersion, isPLCAdmin } from 'pages/Utils/OcplcUtil';
import PlcEditDashboard from 'pages/PlcDashboard/PlcEditDashboard';
import { OcplcKmatLockingQuery, filterOcplcKmat, getOcplcBusinessModels, ocplcRegionDropDown, ocplcTableData } from 'actions/ocplcAction';
import Box from '@mui/material/Box';
import UploadDownloadDataTransIcons from './UploadDownloadDatatransIcons';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';

const OcplcEditPage = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const { email, ocplcRole, roles } = props.auth;
    const { kmatId, type, productGroup, productLineID } = useParams();
    const version = getVersion(type);
    const tableData = useSelector(state => state.ocplc.tableData)
    const filterKmats = useSelector(state => state.ocplc.filterKmats)
    const regionDropdown = useSelector(state => state.ocplc.regionDropdown)
    const [selectedTable, setSelectedTable] = useState('Model Availability');
    const [selectKmat, setSelectedKmat] = useState(kmatId)
    const selectedDefaultRole = findSelectedRole(roles, ocplcRole);
    const [assignedRole, setAssignedRole] = useState(selectedDefaultRole?.name);
    const [selectedRegion, setSelectedRegion] = useState(roleRegionMap[selectedDefaultRole.name]);
    // const status = capitalizeFirstLetter(rowData?.status);
    const [checkedRows, setCheckedRows] = useState([]);
    const ocplcAdmin = isPLCAdmin(ocplcRole);
    const plcRoles = getPlcRoles(roles);
    const showLockandDownload = selectedTable === MATERIAL_AVAILABILITY

    useEffect(() => {
        dispatch(ocplcTableData())
        dispatch(ocplcRegionDropDown(ocplcRole));
        dispatch(filterOcplcKmat(productLineID))
        dispatch(getOcplcBusinessModels())
    }, [])

    if (!kmatId || !version || !selectedRegion || !productGroup || !productLineID) return null;

    const handleSelectItem = (e) => {
        setSelectedTable(e.target.value);
    };

    const handleRolesSelection = (e) => {
        const selectedRole = e.target.value;
        setAssignedRole(selectedRole);
        if (selectedRole) {
            const role = plcRoles.find(role => role.name === selectedRole);
            if (role) {
                setSelectedRegion(roleRegionMap[selectedRole]);
                dispatch(ocplcRegionDropDown(role?.id));
            }
        }
        setCheckedRows([])
    };

    const handleKmatSelection = (e) => {
        setSelectedKmat(e.target.value)
        if (version === VERSION_0002) {
            dispatch(OcplcKmatLockingQuery(kmatId, ocplcAdmin, email, "", navigate, productGroup, type, productLineID));
        }
    }

    const handleClearKmat = () => {
        setSelectedKmat('')
        setCheckedRows([])
    }

    const handleRegionSelection = (e) => {
        setSelectedRegion(e.target.value)
        setCheckedRows([])
    }

    const details = [
        { label: "Table Name", value: selectedTable, color: "#B0B0B0" },
        { label: "Version", value: version, color: "#B0B0B0" },
        { label: "Region", value: selectedRegion, color: "#B0B0B0" },
        { label: "Product Family", value: selectKmat, color: "#B0B0B0" },
        { label: "Product Group", value: productGroup, color: "#B0B0B0" },
        { label: "Status", value: "In Progress", color: "#1976D2" }
    ];

    const filteredDetails = filterDetails(details, selectKmat, version);

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: "space-between" }}>
                <div className={classes.divContainer}>
                    <div className={classes.headerChildren}>
                        <FormControl fullWidth>
                            <InputLabel sx={{ marginTop: "8px !important" }}>Role</InputLabel>
                            <Select
                                className={classes.regionSelect}
                                value={assignedRole}
                                renderValue={(selected) => selected}
                                onChange={handleRolesSelection}
                                disabled={plcRoles.length === 1}
                            >
                                {plcRoles.map(role => (
                                    <MenuItem key={role.id} value={role.name}>
                                        {role.name}{assignedRole === role.name && <CheckIcon className={classes.checkIcon} />}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.headerChildren}>
                        <FormControl fullWidth>
                            <InputLabel sx={{ marginTop: "8px !important" }}>Table</InputLabel>
                            <Select
                                value={selectedTable || ''}
                                onChange={handleSelectItem}
                                className={classes.regionSelect}
                                renderValue={(selected) => selected}
                                MenuProps={{ PaperProps: { className: classes.paperProps } }}
                            >
                                {tableData?.map((item, index) => [
                                    <MenuItem key={index} value={item.subCategory}>
                                        {item.subCategory}
                                        {selectedTable === item.subCategory && <CheckIcon className={classes.checkIcon} />}
                                    </MenuItem>
                                ])}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.headerChildren}>
                        <FormControl fullWidth>
                            <InputLabel sx={{ marginTop: "8px !important" }}>Region</InputLabel>
                            <Select
                                className={classes.regionSelect}
                                value={selectedRegion || ''}
                                onChange={handleRegionSelection}
                                renderValue={(selected) => selected}
                            >
                                {regionDropdown?.map((region, index) => (
                                    <MenuItem key={index} value={region.code}>
                                        {region.code} | {region.name}
                                        {selectedRegion === region.code && <CheckIcon className={classes.checkIcon} />}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className={classes.headerChildren}>
                        <FormControl fullWidth>
                            <InputLabel sx={{ marginTop: "8px !important" }} shrink={true}>Product Family</InputLabel>
                            <Select
                                className={classes.regionSelect}
                                value={selectKmat || ''}
                                onChange={handleKmatSelection}
                                renderValue={(selected) => selected}
                                MenuProps={{ PaperProps: { className: classes.paperProps } }}
                                endAdornment={selectKmat &&
                                    <InputAdornment position="start" style={{ cursor: 'pointer' }}>
                                        <CloseIcon className='clear-filter' onClick={(e) => { e.stopPropagation(); handleClearKmat() }} />
                                    </InputAdornment>}
                            >
                                {filterKmats?.length > 0 ? (
                                    filterKmats.map((kmat, index) => (
                                        <MenuItem key={index} value={kmat}>{kmat}</MenuItem>
                                    ))
                                ) : (
                                    <MenuItem disabled>No Data Found</MenuItem>
                                )}
                            </Select>
                        </FormControl>

                    </div>
                </div>
                <Box sx={{ marginTop: "15px" }}>
                    <UploadDownloadDataTransIcons kmatId={selectKmat} productGroup={productGroup} productLineID={productLineID} tableData={tableData} showLockandDownload={showLockandDownload} showUpload={false} title={"ocplcEdit"} version={version} userRole={ocplcRole} region={selectedRegion} assignedRole={assignedRole} checkedRows={checkedRows} setCheckedRows={setCheckedRows} userEmail={email} />
                </Box>
            </Box>

            <div style={{ marginBottom: "15px" }}>
                {selectedTable && (
                    <span>
                        {filteredDetails.map((detail, index) => (
                            <span key={index}>
                                <span style={{ color: "#333" }}>{detail.label}:</span> &nbsp;
                                <span style={{ color: detail.color }}>{detail.value}</span>
                                {index < filteredDetails.length - 1 && " | "}
                            </span>
                        ))}
                    </span>
                )}
            </div>

            <PlcEditDashboard assignedRole={assignedRole} kmatId={selectKmat} version={version} productGroup={productGroup} selectedTable={selectedTable} region={selectedRegion} regionDropdown={regionDropdown} userRole={ocplcRole} userEmail={email} productLineID={productLineID} checkedRows={checkedRows} setCheckedRows={setCheckedRows} />
        </>
    );
};
export default OcplcEditPage;