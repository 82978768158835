import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { Badge, IconButton, TextField } from "@mui/material";
import { alpha } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: 3,
    width: "100%",
    minHeight: "850px",
    flexGrow: 1,
    '& .MuiFilledInput-root': {
      border: '1px solid #ABB2B9',
      boxShadow: "0.5px 0.5px #ABB2B9",
      fontFamily: "Forma DJR Micro !important",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: "400 !important",
      borderRadius: 12,
      backgroundColor: 'transparent',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&.Mui-disabled': {
        backgroundColor: 'whitesmoke',
      },
      '&.Mui-focused': {
        backgroundColor: 'transparent',
        borderColor: theme.palette.primary.main,
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      },
    },
  },

  tableRow: {
    border: "1px solid darkgray",
    padding: "0 5px",
    ".MuiTableRow-root.MuiTableRow-hover&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "lightblue",
      "& > .MuiTableCell-root": {
        color: "black",
      },
    },
  },
  hoverable: {
    border: "1px solid darkgray",
    "&:hover": {
      color: `${theme.palette.primary.normal}`,
      cursor: `pointer`,
    },
  },
  brand: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    borderBottom: "2px solid lightgray",
  },
  cvgPaper: {
    height: "100%",
    maxHeight: "100%",
    overflow: "hidden",
    marginTop: "7px",
    zIndex: "1000",
    borderRadius: "16px",
    padding: theme.spacing(1),
  },
  badge: {
    top: 0,
    right: 0,
    transform: "scale(1) translate(50%, -50%)",
    transformOrigin: "100% 0%",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "95%",
    fontSize: "16px",
    fontFamily: "Forma DJR Micro !important",
    flex: "1",
    marginRight: "5px",
    marginBottom: "5px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  treeItemContainer: {
    padding: 0,
    margin: 0,
    listStyle: "none",
    display: "flex",
    justifyContent: "space-between",
  },
  publishLoading: {
    position: "fixed",
    top: "50%",
    left: "50%",
    zIndex: "99"
  },
  titleDescription: {
    display: "inline-flex",
    flex: 1,
    gap: "6px",
    fontSize: "xx-large",
  },
  subTitle: {
    color: "#F0F3F4",
    marginTop: "9px",
    marginLeft: "9px",
  },
  keyboardIcon: {
    position: "relative",
    top: "20px",
    left: "5px",
    cursor: "pointer"
  },
  choiceText: {
    flexGrow: 1,
    padding: "3px",
    fontFamily: "Forma DJR Micro !important",
    fontSize: "16px !important",
    color: "#000000"
  },
  titleHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100% !important",
    marginTop: "10px"
  },
  editTitle: {
    marginTop: "5px",
    marginBottom: "5px",
    fontSize: "36px",
    lineHeight: "32px",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    color: "#212121",
  },
  status: {
    border: "1px solid #1C7A17",
    background: "#f50057",
    borderRadius: "24px",
    padding: "5px 8px 4px 8px",
    fontSize: "12px",
    fontFamily: "Forma DJR Micro !important",
    lineHeight: "16px",
    color: "#FFFFFF",
    marginTop: "9px",
    marginBottom: "20px",
  },
  cvgEditor: {
    marginTop: "5px",
    fontSize: "36px",
    fontFamily: "Forma DJR Micro !important",
    lineHeight: "32px",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    marginBottom: "5px"
  },
}));

export const useCommonStyles = makeStyles((theme) => ({
  noItem: {
    borderBottom: '1px solid #ADADAD',
    borderTop: '1px solid #ADADAD',
    color: theme.palette.text.primary,
    fontSize: "20px",
    marginBottom: "15px",
    marginTop: "11px",
    padding: "20px 0px 15px 0px",
    textAlign: 'center'
  },
  cvg: {
    flexGrow: 1,
    display: "flex",
    margin: "1px",
    padding : "6px 6px 4px 11px !important",
    color: "black !important",
    lineHeight: "27px",
    marginLeft: "20px",
    marginRight: "20px",
    "& > span": {
        textDecoration: "none",
        color: "#333",
    },
    "&.selected": {
        backgroundColor: "rgba(2, 120, 171, 0.1) !important",
        borderRadius: "8px",
        color: "#014667 !important",
    },
    "&.disabled": {
        color: "gray",
    },
    "&:hover, &:focus": {
        cursor: "pointer",
        backgroundColor: "lightgray",
        "&.selected": {},
    },
},
  actionableButtons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "30px !important",
    marginRight: "8px"
  },
  popperContainer: {
    margin: 0,
    display: "flex",
    flexDirection: "column",
    minHeight: "100%",
  },
  popperHeader: {
    order: 1,
    display: "flex",
    alignSelf: "stretch",
    fontFamily: "Forma DJR UI",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "24px !important",
    lineHeight: "32px !important",
    marginLeft: "15px",
    marginBottom: "10px",
    alignItems: "center",
  },
  popperList: {
    flexGrow: 1,
    height: "calc(100vh - 60px)",
    maxHeight: "100%",
    overflowX: "hidden",
    overflowY: "scroll",
    width: "100%",
    overscrollBehavior: "contain",
    transition: "none",
    order: 2,
    fontFamily: "Forma DJR Micro !important",
    scrollbarWidth: "thin" /*Firefox*/,
    "&::-webkit-scrollbar": {
      width: "15px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgb(179, 178, 178)",
      borderRadius: "50px",
      border: "1px solid rgb(179, 178, 178)",
    },
  },
}));


export const CustomSearchField = withStyles({
  root: {
    '& .MuiInputBase-root': {
      borderRadius: '12px',
      height: '45px',
      width: '100%',
    },
    flex: "1",
    marginTop: "8px",
    marginLeft: "5px",
    marginRight: "10px",
    position: 'relative',
  },
})(TextField);

export const searchFieldProps = {
  id: "searchfield",
  name: "searchfield",
  variant: "outlined",
};

export const CustomSearch = ({ value, handleClearSearch, ...props }) => {
  return (
    <CustomSearchField
      {...props}
      {...searchFieldProps}
      value={value}
      style={props.style ? props.style : { width: "220px" }}
      slotProps={{
        input: {
          ...props.InputProps,
          endAdornment: (
            <>
              {value ? (
                <IconButton style={{ position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)' }} onClick={handleClearSearch} >
                  <ClearIcon />
                </IconButton>
              ) : (
                <SearchIcon style={{ marginRight: '8px', color: 'rgba(0, 0, 0, 0.54)' }} />
              )}
            </>
          ),
        }
      }}
    />
  );
};

export function useAutoFocus(textFieldRef) {
  useEffect(() => {
    if (textFieldRef.current) {
      textFieldRef.current.focus();
      const length = textFieldRef.current.value?.length;
      textFieldRef.current.setSelectionRange(length, length);
    }
  }, [textFieldRef]);
}

export const CountBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "lightGrey",
    right: 9,
    margin: "13px 10px 0px 0px",
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "8px 9px 4px 9px !important",
    fontFamily: "Forma DJR Micro !important",
    overlap: "rectangular"
  },
}))(Badge);
