/* eslint-disable import/no-anonymous-default-export */
import Cookies from "js-cookie";
import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    RECEIVE_ACCESS_TOKEN,
    CLEAR_USER_INFO,
    TOKEN_RENEW_REQUEST,
    TOKEN_RENEW_SUCCESS,
    TOKEN_RENEW_FAIL,
    KB_USER_DATA,
    SET_ALL_USERS,
    SET_ROLE_LIST
} from "constants/userConstants";

const defaultState = {
    loading: false,
    userInfo: Cookies.get("userInfo") ? JSON.parse(Cookies.get("userInfo")) : null,
    token: Cookies.get("token"),
    expiresAt: null,
    error: null,
    kbUser: null,
    allUsers: [],
    roleList: [],
};

export default (state = defaultState, { type, payload }) => {
    switch (type) {
        case USER_LOGIN_REQUEST:
            return { loading: true };
        case USER_LOGIN_SUCCESS:
            return {
                loading: false,
                userInfo: payload.data,
                token: payload.token,
                error: null,
            };
        case KB_USER_DATA:

            return {
                ...state,
                kbUser: payload,
                userInfo: { ...state.userInfo, role: payload?.role },
            }
        case TOKEN_RENEW_SUCCESS:
            return {
                ...state,
                loading: false,
                token: payload,
            };
        case USER_LOGIN_FAIL:
            return {
                loading: false,
                userInfo: null,
                token: null,
                expiresAt: null,
                error: payload
            };
        case TOKEN_RENEW_FAIL:
            return {
                loading: false,
                userInfo: null,
                token: null,
                expiresAt: null,
                error: payload
            };
        case TOKEN_RENEW_REQUEST:
            return {
                loading: true,
                token: null,
                expiresAt: null
            };
        case RECEIVE_ACCESS_TOKEN:
            return {
                ...state,
                loading: false,
                userInfo: { ...payload.user, role: state?.kbUser?.role },
                token: payload.token,
                expiresAt: payload.expiresAt
            };
        case CLEAR_USER_INFO:
            return {
                userInfo: null,
                token: null,
                expiresAt: null,
                error: null
            };
        case SET_ALL_USERS:
            return {
                ...state,
                allUsers: payload
            }
        case SET_ROLE_LIST:
            const remodeledList = payload.map(item => ({ value: item.id, label: item.name }));
            return {
                ...state,
                roleList: remodeledList
            }
        default:
            return state;
    }
}
