import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { Tab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TemplatesDashboard from 'pages/TemplatesDashboard';
import UISpecs from 'pages/UI-Specs';
import UserAccessManagement from './UserAccessManagement';
import { useEffect } from 'react';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '.5px solid #ADADAD',
    width: '420px',
    marginTop: '8px',
  },
  tabs: {
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    marginRight: '16px',
    minWidth: "120px"
  },
  activeTab: {
    borderBottom: '2px solid #0096d6 !important',
    color: '#0096d6 !important',
  },
}));

const tabs = [
  { label: 'Users', value: 'users', component: <UserAccessManagement /> },
  { label: 'Templates', value: 'templates', component: <TemplatesDashboard /> },
  { label: 'UI-Specs', value: 'ui-specs', component: <UISpecs /> },
];

function AdminPanelTab() {
  const [activeTab, setActiveTab] = useState("users");
  const classes = useStyles();
  const params = useParams();

  useEffect(() => {
    // Set activeTab based on the route parameter
    setActiveTab(params.tab || 'users');
  }, [params.tab]);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
    window.history.pushState(null, "", `/admin/${tabIndex}`);
  };

  return (
    <>
      <div className={classes.root}>
        {tabs.map(({ label, value }) => (
          <Tab
            key={value}
            className={`${classes.tabs} ${activeTab === value && classes.activeTab}`}
            label={label}
            onClick={() => handleTabClick(value)}
          />
        ))}
      </div>
      {tabs.find((tab) => tab.value === activeTab)?.component}
    </>
  )
}

export default AdminPanelTab;
