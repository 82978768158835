import React, { memo } from "react";
import PropTypes from "prop-types";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ButtonSpinner from "./ButtonSpinner";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  content: {
    fontWeight: 400,
    height: "auto",
    fontSize: "16px",
    lineHeight: "23px !important",
    marginBottom: "12px !important",
  },
  action: {
    justifyContent: 'flex-start !important',
    marginLeft: "10px !important",
    marginBottom: "8px !important",
  },
  buttons: {
    borderRadius: "10px !important",
    padding: "7px 20px 7px 20px !important",
    color: "white",
  },
  disabledButton: {
    backgroundColor: "#d3d3d3 !important",
    borderColor: "#d3d3d3 !important",
    color: "#a9a9a9 !important",
  },
}));

function ConfirmationDialog(props) {
  const { open, onClose, loading, title, content, onConfirm, caption, bgColor, width = 480, secondaryCaption = "Cancel", BackdropProps, PaperProps, children, disabled } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      disablebackdropclick={loading}
      disableEscapeKeyDown={loading}
      PaperProps={{ style: { borderRadius: 10, width: width, padding: 7, margin: 0, ...PaperProps?.style } }}
      slotProps={{ backdrop: BackdropProps }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography className={classes.content}>{content}</Typography>
        {children}
      </DialogContent>
      <DialogActions className={classes.action}>
            <Button style={{ borderRadius: "10px", padding: "7px 20px" }} variant="outlined" onClick={onClose} disabled={loading}>
              {secondaryCaption}
            </Button>
            {caption && (
              <Button
                className={`${classes.buttons} ${disabled ? classes.disabledButton : ''}`}
                onClick={onConfirm}
                disabled={loading || disabled}
                variant="outlined"
                style={{ backgroundColor: disabled ? "#d3d3d3" : bgColor, borderColor: disabled ? "#d3d3d3" : bgColor }}
              >
                {caption} {loading && <ButtonSpinner />}
              </Button>
            )}
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onConfirm: PropTypes.func,
  caption: PropTypes.string,
  bgColor: PropTypes.string,
  width: PropTypes.number,
  BackdropProps: PropTypes.object,
  PaperProps: PropTypes.object,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

export default memo(ConfirmationDialog);