export const getCurrentId = (props) => {
    const { newIndex, elementIndex, elementsList } = props;
    elementIndex.current = newIndex;
    const currentElement = elementsList.current[newIndex];
    const divElement = currentElement.getElementsByTagName("div")[0];
    const currentId = divElement.id;
    return { currentId,currentElement };
}
export const getIdFromElementsList = (props) => {
    const { currentId, nodesFromApi, selectedNode, announceSelection } = props;

    let foundNode = nodesFromApi.items.find(node => {
        if (node.choiceID === currentId) {
            selectedNode.current = node;
            return true;
        }

        if (node.items && node.items[currentId]) {
            selectedNode.current = { ...node.items[currentId] };
            return true;
        }

        return false;
    });

    if (foundNode) {
        announceSelection(selectedNode.current);
    }
};
