export const modelAvailability = `query modelAvailability($version: String!, $region: String!, $productFamily: String!, $productGroup: String!, $productLine: String!) {
        modelAvailability(filter: {
            version: $version,
            region: $region,
            productFamily: $productFamily,
            productGroup: $productGroup,
            productLine: $productLine
        }) {
            models {
                pkId
                version
                region
                country
                countryDesc
                productFamily
                productDesc
                businessModel
                productGroup
                comments
                status
                apRegion
                euRegion
                naRegion
                laRegion
                whoChanged
                startDate
                endDate
                lastModifiedDate
                createDate
                visibleDate
            }
        }
    }
`;

export const otherRegional = `query otherRegional($version: String!, $region: String!, $productFamily: String!, $productGroup: String!, $productLine: String!) {
    otherRegional(filter: {
        version: $version,
        region: $region,
        productFamily: $productFamily,
        productGroup: $productGroup,
        productLine: $productLine
    }) {
        models {
      pkId
      version
      region
      country
      countryDesc
      productFamily
      productDesc
      businessModel
      productGroup
      comments
      status
      whoChanged
      wwStartDate
      wwEndDate
      startDate
      endDate
      lastModifiedDate
      createDate
      visibleDate
      p1S
      p1SName
      p1E
      p1EName
      p2S
      p2SName
      p2E
      p2EName
      p3S
      p3SName
      p3E
      p3EName
      p4S
      p4SName
      p4E
      p4EName
      p5S
      p5SName
      p5E
      p5EName
      p6S
      p6SName
      p6E
      p6EName
      p7S
      p7SName
      p7E
      p7EName
      p8S
      p8SName
      p8E
      p8EName
      p9S
      p9SName
      p9E
      p9EName
      apRegion
      euRegion
      naRegion
      laRegion
    }
    }
}`;
