import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { createLogger } from "redux-logger";
import { createStore, applyMiddleware, compose } from "redux";
import { thunk } from "redux-thunk";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme
} from "@mui/material/styles";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import store from "./store";
import allReducers from "./reducers";
import { enableMapSet, enablePatches } from 'immer';

enableMapSet();
enablePatches();

const middlewares = [thunk];
// Only use the redux-logger middleware in development
if (process.env.NODE_ENV === "DEV") {
  middlewares.push(createLogger());
}
const theme = createTheme()
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true }) || compose;

const store = createStore(
  allReducers,
  composeEnhancer(applyMiddleware(...middlewares))
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>
    </ThemeProvider>
  </StyledEngineProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();