import React from 'react';
import { useGridApiContext, useGridSelector, gridPageSelector, gridPageSizeSelector, gridRowCountSelector } from '@mui/x-data-grid';

import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { paginationContainerStyles, rowsPerPageContainerStyles, typographyStyles, selectStyles, spanStyles } from './ocplcHelpers';

const CustomPagination = ({ pageSizeOptions }) => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
    const rowCount = useGridSelector(apiRef, gridRowCountSelector);

    const handlePageSizeChange = (event) => {
        apiRef.current.setPageSize(event.target.value);
    };

    return (
        <div style={paginationContainerStyles}>
            <div style={rowsPerPageContainerStyles}>
                <Typography style={typographyStyles}>Rows Per Page:
                    <Select value={pageSize} onChange={handlePageSizeChange} style={selectStyles}>
                        {pageSizeOptions.map((size) => (
                            <MenuItem sx={{ fontSize: "14px" }} key={size} value={size}>{size}</MenuItem>
                        ))}
                    </Select>
                    <span style={spanStyles}>of {rowCount}</span>
                </Typography>
            </div>
            <Pagination
                sx={(theme) => ({ padding: theme.spacing(1.5, 0) })}
                shape="rounded"
                className="data-grid-pagination"
                color="primary"
                count={Math.ceil(rowCount / pageSize)}
                page={page + 1}
                onChange={(event, value) => apiRef.current.setPage(value - 1)}
            />
        </div>
    );
};

export default CustomPagination;