import { GraphqlApi } from 'services/api';
import { snackBar } from './modelActions';
import { BUSINESS_MODEL, COUNTRY_LIST, FILTER_BY_STATUS, LOCK_STATUS_DASHBOARD, MODEL_AVAILABILITY, REGION_DROPDOWN, VERSION_0002, TOF_AUDIT_TRAIL, CREATE_MODEL_AVAILABILITY, GET_PRODUCT_LINE, UPDATE_MODEL_AVAILABILITY, CREATE_EXISTING_MODEL_AVAILABILITY, MODULE_WISE_DATA_TRANSPORT, COMPLETE_MODEL_AVAILABILITY, DELETE_MODEL_AVAILABILITY, DASHBOARD_DATA_TRANSPORT } from 'constants/ocplcConstants';
import { regionalFields } from 'pages/PlcDashboard/columnTypes';
import { currentDate } from 'pages/Utils/OcplcUtil';

export const filterOcplcKmat = (productLineID) => async (dispatch) => {
    try {
        const res = await GraphqlApi.query('getOcplcProductFamily', {filter: { productLine : productLineID }});
        dispatch({ type: "FILTER_KMAT", payload: res?.data?.ocplcKmatProductFamily?.productFamily })
    } catch (error) {
        dispatch({ type: "FILTER_KMAT", payload: [] })
        dispatch(snackBar(true, error.message, "error"));
    }
}
export const refreshOcplc = () => async (dispatch) => {
    try {
        const res = await GraphqlApi.query('ocplcDashboard');
        const ocplcData = res?.data?.ocplcDashboard;
        dispatch({ type: "REFRESH_OCPLC", payload: ocplcData });
    } catch (error) {
        dispatch({ type: "REFRESH_OCPLC", payload: { ocplcData: [] } });
        dispatch(snackBar(true, error.message, "error"));
    }
};

export const clearOcplcFilters = () => (dispatch) => {
    dispatch({ type: "CLEAR_OCPLC_FILTERS" });
}

export const filterOcplc = (payload) => (dispatch) => {
    dispatch({ type: "FILTER_OCPLC", payload });
}

export const filterOcplcDashboard = (payload) => (dispatch) => {
    dispatch({ type: "FILTER_OCPLC_EDIT_DASHBOARD", payload });
}

export const filterByStatus = (payload) => (dispatch) => {
    dispatch({ type: FILTER_BY_STATUS, payload   });
}

export const ocplcTableData = () => async (dispatch) => {
    try {
        const res = await GraphqlApi.query('ocplcTableDropDown');
        const tableData = res?.data?.ocplcTableDropDown.tables;
        dispatch({ type: "OCPLC_TABLE_DATA", payload: tableData });
    } catch (error) {
        dispatch({ type: "OCPLC_TABLE_DATA", payload: [] });
        dispatch(snackBar(true, error.message, "error"));
    }
};

export const modelAvailability = (version, region, productFamily, productGroup, productLine) => async (dispatch) => {
    try {
        const modelApi = region === "WW" ? "modelAvailability" : "otherRegional";
        const res = await GraphqlApi.query(modelApi, { version, region, productFamily, productGroup, productLine });

        const modelAvailability = res?.data?.[modelApi]?.models || [];
        dispatch({ type: MODEL_AVAILABILITY, payload: modelAvailability });
    } catch (error) {
        dispatch({ type: MODEL_AVAILABILITY, payload: { modelAvailability: [] } });
        dispatch(snackBar(true, error.message, "error"));
    }
};

export const OcplcKmatLockingQuery = (kmatId, isPLCAdmin, loginUserEmailId, status, navigate, productGroup, type, productLineID) => async (dispatch) => {

    try {
        const res = await GraphqlApi.query("OcplcKmatLockingQuery", {
            kmatId: kmatId,
            isAdmin: isPLCAdmin,
            loginUserEmailId: loginUserEmailId,
            status: status,
            isBack: false
        });

        const lockStatus = res.data.ocplcKmatLocking;
        if (lockStatus.isError) {
            dispatch({ type: LOCK_STATUS_DASHBOARD, payload: lockStatus });
        } else {
            navigate(`/ocplc/${type}/${kmatId}/${productGroup}/${productLineID}`)
        }

    } catch (error) {
        dispatch({ type: LOCK_STATUS_DASHBOARD, payload: error.message });
        dispatch(snackBar(true, error.message, "error"));
    }
}

export const countryList = (region) => async (dispatch) => {
    try {
        const res = await GraphqlApi.query("ocplcCountryDropDown", { filter: { region: region } });
        const countryList = res?.data?.ocplcCountryDropDown?.countries || [];
        dispatch({ type: COUNTRY_LIST, payload: countryList });
    } catch (error) {
        dispatch({ type: COUNTRY_LIST, payload: { countryList: [] } });
        dispatch(snackBar(true, error.message, "error"));
    }
}

export const getOcplcBusinessModels = () => async (dispatch) => {
    try {
        const res = await GraphqlApi.query("getOcplcBusinessModel")
        const businessModels = res?.data?.ocplcBusinessModel?.businessModels || [];
        dispatch({ type: BUSINESS_MODEL, payload: businessModels });
    } catch (error) {
        dispatch({ type: BUSINESS_MODEL, payload: error.message });
    }
};

export const ocplcRegionDropDown = (role) => async (dispatch) => {
    try {
        const res = await GraphqlApi.query("ocplcRegionDropDown",{
            filter: { role: role }
        });
        const regions = res?.data?.ocplcRegionDropDown?.regions || [];
        dispatch({ type: REGION_DROPDOWN, payload: regions });
    } catch (error) {
        dispatch({ type: REGION_DROPDOWN, payload: [] });
        dispatch(snackBar(true, error.message, "error"));
    }
}

export const deleteModelAvailabilityAction = ({ pkId, comments, whoChanged, productFamily }) => async (dispatch) => {
    try {
        const res = await GraphqlApi.query("deleteModelAvailability", {
            input: { 
                pkId,
                comments,
                whoChanged
            }
        });
        const message = res.data.deleteModelAvailability.message;
        const successMessage = `Model availability for "${productFamily}" has been removed`;
        dispatch(snackBar(true, message === 'Success' ? successMessage : message, message === 'Success' ? 'success' : 'error'));
        if (message === 'Success') {
            dispatch({ type: DELETE_MODEL_AVAILABILITY, payload: { pkId, comments, whoChanged } });
        }
    } catch (error) {
        dispatch(snackBar(true, error.message, "error"));
    }
};

export const ocplcgetModelTOFAuditTrial = (feedType, version, region, productFamily, country, businessModel) => async (dispatch) => {
    const apiMethodsMapping = {
        targetOfferAudit: { apiMethod: "ocplcModelTOFAuditTrial", fetchApiMethodData: "modelTOFAuditTrial" },
        modelOfferAudit: {apiMethod: "ocplcModelAvailabilityAuditTrial",fetchApiMethodData: "modelAuditTrial"}
    };
    const { apiMethod, fetchApiMethodData } = apiMethodsMapping[feedType]
    
    try {
        const res = await GraphqlApi.query(apiMethod, {
            filter: {
                version: version,
                region: region,
                productFamily: productFamily,
                country: country,
                businessModel: businessModel
            }
        });
        const modelAuditTrial = res?.data?.[apiMethod]?.[fetchApiMethodData] || [];
        dispatch({ type: TOF_AUDIT_TRAIL, payload: modelAuditTrial });
    } catch (error) {
        dispatch({ type: TOF_AUDIT_TRAIL, payload: [] });
        dispatch(snackBar(true, error.message, "error"));
    }
}

export const updateModelAvailability = (formData, plcDetails, userName, isOtherRegion, regionForCopyAndEdit, copy, kmatId, productLineID, selectedTableRegion, handleClose) => async (dispatch) => {
    // Base common fields
    let data = {
        pkId: copy ? "0" : plcDetails.pkId,
        version: plcDetails.version,
        region: regionForCopyAndEdit,
        productDesc: plcDetails.productDesc,
        productGroup: plcDetails.productGroup,
        productFamily: copy ? formData.productFamily : plcDetails.productFamily,
        status: plcDetails.status,
        whoChanged: userName,
        lastModifiedDate: currentDate(),
        createDate: plcDetails.createDate,
        copy: copy
    };
    // Fields specific to "WW" region
    if (!isOtherRegion) {
        data = {
            ...data,
            country: formData["countryID"],
            businessModel: formData["bussinessModel"],
            comments: formData["comment"],
            apRegion: formData["ap"],
            euRegion: formData["eu"],
            naRegion: formData["na"],
            laRegion: formData["la"],
            startDate: formData["startDate"],
            endDate: formData["endDate"],
            hStartDate: "",
            hEndDate: "",
            visibleDate: "",
            countryDesc: formData["countryDesc"]
        };
    } 
    // Fields specific to other regions
    else {
        data = {
            ...data,
            country: formData["countryID"] || "",
            countryDesc: formData["countryDesc"] || "",
            businessModel: formData["bussinessModel"] || "",
            comments: formData["comment"] || "",
            wwStartDate: formData["wwStartDate"] || "",
            wwEndDate: formData["wwEndDate"] || "",
            startDate: formData["regStartDate"] || "",
            endDate: formData["regEndDate"] || "",
        };
        // Add regional fields dynamically
        regionalFields.forEach(({ startField, startName, endField, endName }) => {
            data[startField] = formData[startField] || "";
            data[endField] = formData[endField] || "";
            data[startName] = plcDetails[startName];
            data[endName] = plcDetails[endName];
        });
    }
    try {
        const apiMethod = isOtherRegion ? "updateTofModelAvailability" : "updateModelAvailability";
        const res = await GraphqlApi.query(apiMethod, { input: data });

        if (res.data[apiMethod].message === "Success") {
            if (copy) {
                dispatch({ type: CREATE_EXISTING_MODEL_AVAILABILITY, payload: data });
            } else {
                dispatch({ type: UPDATE_MODEL_AVAILABILITY, payload: data });
            }
            dispatch(modelAvailability(plcDetails.version, selectedTableRegion, kmatId, plcDetails.productGroup, productLineID));
            dispatch(snackBar(true, `Model data for ${plcDetails.productFamily} ${copy ? 'copied' : 'updated'} successfully`, 'success'));
            handleClose();
        }
    } catch (error) {
        dispatch(snackBar(true, error.message, "error"));
    }
};

export const GetProductLine = (productGroup) => async (dispatch) => {
    try {
        const res = await GraphqlApi.query("GetProductLine", { filter:{productGroup:productGroup} });
        const productLine = res?.data?.ocplcProductLine?.productLine || [];
        dispatch({ type: GET_PRODUCT_LINE, payload: productLine });
    } catch (error) {
        dispatch({ type: GET_PRODUCT_LINE, payload: [] });
        dispatch(snackBar(true, error.message, "error"));
    }
}

export const createModelAvailability = (formData) => async (dispatch) => {
    try {
        const res = await GraphqlApi.query("createModelAvailability", {
            input: {
                version: formData.VERSION,
                region: formData.REGION,
                productLine: formData.PRODUCT_LINE,
                productFamily: formData.PRODUCT_FAMILY,
                productDescriptions: formData.PRODUCT_DESC,
                businessModel: formData.BUSINESS_MODEL,
                productGroup: formData.PRODUCT_GROUP,
                country: "ALL",
                startDate: formData.START_DATE,
                endDate: formData.END_DATE,
                comments: formData.COMMENT,
                apRegion: formData.apRegion,
                euRegion: formData.euRegion,
                naRegion: formData.naRegion,
                laRegion: formData.laRegion,
                whoChanged: formData.whoChanged
            }
        });
        if (res.data.createModelAvailability.message === 'Success') {
            dispatch({ type: CREATE_MODEL_AVAILABILITY, payload: formData });
            dispatch(snackBar(true, `Model data for ${formData.PRODUCT_FAMILY} has been added successfully`, 'success'));
        }
        return res.data.createModelAvailability.message;
    } catch (error) {
        dispatch(snackBar(true, error.message, "error"));
    }
}

const handleDTApiResponse = async (dispatch, apiMethod, apiParams, successActionType, successMessage, additionalSuccessActions = []) => {
    try {
        const res = await GraphqlApi.query(apiMethod, apiParams);
        const message = res.data[apiMethod].response || res.data[apiMethod].message;
        const isSuccess = message === 'Success';
        const status = isSuccess ? 'success' : 'error';
        const snackMessage = isSuccess ? successMessage : message;
        const payload = res?.data?.[apiMethod]?.response || res?.data?.[apiMethod]?.message || [];
        
        dispatch({ type: successActionType, payload });
        dispatch(snackBar(true, snackMessage, status));
        
        if (isSuccess) {
            additionalSuccessActions.forEach(action => dispatch(action));
        }
    } catch (error) {
        dispatch(snackBar(true, error.message, "error"));
    }
};

export const moduleWiseDataTransport = (pkIds, whoChanged, moduleType, version, region, kmatId, productGroup, productLineID) => async (dispatch) => {
    await handleDTApiResponse(
        dispatch,
        'moduleWiseDataTransport',
        { filter: { pkIds, whoChanged, moduleType } },
        MODULE_WISE_DATA_TRANSPORT,
        "Data Transported Successfully",
        [modelAvailability(version, region, kmatId, productGroup, productLineID)]
    );
};

export const completeModelAvailability = (pkId, whoChanged, version, region, kmatId, productGroup, productLineID) => async (dispatch) => {
    await handleDTApiResponse(
        dispatch,
        'completeModelAvailability',
        { input: { pkId, whoChanged } },
        COMPLETE_MODEL_AVAILABILITY,
        "Model Availability Completed Successfully",
        [modelAvailability(version, region, kmatId, productGroup, productLineID)]
    );
};

export const dashboardDataTransport = (whoChanged, dataTransportObjs) => async (dispatch) => {
    await handleDTApiResponse(
        dispatch,
        'dataTransport',
        { filter: { whoChanged, dataTransportObjs } },
        DASHBOARD_DATA_TRANSPORT,
        "Data Transported Successfully",
        [refreshOcplc()]
    );
};