import moment from "moment";

export const filterText = (data, searchText, matchFields) => {
    const search = searchText.toLowerCase();

    return data.filter(row => {
        return matchFields.some(field => {
            return row[field] && row[field].toLowerCase().includes(search);
        });
    });
};

export const filterSelect = (data, searchText, matchFields) => {
    const [unit1] = matchFields;
    if (!searchText) return data;
    return data?.filter(row => row[unit1] && row[unit1] === searchText)
}

export const filterBoolean = (data, searchText, matchFields) => {
    const [unit1] = matchFields;
    if (searchText === false) return data;
    return data.filter(row => row[unit1] && row[unit1] === searchText);
};

export const filterDate = (data, searchText, matchFields) => {
    const search = searchText.toLowerCase();
    return data.filter(row => 
        matchFields.some(field => 
            row[field] && moment(row[field]).format('DD, MM, YYYY').toLowerCase().includes(search)
        )
    );
}

export const filterFactory = (colType) => {
    const filterFns = {
        text: (data, searchText, matchFields) => filterText(data, searchText, matchFields),
        select: (data, searchText, matchFields) => filterSelect(data, searchText, matchFields),
        boolean: (data, searchText, matchFields) => filterBoolean(data, searchText, matchFields),
        date: (data, searchText, matchFields) => filterDate(data, searchText, matchFields),  // Added date case
    };

    return filterFns[colType];
}

export const filterGrid = (data, searchText, type, matchFields) => {
    const filter = filterFactory(type);
    return filter(data, searchText, matchFields);
}

export const applyFilters = (filtersMap, initialState) => {
    return filtersMap.reduce((acc, filter) => {
        const { value, type, matchFields } = filter;

        if (!value || !type) return acc;

        return filterGrid(acc, value, type, matchFields);
    }, initialState);
}