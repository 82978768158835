import { FETCH_SUCCESS } from "constants/modelConstants";
import { getTabUpdateStatus } from "reducers/modelUtils";

export const getTabCategories = (retModelData) => {
  const tabCategoriesMap = {
    PM: [],
    Misc: [],
    CarePack: [],
    CustomService: [],
    Deploy: [],
    Spares: [],
    MandA: [],
    Regional: [],
    WWNA: [],
    WWLA: [],
    WWEU: [],
    WWAP: [],
    NA: [],
    LA: [],
    EU: [],
    AP: [],
    NAGPSA: [],
    WW: [],
  };

  retModelData.forEach((modelData) => {
    if (tabCategoriesMap.hasOwnProperty(modelData.tabCategory)) {
      tabCategoriesMap[modelData.tabCategory].push(modelData);
    }
  });

  return tabCategoriesMap;
};

export const handleData = (dispatch, data, dataType) => {
  if (!data) {
    throw new Error("Error processing data");
  }

  dispatchFetchSuccess(dispatch, data, dataType);
};

export const dispatchFetchSuccess = (dispatch, data, dataType) => {
  const { PM, Misc, CarePack, CustomService, Deploy, Spares, MandA, Regional, WWNA, WWLA, WWEU, WWAP, NA, LA, EU, AP, NAGPSA, WW } = getTabCategories(data.choices ?? []);
  const types = ['model', 'template', 'regional'];

  if (!types.includes(dataType)) {
    throw new Error("Invalid fetch type");
  }
  const activeTab = { 'model': 'PM', 'template': 'PM', 'regional': 'WWNA' }[dataType];

  const tabData = {  PM, Misc, CarePack, CustomService, Deploy, Spares, MandA};
  const tabUpdateStatus = getTabUpdateStatus(tabData);

  dispatch({
    type: FETCH_SUCCESS,
    payload: {
      kmat: data.model,
      dataPM: PM,
      dataMisc: Misc,
      dataRegional: Regional,
      dataSpares: Spares,
      dataCarePack: CarePack,
      dataCustomService: CustomService,
      dataDeploy: Deploy,
      dataMandA: MandA,
      dataWWNA: WWNA,
      dataWWLA: WWLA,
      dataWWEU: WWEU,
      dataWWAP: WWAP,
      dataNA: NA,
      dataLA: LA,
      dataEU: EU,
      dataAP: AP,
      dataNAGPSA: NAGPSA,
      dataWW: WW,
      localData: [],
      versions: data.versions,
      activeTab,
      dataType,
      tabUpdateStatus,
    }
  });
};


export const getChoicesMutData = (kmatID, modVer, choice) => {
  const selectionType = `${choice.selectionType}${choice.isRequired === true ? " Required" : ""}`
  return choice.edited ? {
    kmatID,
    modVer,
    choiceID: choice?.choiceID,
    tabCategory: choice?.tabCategory,
    selectionType: selectionType,
    dataRange: choice?.dataRange || "",
    defaultValue: choice?.defaultValue || "",
    dataLength: choice?.dataLength || "",
    requiredCondition: choice.requiredCondition  || "",
    displayCondition: choice.displayCondition || "",
    messages:JSON.stringify( choice?.messages?.filter(msg => msg.condition !== ""|| msg.description !== "") || []),
    comments: choice?.comments || "",
    obsoleteOn: choice?.obsoleteOn || "",
  } : null;
}

export const getItemsMutData = (kmatID, modVer, items) => {
  return items?.reduce((acc, item) => {
    if (!item.edited) return acc;

    const newItem = {
      kmatID,
      modVer,
      itemID: item?.itemID,
      partDescription: item?.partDescription || "",
      filterCondition: item?.filterCondition || "",
      counterCvg: item?.counterCvg  || "",
      defaultCondition: item?.defaultCondition || "",
      cvg: item.cvgs ? item.cvgs.join(' & ').trim() : item?.cvg || "",
      defaultComponents: JSON.stringify(item?.defaultComponents?.filter(comp => comp.condition !== "" && comp.description !== "") || []),
      messages: JSON.stringify(item?.messages?.filter(msg => msg.condition !== "" || msg.description !== "") || []),
      obsoleteOn: item?.obsoleteOn || "",
      comments: item?.comments  || "",
      tabCategory: item?.tabCategory,
    };

    return [...acc, newItem];
  }, []);
};
